import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import DialogActions from '@material-ui/core/Button';
import RedirectToLogin from './RedirectToLogin'
import { logout } from '../user/authService';
import { getStorage } from '../authentication/localStorage.js';
const useStyles = makeStyles(() => ({
  json: {
    wordBreak: 'break-word',
  },
}));

function Monitor() {
  const classes = useStyles();
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);
  const [loginResponse, setLoginResponse] = React.useState({});

  useEffect(() => {
    const storage = getStorage();
    setLoginResponse(storage.loginResponse);
  }, []);

  const handleLogout = () => {
    logout();
    setRedirectToLogin(true);
  };

  if (redirectToLogin) {
    return <RedirectToLogin />
  }
  return (
    <div>
      <DialogActions>
        <Button onClick={handleLogout}>Log out</Button>
      </DialogActions>
      <h1>No available account information.</h1>
      <div className={classes.json}>
        {
          JSON.stringify(loginResponse)
        }
      </div>
    </div>
  );
}

export default Monitor;