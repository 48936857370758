import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetServiceSummary extends CustomerPortalApiCall {
  apiCall(accountId) {
    return this.apiManager.getServiceSummary(accountId);
  }
}

const getServiceSummary = (accountId) => {
  return new GetServiceSummary().execute(accountId);
}

getServiceSummary.propTypes = {
  identity: PropTypes.number.isRequired,
  accountPackageId: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  serviceBaseTypeId: PropTypes.number.isRequired,
  serviceName: PropTypes.string.isRequired,
  serviceStatusTypeId: PropTypes.number,
  serviceStatusTypeName: PropTypes.string,
};

export default getServiceSummary;