const serviceChargeTypeEnum = {
  RECURRING: { uid: 1, caption: 'Recurring Charge' },
  ONE_TIME: { uid: 2, caption: 'One Time Charge' },
};

const groupServicesByName = (serviceList) => {
  let uniqueServiceNames = serviceList.reduce((result, service) => {
    if (!result.includes(service.serviceName)) {
      result.push(service.serviceName);
    }
    return result;
  }, []);
  let groupedServices = uniqueServiceNames.map((serviceName) => {
    return serviceList.filter((service) => service.serviceName === serviceName);
  });
  return groupedServices;
}

export default (packages, serviceSummary, dateFormatter, serviceStatusList, accountPackageStatusList, currentAccountStatus) => {
  if (dateFormatter === undefined || serviceStatusList.length === 0 || packages.length === 0) return [];
  return packages.map((item) => {
    const accountPackageStatus = item?.details?.temporalData?.items[0];
    const accountPackageStatusType = accountPackageStatusList.find(status => status.id === accountPackageStatus.accountPackageStatusTypeId);
    const preventChanges = (
      (currentAccountStatus !== undefined && currentAccountStatus.preventChanges) ||
      (accountPackageStatusType !== undefined && accountPackageStatusType.preventChanges)
    );
    const serviceList = serviceSummary.filter(service => service.accountPackageId === item.identity);
    return {
      id: item.identity,
      name: item.name,
      quantity: item.quantity,
      nextBill: dateFormatter.dbToUIDateTimeConvert(item.nextBill, true),
      status: accountPackageStatus.accountPackageStatusTypeName,
      statusId: accountPackageStatus.accountPackageStatusTypeId,
      preventChanges: preventChanges,
      baseBillingStatusTypeId: accountPackageStatusType.baseBillingStatusTypeId,
      serviceStatusList: serviceStatusList,
      services: (serviceList === undefined || !Array.isArray(serviceList)) ? [] :
        groupServicesByName(serviceList).map((services, serviceIdx) => {
          const firstService = services[0];
          const chargeType = firstService.serviceBaseTypeId === serviceChargeTypeEnum.RECURRING.uid ? serviceChargeTypeEnum.RECURRING
            : serviceChargeTypeEnum.ONE_TIME;
          let totalCount;
          let serviceStatusCounts = [];
          serviceStatusCounts = serviceStatusList.map((statusType) => {
            let selectedService = services.find((svc) => svc.serviceStatusTypeId === statusType.id);
            return (selectedService === undefined) ? 0 : selectedService.count * item.quantity;
          })
          totalCount = serviceStatusCounts.reduce((a, b) => a + b, 0); // Add the counts to get total count
          return {
            id: item.identity * 1000 + serviceIdx,
            name: firstService.serviceName,
            chargeType: chargeType.caption,
            serviceStatusCounts: serviceStatusCounts,
            totalCount: totalCount,
          }
        })
    }
  })
}