import PropTypes from 'prop-types';

import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetUsage extends CustomerPortalApiCall {
  apiCall(accountId, usageMonth, usageYear, pageOptions) {
    let requestOptions = {
      masterId: accountId,
      queryParameters: {
        usageMonth: usageMonth,
        usageYear: usageYear,
      },
      ReadRequest: { filters:[
        'accountName', 'serviceName',
        'packageName', 'udrUsageIdentifier', 'usageBaseUnitName', 'usageYear', 'usageMonth',
        'usageBillPeriodStartDate', 'usageTotal', 'displayUsageTotal'
      ]}
    };
    return this.apiManager.monthlyUsageSummary.paged(
      pageOptions.pageNumber,
      pageOptions.pageSize,
      undefined,
      requestOptions
    )
  }
}


const getUsage = (accountId, usageMonth, usageYear, pageOptions) => new GetUsage().execute(accountId, usageMonth, usageYear, pageOptions);

getUsage.propTypes = {
  identity: PropTypes.number.isRequired,
  serviceName: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
  usageIdentifier: PropTypes.string.isRequired,
  frequency: PropTypes.number.isRequired,
  frequencyUnit: PropTypes.string.isRequired,
  usage: PropTypes.number.isRequired,
  usageUnit: PropTypes.string.isRequired,
};

export default getUsage;

