import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useBorderlessStyle = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  caption: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: 'x-small',
      paddingTop: '0px !important',
    },
  },
  emptyCell: {
    width: '15px'
  },
  serviceStatus: {
    width: '300px'
  }
});

export default function ServiceListItem(props) {
  const { service, startIdx, endIdx } = props;
  const classes = useBorderlessStyle();
  const matches = useMediaQuery('(min-width:768px)');

  let serviceStatus = (counts) => {
    return counts.map((count, idx) => {
      return (
        <TableCell key={idx} align='center' className={classes.serviceStatus}>{count}</TableCell>
      )
    });
  };

  return (
    <React.Fragment>
      <TableRow key={service.id} className={classes.root}>
        <TableCell className={classes.emptyCell} />
        <TableCell>
          {service.name}
        </TableCell>
        {
          matches &&
          <>
            <TableCell />
            {
              serviceStatus(service.serviceStatusCounts.slice(startIdx, endIdx + 1))
            }
            <TableCell className={classes.emptyCell} />
          </>
        }
        <TableCell align='right'>{service.totalCount}</TableCell>
      </TableRow>
      <TableRow className={classes.caption}>
        <TableCell className={classes.emptyCell} />
        <TableCell>{service.chargeType === 'Recurring Charge' ? service.chargeType : 'One Time Charge'} </TableCell>
      </TableRow>
    </React.Fragment>
  )
}