import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import routes from '../app/routes';
import { drawerClass, logoHeight } from '../assets/jss/classes/drawer';
import { largeLogo, smallLogo } from '../assets/jss/portalStyle'

const styles = (theme) => ({
  ...drawerClass,
  item: {
    position: 'relative',
    display: 'block',
    paddingTop: 1,
    paddingBottom: 12,
    textDecoration: 'none',
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      filter: 'brightness(80%)',
    },
  },
  itemCategory: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #404854',
    height: logoHeight,
  },
  list: {
    position: 'unset',
  },
  firebase: {
    fontSize: 42,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  positionedLink: {
    position: 'absolute',
    width: '100%',
    bottom: '13px',
    textAlign: 'center',
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: '18px',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  largeLogo: {
    height: '100px',
    width: '200px',
  },
  smallLogo: {
    height: '80px',
    width: '80px',
  },
  noLogo: {
    display: 'block',
    paddingTop: theme.spacing(2),
  }
});

function Navigator(props) {
  const { classes, url, ...drawerProps } = props;
  const category = url.substring(1);  // strip leading '/'
  const { variant } = drawerProps;
  let poweredByLogiSenseLink = (
    <a
      href='https://www.logisense.com?ref=client-portal'
      className={clsx(classes.item, classes.positionedLink)}
      rel='noopener noreferrer'
      target='_blank'
    >
      <ListItemText
      >
        <Typography variant='caption' display='inline' gutterBottom>
          Powered by{' '}
        </Typography>
        <Typography variant='button' display='inline' gutterBottom>
          LogiSense
        </Typography>
      </ListItemText>
    </a>
  );

  let logo = (() => {
    if (variant === 'permanent' && largeLogo) {
      return <img src={largeLogo} className={classes.largeLogo} alt='Logo' />
    } else if (variant === 'temporary' && smallLogo) {
      return <img src={smallLogo} className={classes.smallLogo} alt='Logo' />
    } else {
      return <span className={clsx(classes.largeLogo, classes.noLogo)}>LogiSense</span>
    }
  })

  return (
    <Drawer {...drawerProps} className={classes.drawer}>
      <List disablePadding className={classes.list}>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          {logo()}
        </ListItem>
        {routes.map(({ id, icon, href }) => {
          let active = id.toLowerCase() === category;
          return (
            <NavLink
              id={'menu_' + id}
              to={href}
              className={clsx(classes.item, active && classes.itemActiveItem)}
              activeClassName='activeMenuItem'
              key={id}
            >
              <ListItem button>
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {id}
                </ListItemText>
              </ListItem>
            </NavLink>
        )})}
        {poweredByLogiSenseLink}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);