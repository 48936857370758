export const drawerWidth = 232;
export const logoHeight = 133;
export const drawerClass = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    backgroundColor: theme.palette.secondary.main
  }
});
