import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetAccountPackageStatusType extends CustomerPortalApiCall {
  apiCall(searchRequest) {
    return this.apiManager.accountPackageStatusType.all(searchRequest);
  }
}

const getAccountPackageStatusType = (searchRequest) => new GetAccountPackageStatusType().execute(searchRequest);

getAccountPackageStatusType.propTypes = {
  identity: PropTypes.number.isRequired,
  baseBillingStatusTypeId: PropTypes.number.isRequired,
  baseBillingStatusTypeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  preventChanges: PropTypes.bool.isRequired,
};

export default getAccountPackageStatusType;