import axios from 'axios';
import { Retryable } from '@lb/utils/constructs';
import { BaseManagerFactory } from '@lb/utils/apiManager';
import dayjs from '../assets/dayjsPlugins.js'
import AuthenticationStaleError from './AuthenticationStaleError.js';
import { clearStorage, getStorage, setStorage } from './localStorage.js'

export default class CustomerPortalApiManagerFactory extends BaseManagerFactory {
  constructor(config) {
    super(config);
    let api_configuration = config.api_configuration;
    this.authUrl = api_configuration.auth_url;
    this.logger = global.Logger;
    this.url_auth_server_request_prefix = `${this.authUrl}/${api_configuration.authorization_server}/api/v${api_configuration.version}/CustomerPortal/Access/Login`;
    this.url_auth_server_logout = `${this.authUrl}/${api_configuration.resource_server}/api/v${api_configuration.version}/internal/CustomerPortal/Access/Logout`;
    this.actingAccountId = config.actingAccountId;
    this.isLogin = config.isLogin;
  }

  getClient() {
    return axios;
  }

  initialize() {
    if (this.isLogin) {
      clearStorage();
    }

    if (!this.initializeFromStoredToken()) {
      super.initialize();
    }
  }

  initializeFromStoredToken() {
    const storage = getStorage();
    const refreshToken = storage.refreshToken;
    const invoicerAccountId = storage.invoicerAccountId;
    const refreshTokenExists = refreshToken !== undefined && invoicerAccountId !== undefined;
    if (refreshTokenExists) {
      this.idleDate = dayjs();
      this.access_token_promise = this.establishClaim(true, { 
        refresh_token: refreshToken,
        actingAccountId: invoicerAccountId,
      });
    }
    return refreshTokenExists;
  }

  async tokenAwaitable(useRefresh, current_claim, failCount) {
    return new Promise((resolve, reject) => {
      let searchParams = {
        Accept: 'application/x-www-form-urlencoded',
        'Content-type': 'application/x-www-form-urlencoded',
        client_id: this.credentials.clientId,
      };

      if (useRefresh === true && current_claim !== undefined && failCount === 0) {
        // Refreshing the access token
        searchParams = Object.assign(searchParams, {
          grant_type: 'refresh_token',
          refresh_token: current_claim['refresh_token']
        });
      } else if (this.credentials.password !== undefined) {
        // Initial login attempt
        searchParams = Object.assign(searchParams, {
          grant_type: 'password',
          username: this.credentials.username,
          password: this.credentials.password
        });
      } else {
        // Timeout occured, refresh token is now stale, user will need to re-provide credentials
        clearStorage();
        return reject(new AuthenticationStaleError());
      }

      if (current_claim !== undefined && current_claim.actingAccountId !== undefined) {
        this.actingAccountId = current_claim.actingAccountId;
      }

      if (this.actingAccountId !== undefined) {
        searchParams.acting_account_id = this.actingAccountId;
      }

      // Do not keep the password any longer than we need to
      if (this.credentials.password !== undefined) {
        delete this.credentials.password;
      }
      if (this.config.password !== undefined) {
        delete this.config.password;
      }

      const login = (this.config.isLogin === true);
      this.config.isLogin = false;

      axios.post(this.url_auth_server_request_prefix, new URLSearchParams(searchParams))
        .then(function (response) {
          if (response.status === 200) {
            try {
              let authResponse = response.data;
              let accessToken = login ? authResponse.access_token : undefined; // Only reset the access token on initial login when we know the accountID is the InvoicerAccountID
              setStorage(authResponse.refresh_token, accessToken, authResponse);
              return resolve(authResponse);
            } catch (e) {
              console.error(`Failed to parse authorization response. Response: ${response}`);
              console.error(e);
              return reject(new Retryable.CouldNotEstablishResourceError(response));
            }
          }
          clearStorage();
          return reject(new Retryable.CouldNotEstablishResourceError(response));
        }).catch(function (err) {
          clearStorage();
          console.warn('An unexpected error occurred when obtaining an authorization token, treating it as a stale token.')
          return reject(new AuthenticationStaleError());
        });
    });
  }

  async logout() {
    const [access_token, ] = await this.getCurrentToken();
    return new Promise((resolve, reject) => {
      axios
        .post(this.url_auth_server_logout, undefined, {
          headers: {
            'Authorization': 'Bearer ' + access_token
          }
        })
        .then(function () {
          return resolve();
        })
        .catch(function (err) {
          return reject(new Error(err));
        })
        .finally(() => {
          clearStorage();
        });
    });
  }
}
