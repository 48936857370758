import React, { useState } from 'react';

import { withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import postUserPasswordChange from '../api/postUserPasswordChange'
import { getUserName } from '../user/authService'
import ChangePasswordFields from './ChangePasswordFields';
import ValidationErrors from './ValidationErrors';

const styles = (theme) => ({
  dialogButton: {
    margin: theme.spacing(2),
  },
})

function ChangePasswordDialog(props) {
  const { classes, onClose } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const currentPasswordChangeHandler = (event) => {
    setCurrentPassword(event.target.value);
    setValidationErrors([]);
  };

  const passwordChangeHandler = (event) => {
    if (confirmPassword.length > 0) {
      updateConfirmPasswordError(event.target.value, confirmPassword);
    }
    setNewPassword(event.target.value);
    setValidationErrors([]);
  };

  const passwordConfirmChangeHandler = (event) => {
    updateConfirmPasswordError(newPassword, event.target.value);
    setConfirmPassword(event.target.value);
    setValidationErrors([]);
  };

  const updateConfirmPasswordError = (pass, confirmPass) => {
    setConfirmPasswordError(pass !== confirmPass ? 'Passwords do not match' : '');
  }

  const submitHandler = async (e) => {
    let postBody = {
      userName: await getUserName(),
      clearTextPassword: currentPassword,
      clearTextNewPassword: newPassword,
      clearTextConfirmPassword: confirmPassword,
    }

    setIsChangingPassword(true);
    postUserPasswordChange(postBody).then(() => {
      setValidationErrors([]);
      if (typeof (onClose) === 'function') {
        onClose();
      }
    }).catch((error) => {
      let response = error.response;
      if (response !== undefined && response.data !== undefined && Array.isArray(response.data.messages)) {
        setValidationErrors(response.data.messages);
      }
    }).finally(() => setIsChangingPassword(false));
  };

  const cancelHandler = (e) => {
    if (typeof (onClose) === 'function') {
      onClose();
    }
  }

  const isValidSubmit = (
    currentPassword.length > 0 &&
    newPassword.length > 0 &&
    confirmPassword === newPassword
  );
  return (
    <>
      <Dialog
        open
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(event, reason);
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          Change Password
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            type="password"
            margin="normal"
            required
            fullWidth
            id="currentPassword"
            label="Current Password"
            name="currentPassword"
            value={currentPassword}
            onChange={currentPasswordChangeHandler}
          />
          <ChangePasswordFields
            password={{
              value: newPassword,
              onChange: passwordChangeHandler
            }}
            confirmPassword={{
              value: confirmPassword,
              onChange: passwordConfirmChangeHandler
            }}
            confirmPasswordError={confirmPasswordError}
            onEnterPress={isValidSubmit && submitHandler}
          />
          <ValidationErrors validationErrors={validationErrors} />
        </DialogContent>
        <DialogActions>
          <Button
            halfWidth
            color="primary"
            className={classes.dialogButton}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            halfWidth
            variant="contained"
            color="primary"
            className={classes.dialogButton}
            disabled={!isValidSubmit || isChangingPassword}
            onClick={submitHandler}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(ChangePasswordDialog);


