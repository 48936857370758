// TODO - Update to reference CPUI paths
const prefix = '';
const imports = {
  imports: {
    'vendor/': `${prefix}/vendor/`,
    'entities/': `${prefix}/application/entities/`,
    'screens/': `${prefix}/application/screens/`,
    'core/': `${prefix}/application/core/`,
    'ui_clone': `${prefix}/application/helpers/clone.js`,
    // Node Modules
    'uuid': `${prefix}/import/uuid.js`,
    'axios': `${prefix}/import/axios.js`,
    'lodash': `${prefix}/node_modules/lodash-es/lodash.js`,
  },
};

// Module Loader
window.exports = {};

// ImportMap Element Creation
const im = document.createElement('script');
im.type = 'importmap';
im.textContent = JSON.stringify(imports);
document.currentScript.after(im);