import React, { useState, useEffect } from 'react';

import MuiTable from '@material-ui/core/Table';
import { withStyles, Container, TableBody, TableContainer } from '@material-ui/core';

import { getCurrentAccountId, getCurrentOwnerCustomerPortalSettings } from '../../../helpers/getInfoFromCurrentUser';
import Loading from '../../../components/Loading';
import { getAllPaymentTypes } from '../../../api/getPaymentType';
import getPaymentMethods from '../../../api/getPaymentMethods';
import putPaymentMethod from '../../../api/putPaymentMethod';
import postSetPaymentMethodAsDefault from '../../../api/postSetPaymentMethodAsDefault';
import PaymentMethodRow from './PaymentMethodRow';
import EmptyPaymentMethod from './EmptyPaymentMethod';
import AddPaymentDialog from './AddPaymentDialog';

const styles = (theme) => ({});

function PaymentMethods() {
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customerPortalSettings, setCustomerPortalSettings] = useState({});
  const [accountId, setAccountId] = useState(false);
  const [hasDefault, setHasDefault] = useState(false);
  const [paymentMethodNameList, setPaymentMethodNameList] = useState([]);
  const allowPayments = customerPortalSettings.allowPaymentMethodUpdate;

  const getData = async () => {
    let accountId = await getCurrentAccountId();
    setAccountId(accountId);
    let getPaymentTypes = getAllPaymentTypes();
    let getPaymentMethodsPromise = getPaymentMethods(accountId);
    let getSettingsPromise = getCurrentOwnerCustomerPortalSettings();
    Promise.all([getPaymentTypes, getPaymentMethodsPromise, getSettingsPromise])
      .then((data) => {
        const [paymentTypeList, paymentMethodList, settings] = data;
        let paymentMethods = paymentMethodList.map((paymentMethod) => {
          return {
            ...paymentMethod,
            paymentMethodBaseId: paymentTypeList.find(paymentType => paymentMethod.paymentTypeId === paymentType.identity).paymentBaseTypeId,
          }
        })
        setPaymentMethods(paymentMethods);
        setCustomerPortalSettings(settings);
        let checkDefault = paymentMethodList.some(paymentMethod => paymentMethod.autopay === true && paymentMethod.isActive === true) === true;
        setHasDefault(checkDefault);
        let paymentMethodNameList = paymentMethodList.map(paymentMethod => paymentMethod.name);
        setPaymentMethodNameList(paymentMethodNameList);
        setLoading(false);
      })
  };

  useEffect(() => {
    getData();
  }, [])

  const handleIsActiveChange = (e, paymentMethodId) => {
    let selectedPaymentMethod = paymentMethods.find(pm => pm.identity === paymentMethodId);
    let newActiveValue = !selectedPaymentMethod.isActive;
    // Set auto-pay to false. 
    // Either it is now inactive and it should not be default, or it was already inactive and should not have been default in the first place
    let newAutopayValue = false;
    let putBody = {
      identity: paymentMethodId,
      isActive: newActiveValue,
      autopay: newAutopayValue,
    };
    putPaymentMethod(putBody).then(() => {
      selectedPaymentMethod.isActive = newActiveValue;
      selectedPaymentMethod.autopay = newAutopayValue;
      updatePaymentMethodState([selectedPaymentMethod]);
    });
  }

  const handleIsDefaultChange = (e, paymentMethodId) => {
    let selectedPaymentMethod = paymentMethods.find(pm => pm.identity === paymentMethodId);
    let defaultPaymentMethod = paymentMethods.find(pm => pm.autopay === true);
    let isSelectedPaymentMethodDefault = (selectedPaymentMethod === defaultPaymentMethod)
    let paymentMethodsToUpdate = [selectedPaymentMethod];
    if (isSelectedPaymentMethodDefault) {
      // Set Auto-pay to false, there should not be a default payment method anymore
      let putBody = {
        identity: paymentMethodId,
        autopay: false,
      };
      putPaymentMethod(putBody).then(() => {
        selectedPaymentMethod.autopay = false;
        updatePaymentMethodState([selectedPaymentMethod]);
      })
    } else {
      // Set Auto-pay to true using the specialized endpoint. This will set other payment methods default = false
      postSetPaymentMethodAsDefault(paymentMethodId).then(() => {
        selectedPaymentMethod.autopay = true;
        if (defaultPaymentMethod) {
          defaultPaymentMethod.autopay = false;
          paymentMethodsToUpdate.push(defaultPaymentMethod);
        }
        updatePaymentMethodState(paymentMethodsToUpdate);
      })
    }
  }

  const updatePaymentMethodState = (paymentMethods) => {
    setPaymentMethods(oldValue => oldValue.map(pm => {
      let paymentMethod = paymentMethods.find(z => z.identity === pm.identity);
      return paymentMethod !== undefined ? paymentMethod : pm;
    }));
  }

  const onSuccessfulPayment = () => {
    setLoading(true);
    getData();
  };

  return loading ? <Loading /> : (
    <>
      {
        (allowPayments) && (
          <AddPaymentDialog
            currentAccountId={accountId}
            hasDefault={hasDefault}
            paymentMethodNameList={paymentMethodNameList}
            onSuccessfulPayment={onSuccessfulPayment}
            style={{ 'display': 'block' }}
          />
        )
      }
      {
        (paymentMethods.length === 0) ? (
          <Container>
            <EmptyPaymentMethod />
          </Container>
        ) : (
          <TableContainer>
            <MuiTable>
              <colgroup>
                <col width='30%' />
                <col width='40%' />
                <col width='30%' />
              </colgroup>
              <TableBody>
                {
                  paymentMethods.map((method) => {
                    return (
                      <PaymentMethodRow
                        key={`${method.name}_${method.paymentMethodIdentifier}`}
                        paymentMethod={method}
                        customerPortalSettings={customerPortalSettings}
                        handleIsActiveChange={handleIsActiveChange}
                        handleIsDefaultChange={handleIsDefaultChange}
                        accountId={accountId}
                        hasDefault={hasDefault}
                        paymentMethodNameList={paymentMethodNameList}
                        onSuccessfulPayment={onSuccessfulPayment}
                      />
                    )
                  })
                }
              </TableBody>
            </MuiTable>
          </TableContainer>
        )
      }
    </>
  )
}

export default withStyles(styles)(PaymentMethods)