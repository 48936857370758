import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetPaymentMethods extends CustomerPortalApiCall {
  apiCall(accountId, searchRequest) {
    if (searchRequest === undefined) {
      searchRequest = { filters: [] }
    }

    searchRequest.filters.push(
      { name: 'accountId', value: accountId }
    );
    return this.apiManager.paymentMethod.all(searchRequest);
  }
}

const getPaymentMethods = (accountId, searchRequest) => new GetPaymentMethods().execute(accountId, searchRequest);
getPaymentMethods.propTypes = {
  identity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  paymentMethodIdentifier: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
}
export default getPaymentMethods;