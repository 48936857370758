import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider, withStyles } from '@material-ui/core/styles';

import { primaryColor, secondaryColor } from '../assets/jss/portalStyle';

let buildTheme = (primaryMain, secondaryMain) => {
  let theme = createTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: primaryMain ?? '#009be5',
        dark: '#006db3',
      },
      secondary: {
        main: secondaryMain ?? '#009be5',
      }
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
      MuiTypography: {
        component: 'div',
      }
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
          borderRight: '5px solid',
          borderRightColor: '#d6d9dc',
        },
        indicator: {
          height: 3,
          width: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.primary.main,
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2),
            minWidth: 0,
          },
          [theme.breakpoints.down('sm')]: {
            margin: '0 8px',
          },
        },
        selected: {
          color: theme.palette.primary.main,
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };
  return responsiveFontSizes(theme);
};
const styles = (theme) => ({
});
function AppThemeProvider(props) {
  const theme = buildTheme(primaryColor, secondaryColor);
  return (
    <ThemeProvider theme={theme} {...props} />
  )
}
export default withStyles(styles)(AppThemeProvider)