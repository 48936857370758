import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetPaymentType extends CustomerPortalApiCall {
  apiCall(id) {
    return this.apiManager.paymentType.single(id);
  }
}

class GetAllPaymentTypes extends CustomerPortalApiCall {
  apiCall() {
    return this.apiManager.paymentType.all();
  }
}

export const getAllPaymentTypes = () => new GetAllPaymentTypes().execute();

export const getPaymentType = (id) => new GetPaymentType().execute(id);

getPaymentType.propTypes = {
  identity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  vendorCardName: PropTypes.string.isRequired,
  paymentBaseTypeId:  PropTypes.number.isRequired,
  paymentBaseTypeName: PropTypes.string.isRequired,
  paymentTypeDisbursementTypeId:  PropTypes.number.isRequired,
  paymentTypeDisbursementTypeName: PropTypes.string.isRequired,
  isActive:  PropTypes.bool.isRequired,
  paymentGatewayId:  PropTypes.number,
  paymentGatewayName: PropTypes.string,
  cardTypeId:  PropTypes.number,
  cardTypeName: PropTypes.string,
};

getAllPaymentTypes.propTypes = {
  identity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  vendorCardName: PropTypes.string.isRequired,
  paymentBaseTypeId:  PropTypes.number.isRequired,
  paymentBaseTypeName: PropTypes.string.isRequired,
  paymentTypeDisbursementTypeId:  PropTypes.number.isRequired,
  paymentTypeDisbursementTypeName: PropTypes.string.isRequired,
  isActive:  PropTypes.bool.isRequired,
  paymentGatewayId:  PropTypes.number,
  paymentGatewayName: PropTypes.string,
  cardTypeId:  PropTypes.number,
  cardTypeName: PropTypes.string,
};

