import getCustomerPortalSettings from '../api/getCustomerPortalSettings.js';
import getLocale from '../api/getLocale.js';
import { getCurrentUser } from '../user/authService.js'
//let timeZone = undefined;
let localeIdentifier = undefined;
let currentOwnerCustomerPortalSettingsPromise = undefined
let localeIdentifierPromise = undefined;

export const resetCurrentUserSettings = () => {
  localeIdentifier = undefined;
  currentOwnerCustomerPortalSettingsPromise = undefined
  localeIdentifierPromise = undefined;
}

export const getCurrentAccountId = () => {
  return new Promise((resolve) => {
    getCurrentUser().then((user) => {
      return resolve(user.accountId);
    });
  });
};

export const getCurrentOwnerCustomerPortalSettings = () => {
  if (currentOwnerCustomerPortalSettingsPromise === undefined) {
    currentOwnerCustomerPortalSettingsPromise = new Promise((resolve) => {
      getCurrentUser().then((u) => {
        getCustomerPortalSettings(u.accountId).then((settings) => {
          return resolve(settings);
        })
      });
    })
  }
  return currentOwnerCustomerPortalSettingsPromise;
}

export const getLocaleIdentifier = () => {
  if (localeIdentifier !== undefined) {
    return Promise.resolve(localeIdentifier)
  }

  if (localeIdentifierPromise === undefined) {
    localeIdentifierPromise = new Promise((resolve) => {
      getCurrentUser().then((user) => {
        const localeId = user.localeId;
        getLocale(localeId)
        .then((locale) => {
          localeIdentifier = locale === undefined ? undefined : locale.localeIdentifier;
          return resolve(localeIdentifier);
        })
      }).catch((err) => {
        console.error(err);
        resolve();
      });
    });
  }

  return localeIdentifierPromise;

};

// TODO - Likely going to need this at some point ??
// TODO - Will need to re-use the promise here 
// export const getTimeZone = () => {
//   return new Promise((resolve) => {
//     if (timeZone !== undefined) {
//       return resolve(timeZone)
//     }
//     getCurrentUser().then((user) => {
//       if (user === undefined) {
//         return resolve();
//       }
//       timeZone = user.timeZone;
//       return resolve(timeZone);
//     }).catch((err) => {
//       console.error(err);
//       resolve();
//     });
//   });
// }