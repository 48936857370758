import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from "@material-ui/core/Divider";
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import { drawerWidth, logoHeight } from '../assets/jss/classes/drawer'
import { logout } from '../user/authService';
import ChangeAccount from '../components/ChangeAccount';

const styles = (theme) => ({
  secondaryBar: { 
    zIndex: 0,
    position: 'absolute',
    left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%'
    }
  },
  toolbar: {
    height: logoHeight,
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      marginBottom: '12px',
      marginTop: '-12px',
    }
  },
  title: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  changeAccount: {
    position: 'absolute',
    top: '85px',
    left: '48px',
    [theme.breakpoints.down('sm')]: {
      top: '55px',
    }
  },
})

function Header(props) {
  const { classes, onDrawerToggle, ...changeAccountProps } = props;
  const { currentAccount } = changeAccountProps;
  const auth = true;
  const [anchorUserProfile, setAnchorUserProfile] = React.useState(null);
  const openUserProfile = Boolean(anchorUserProfile);

  const handleUserProfile = () => {
    history.push('/profile');
    handleProfileClose();
  }

  const handleProfileMenu = (event) => {
    setAnchorUserProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorUserProfile(null);
  };

  const handleLogOut = () => {
    handleProfileClose();
    logout(() => history.push('/login'))
  };

  let history = useHistory();
  return (
    <React.Fragment>
      <AppBar
        component='div'
        className={classes.secondaryBar}
        color='primary'
        position='static'
        elevation={0}
      >
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <IconButton
            color='inherit'
            edge='start'
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <Icon
            id='AccountIcon'
            edge='start'
            color='inherit'
            aria-label='menu'
          >
            <PersonIcon/>
          </Icon>
        </Hidden>
        <Typography id={'AppBarAccountName'} variant='h6' className={classes.title} noWrap={true}>
          {currentAccount.name}
        </Typography>
        <div className={classes.changeAccount}>
          <ChangeAccount text='Change Account' { ...changeAccountProps } />
        </div>
        {auth && (
          <div>
            <IconButton
              id={'AppBarProfileIcon'}
              aria-label='user profile'
              aria-controls='AppBarUserProfileMenu'
              aria-haspopup='true'
              onClick={handleProfileMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id='AppBarUserProfileMenu'
              anchorEl={anchorUserProfile}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openUserProfile}
              onClose={handleProfileClose}
            >
              <MenuItem id={'AppBarMenuProfile'} onClick={handleUserProfile}>Profile</MenuItem>
              <Divider className={classes.divider} />
              <MenuItem id={'AppBarMenuLogOut'} onClick={handleLogOut}>Log Out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>

      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  setCurrentAccount: PropTypes.func.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);