import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Header from '../components/Header';
import ChangeAccount from '../components/ChangeAccount';

const styles = (theme) => ({
  changeAccount: {
    display: 'inline',
  }
})

function EmptyListMessage(props) {
  const {classes, ...rest} = props;
  const {screenId, headerLabel, entityName, invoicerAccounts} = rest;
  return (
    <>
      <Grid container spacing={3} alignItems='center' id={screenId}>
        <Grid item xs id={`empty${entityName}List`}>
          <Header id={'PageTitle'} label={headerLabel}/>
        </Grid>
      </Grid>
      <Typography variant='subtitle1'>This account currently does not have any {entityName}.</Typography>
      {
        Array.isArray(invoicerAccounts) && invoicerAccounts.length > 1 &&
        <Typography variant='subtitle1'>{'Please select a '}
          <ChangeAccount buttonClass={classes.changeAccount} text='different account' {...rest} />
        </Typography>
      }
    </>
  );
}

EmptyListMessage.propTypes = {
  entityName: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
  screenId: PropTypes.string.isRequired,
  invoicerAccounts: PropTypes.array,
};

export default withStyles(styles)(EmptyListMessage);
