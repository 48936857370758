import PropTypes from 'prop-types';

import CustomerPortalApiCall from './CustomerPortalApiCall'

class GetInvoiceRender extends CustomerPortalApiCall {
  apiCall(invoiceId) {
    return this.apiManager.getInvoiceRender(invoiceId);
  }
}

const getInvoiceRender = (invoiceId) => new GetInvoiceRender().execute(invoiceId);

getInvoiceRender.propTypes = {
  pdf: PropTypes.string,
  link: PropTypes.string,
  invoiceName: PropTypes.string.isRequired,
};

export default getInvoiceRender;