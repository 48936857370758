import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldWithOnEnterAction from './TextFieldWithOnEnterAction'

export default (props) => {
  const { onLoginSuccess, loginWorkflow, classes } = props;
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [username, setUsername] = useState(props.username ?? '');
  const [password, setPassword] = useState('');
  const disableUserName = (props.username !== undefined);

  const loginHandler = () => {
    setIsAuthenticating(true);
    setAuthenticationFailed(false);

    const onSuccess = () => {
      onLoginSuccess();
    }
    const onFail = () => {
      setIsAuthenticating(false);
      setAuthenticationFailed(true);
    }

    loginWorkflow(username, password, onSuccess, onFail);
  };

  const usernameChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  return (
    <React.Fragment>
      <TextField
        disabled={disableUserName}
        variant='outlined'
        margin='normal'
        required
        fullWidth
        id='username'
        label='Username'
        name='userName'
        autoFocus
        value={username}
        onChange={event => usernameChangeHandler(event)}
      />
      <TextFieldWithOnEnterAction
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        autoComplete='current-password'
        value={password}
        onChange={event => passwordChangeHandler(event)}
        onEnterPress={loginHandler}
      />
      <Button
        fullWidth
        variant='contained'
        id='button_Login'
        color='primary'
        className={classes.submit}
        onClick={loginHandler}
        disabled={isAuthenticating}
      >
        Log In
      </Button>
      {/* TODO: need CSS on these */}
      { isAuthenticating && <CircularProgress />}
      { authenticationFailed && <div>Username and/or Password is not correct</div> }
    </React.Fragment>
  )
}