import { getAuthExpiredCb, getApiManager } from '../user/authService';
import AuthenticationStaleError from '../authentication/AuthenticationStaleError';

export default class CustomerPortalApiCall {
  constructor() {
    this.apiManager = undefined;
  }

  async initializeApiManager() {
    if (this.apiManager === undefined) {
      this.apiManager = await getApiManager();
    }
  }

  apiCall(...props) {
    throw new Error('Classes extending CustomerPortalApiCall must implement "apiCall" ');
  }

  onSuccess(results) {
    console.trace(results);
    return Promise.resolve(results);
  }

  onError(err) {
    console.error(err);
    return Promise.reject(err);
  }

  onAuthenticationStaleError(err, ...props) {
    let cb = getAuthExpiredCb();
    if (typeof cb !== 'function') {
      return Promise.reject(err);
    }

    return cb().then(() => {
      getApiManager().then((mgr) => {
        this.apiManager = mgr;
        return this.execute(...props);
      });
    });
  }

  execute(...props) {
    return this.initializeApiManager().then(() => {
      return this.apiCall(...props)
      .then((results) => {
        return this.onSuccess(results);
      })
      .catch((err) => {
        if (err instanceof AuthenticationStaleError) {
          return this.onAuthenticationStaleError(err, ...props);
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        return this.onError(err);
      })
    })
  }
}