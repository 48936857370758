import axios from 'axios';
import download from 'downloadjs';

global.Logger = {
  trace: (msg) => { console.trace(msg); },
  debug: (msg) => { console.debug(msg); },
  info: (msg) => { console.log(msg); },
  warn: (msg) => { console.warn(msg); },
  error: (msg) => { console.error(msg); }
}

global.$ui = {
  Date: {}
};
Object.defineProperty($ui.Date, 'MIN_DATE', {
  get: function () { return new Date(1970, 0, 1) },
  set: function () { console.warn('Attempted to change $ui.Date.MIN_DATE, which is not allowed')}
});

Object.defineProperty($ui.Date, 'MAX_DATE', {
  get: function () { return new Date(2199, 11, 31) },
  set: function () { console.warn('Attempted to change $ui.Date.MAX_DATE, which is not allowed')}
});


global.axios = axios;
global.download = download;