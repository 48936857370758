import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';

import { getApiManager } from '../user/authService';
import { getStorage } from '../authentication/localStorage.js';
import Loading from '../components/Loading';
import RedirectToLogin from '../components/RedirectToLogin';
import Monitor from '../components/Monitor';
import AppThemeProvider from './AppThemeProvider'

export default ({ component: Component, ...rest }) => {
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isInternal, setIsInternal] = useState(false)
  useEffect(() => {
    const checkAuth = async () => {
      const apiManager = await getApiManager();
      const storage = getStorage();
      setIsInternal(parseInt(storage?.invoicerAccountId) === 0);
      setAuth(apiManager !== undefined);
      setLoading(false);
    }
    checkAuth();
  }, []);

  if (loading) {
    return <Loading />
  } else if (isInternal) {
    return <Monitor />
  }
  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <AppThemeProvider>
            <Component {...props} />
          </AppThemeProvider>
        ) : (
          <RedirectToLogin {...props} />
        )
      }
    />
  );
};

