import PropTypes from 'prop-types';

import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetServiceStatusType extends CustomerPortalApiCall {
  apiCall(searchRequest) {
    return this.apiManager.serviceStatusType.all(searchRequest);
  }
}

const getServiceStatusType = (searchRequest) => new GetServiceStatusType().execute(searchRequest);

getServiceStatusType.propTypes = {
  identity: PropTypes.number.isRequired,
  baseBillingStatusTypeId: PropTypes.number.isRequired,
  baseBillingStatusTypeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default getServiceStatusType;