import CustomerPortalApiCall from './CustomerPortalApiCall';

class PostPaymentOneTimeCreditCard extends CustomerPortalApiCall {
  apiCall(data) {
    let postBody = {
      accountId: data.accountId,
      amount: data.amount,
      date: data.date,
      paymentMethodToken: data.paymentMethodToken,
      posting: data.posting,
      automaticDisburse: true,
    }
    if (data.savePaymentMethod === true) {
      postBody.savePaymentMethod = data.savePaymentMethod;
      postBody.name = data.paymentMethodName;
    }
    return this.apiManager.paymentOneTimeCreditCard.create(postBody);
  }
}

const postPaymentOneTimeCreditCard = (data) => new PostPaymentOneTimeCreditCard().execute(data);

export default postPaymentOneTimeCreditCard;