import React from 'react';

import { withStyles } from '@material-ui/core';
import Button from "@material-ui/core/Button";

import ReadOnlyField from '../../components/ReadOnlyField';
import ChangePasswordDialog from '../../components/ChangePasswordDialog'

const styles = (theme) => ({
  readOnlyField: {
    paddingBottom: theme.spacing(2),
  },
});

function UserDetail (props) {
  const { classes, customerPortalUser } = props;
  const [openModal, setOpenModal] = React.useState(false);

  const handleModalOpen = async () => {
    setOpenModal(true);
  };

  const handleModalClose = async () => {
    setOpenModal(false);
  };

  const items = [
    { caption: 'Account', text: customerPortalUser.accountName },
    { caption: 'Status', text: customerPortalUser.isActive ? 'Active' : 'Inactive' },
    { caption: 'Name', text: `${customerPortalUser.firstName} ${customerPortalUser.lastName}` },
    { caption: 'Email', text: customerPortalUser.email },
    { caption: 'Username', text: customerPortalUser.name },
  ]

  return (
    <>
      {
        items.map((item) => {
          return <div className={classes.readOnlyField} key={item.caption}>
            <ReadOnlyField 
              caption={item.caption}
              text={item.text}
            />
          </div>
        })
      }
      <Button
        className={classes.changePassword}
        variant='contained'
        color='primary'
        onClick={handleModalOpen}
      >
        Change Password
      </Button>
      { openModal && <ChangePasswordDialog onClose={handleModalClose} /> }
    </>
  )
}

export default withStyles(styles)(UserDetail)