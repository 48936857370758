import { v4 as uuid } from 'uuid';
import CustomerPortalApiCall from './CustomerPortalApiCall';
import { guid } from '../helpers/numberFormat';
const generateName = (data, paymentMethod) => {
  if (data.name !== undefined && data.name !== '') {
    return data.name;
  } else {
    return `${paymentMethod.card_type} ${paymentMethod.last_four_digits} ${guid()}`;
  }
};
class PostPaymentMethodCreditCard extends CustomerPortalApiCall {
  apiCall(data, paymentMethod) {
    let payload;
    payload = {
      accountId: data.accountId,
      autopay: data.autopay,
      description: null,
      isActive: data.isActive,
      name: generateName(data, paymentMethod),
      paymentMethodToken: paymentMethod.token,
    }
    return this.apiManager.paymentMethodCreditCard.create(payload);
  }
}

const postPaymentMethodCreditCard = (data, paymentMethod) => new PostPaymentMethodCreditCard().execute(data, paymentMethod);
export default postPaymentMethodCreditCard