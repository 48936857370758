import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';

import ProtectedRoute from 'app/ProtectedRoute';
import ThemedRoute from 'app/ThemedRoute';
import BuildPage from 'homePage/BuildPage';
import {Counter} from 'counter/Counter';
import SignIn from 'user/SignIn';
import ForgotPassword from 'user/ForgotPassword';
import NewPassword from 'user/NewPassword';
import importScript from 'customHooks/importScript';


function App() {
  // External Library Imports from public/scripts
  importScript(process.env.PUBLIC_URL + '/scripts/spreedly-iframe-v1.min.js'); //Spreedly iFrame

  // Main App Return
  return (
    <div className='App'>
      <Router>
        <Switch>
          <ProtectedRoute path='/' exact component={BuildPage}/>
          <ProtectedRoute path='/invoices' component={BuildPage}/>
          <ProtectedRoute path='/overview' component={BuildPage}/>
          <ProtectedRoute path='/products' component={BuildPage}/>
          <ProtectedRoute path='/usage' component={BuildPage}/>
          <ProtectedRoute path='/profile' component={BuildPage}/>
          <ThemedRoute path='/login' component={SignIn}/>
          <ThemedRoute path='/forgotPassword' component={ForgotPassword}/>
          <ThemedRoute path='/newPassword' component={NewPassword}/>
          <ProtectedRoute path='/counter' exact component={Counter}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
