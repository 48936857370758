const sanitizeString = (strItem, separator) => {
  const checkSpecialCharsAndEmpty = (value) => {
    return value.includes('\n') || value.includes('\r') || value.includes('\t') || value.includes(',')
      || value.includes(';') || value.includes('.') || value.includes('"') || value.includes('\'')
      || value.includes('`') || value.includes('´');
  };

  if (strItem === undefined || strItem === null) {
    strItem = '';
  }
  if (typeof strItem === 'string') {
    if (checkSpecialCharsAndEmpty(strItem)) {
      strItem = strItem.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ');
      strItem = strItem.replace(/,/g, ',');
      strItem = strItem.replace(/"/g, '""');
      strItem = strItem.replace(/'/g, '\'');
      strItem = strItem.replace(/ +(?= )/g, '');
    }
    if (strItem.indexOf(separator) > 0) {
      strItem = `"${strItem}"`;
    }
  }
  return strItem;
};

export default (arrayOfObjects, separator = ',', lineEnding = '\n') => {
  if (!Array.isArray(arrayOfObjects) || !arrayOfObjects.every((obj) => typeof obj === 'object') || arrayOfObjects.length === 0) return '';
  let csvArray = []
  // CSV Headers
  csvArray.push(Object.keys(arrayOfObjects[0]).join(separator));

  // CSV Data
  arrayOfObjects.forEach((obj) => {
    csvArray.push(
      Object.values(obj)
        .map((obj) => sanitizeString(obj, separator))
        .join(separator)
    );
  })
  return csvArray.join(lineEnding);
}