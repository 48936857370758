import { dateHelpers } from '@lb/utils/helpers';
import dayjs from '../assets/dayjsPlugins.js';
import { getLocaleIdentifier } from './getInfoFromCurrentUser.js';

const defaultTimeFormat = 'h:mm A';

function dateHelperSetup(localeDateFormat, localeDateTimeFormat) {
  const uiDateHelper = dateHelpers;

  uiDateHelper.dbToUIDateTimeConvert = function(strDateTime, showDateOnly) {
    if (showDateOnly) {
      return this.dayjs(strDateTime, this.formats.dbDateTimeFormat).format(localeDateFormat);
    }
    const currDate = this.dayjs.utc(strDateTime, this.formats.dbDateTimeFormat).local();
    return this.dayjs(currDate).format(localeDateTimeFormat);
  }.bind(uiDateHelper);

  uiDateHelper.isoToUIDateTimeConvert = function(isoDateTime, showDateOnly) {
    if (isoDateTime === '') return undefined;
    const dateFormat = (showDateOnly) ? localeDateFormat : localeDateTimeFormat;
    const currDate = this.dayjs.utc(isoDateTime, this.formats.isoDateTimeFormat).local();
    return (currDate.isValid())
      ? this.dayjs(currDate).format(dateFormat) : isoDateTime;
  }.bind(uiDateHelper);

  uiDateHelper.isoToUIDateConvert = function(isoDate) {
    if (isoDate === '') return undefined;
    const currDate = this.dayjs(isoDate, this.formats.isoDateFormat);
    return (currDate.isValid()) ? currDate.format(localeDateFormat) : isoDate;
  }.bind(uiDateHelper);

  uiDateHelper.uiToDayjsConvert = function(strDateTime) {
    if (strDateTime === undefined || strDateTime === '') return '';
    return this.dayjs(strDateTime, [localeDateTimeFormat, localeDateFormat], true);
  }.bind(uiDateHelper);

  uiDateHelper.uiToISODateTimeConvert = function(strDateTime) {
    if (strDateTime === undefined || strDateTime === '') return '';
    const currDate = this.dayjs(strDateTime, localeDateTimeFormat, true);
    return (currDate.isValid())
      ? currDate.utc().format(this.formats.isoDateTimeFormat) : strDateTime;
  }.bind(uiDateHelper);

  uiDateHelper.uiToISODateConvert = function(strDateTime) {
    if (strDateTime === undefined || strDateTime === '') return '';
    const currDate = this.dayjs(strDateTime, localeDateFormat, true);
    return (currDate.isValid())
      ? currDate.format(this.formats.isoDateFormat) : strDateTime;
  }.bind(uiDateHelper);

  uiDateHelper.uiNow = function(showDateOnly) {
    const dateFormat = (showDateOnly) ? localeDateFormat : localeDateTimeFormat;
    return this.dayjs.utc().format(dateFormat);
  }.bind(uiDateHelper);

  // Converts a UI date to a dateTime starting at 12AM
  uiDateHelper.uiDateToDateTimeConvert = function(strDate) {
    if (strDate === undefined || strDate === '') return '';
    return this.dayjs(strDate, localeDateFormat).format(localeDateTimeFormat);
  }.bind(uiDateHelper);

  // Checks for Valid date
  uiDateHelper.isUIDateValid = function (date) {
    return this.dayjs(date, localeDateFormat).isValid();
  }.bind(uiDateHelper);

  // Does not use directly, but calls into uiToISODateConvert
  uiDateHelper.isUIDateValid = function(uiDate, minDate, maxDate) {
    // Dates outside this range are considered invalid and should match our default component restrictions
    const min = this.dayjs(minDate).format(this.formats.isoDateFormat);
    const max = this.dayjs(maxDate).format(this.formats.isoDateFormat);
    const isoDate = this.uiToISODateConvert(uiDate);
    return this.isISODateValid(isoDate)
      && this.isoComparisonFn(isoDate, min, true) >= 0
      && this.isoComparisonFn(max, isoDate, true) >= 0;
  }.bind(uiDateHelper);

  return uiDateHelper;
}

const dateFormat = async () => {
  const localeInfo = await getLocaleIdentifier();
  dayjs.locale(localeInfo);
  const dateFormat = dayjs.localeData().longDateFormat('L');
  const timeFormat = `${dateFormat} ${defaultTimeFormat}`;
  return dateHelperSetup(dateFormat, timeFormat);
};
export default dateFormat;
