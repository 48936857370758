import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetAccountInvoicers extends CustomerPortalApiCall {
  apiCall(invoicerAccountId) {
    return this.apiManager.account.all({
      filters: [{ name: 'invoicerAccountId', value: invoicerAccountId }]
    });
  }
}

const getAccountInvoicers = (invoicerAccountId) => new GetAccountInvoicers().execute(invoicerAccountId);

getAccountInvoicers.propTypes = {
  identity: PropTypes.number.isRequired,
  accountStatusTypeId: PropTypes.number.isRequired,
  accountStatusTypeName: PropTypes.string.isRequired,
  accountTypeId: PropTypes.number.isRequired,
  accountTypeName: PropTypes.string.isRequired,
  accountsReceivableTermsId: PropTypes.number.isRequired,
  accountsReceivableTermsName: PropTypes.string.isRequired,
  actingOwnerId: PropTypes.number.isRequired,
  actingOwnerName: PropTypes.string.isRequired,
  billDay: PropTypes.number,
  billGroupId: PropTypes.number,
  billGroupName: PropTypes.string,
  created: PropTypes.string.isRequired,
  createdByUserId: PropTypes.number.isRequired,
  createdByUserName: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  currencyId: PropTypes.number.isRequired,
  currencyName: PropTypes.string.isRequired,
  extensions: PropTypes.object,
  invoiceDeliveryId: PropTypes.number,
  invoiceDeliveryName: PropTypes.string,
  invoicerAccountId: PropTypes.number,
  invoicerAccountName: PropTypes.string,
  name: PropTypes.string.isRequired,
  taxSettingAccountId: PropTypes.number,
  taxSettingAccountName: PropTypes.string,
  usageBillDay: PropTypes.number,
  usageInvoicerAccountId: PropTypes.number,
  usageInvoicerAccountName: PropTypes.string,
};

export default getAccountInvoicers;
