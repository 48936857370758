import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import useUserStyles from './useUserStyles';
import LogisenseIcon from 'components/LogisenseIcon';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import CheckEmail from './CheckEmail';
import { requestResetLink } from './authService';

export default (props) => {
  const useStyles = useUserStyles();
  const classes = useStyles();
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [submitSucceed, setSubmitSucceed] = useState(false);
  const [userName, setUserName] = useState('');
  const cancelHandler = () => {
    setIsCancelClicked(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const onSuccessCb = (response) => {
      setSubmitSucceed(true);
    };
    const onFailCb = (response) => {
      console.error(response);
    };
    requestResetLink(userName, onSuccessCb, onFailCb);
  };

  const userNameChangeHandler = (event) => {
    setUserName(event.target.value);
  };

  if (isCancelClicked) {
    return <Redirect to={{ from: { pathname: '/' } }}/>;
  } else if (submitSucceed) {
    return <CheckEmail userName={userName}/>;
  }
  return (
    <Container component='main' maxWidth='xs' id='portalScreen_ForgotPassword'>
      <CssBaseline/>
      <Card elevation={3} className={classes.login}>
        <LogisenseIcon className={classes.logo}/>
        <div className={classes.title}>Forgot your Password?</div>
        <form className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='userName'
            label='userName'
            name='userName'
            autoFocus
            onChange={userNameChangeHandler}
          />
          <div className={classes.buttonRow}>
            <Button
              variant='contained'
              color='primary'
              className={clsx(classes.button, classes.floatLeft)}
              onClick={cancelHandler}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={clsx(classes.button, classes.floatRight)}
              disabled={userName.length < 1}
              onClick={submitHandler}
            >
              Continue
            </Button>
          </div>
        </form>
      </Card>
    </Container>
  )
};