import { setBranding } from '../assets/jss/portalStyle';
import { getCurrentOwnerCustomerPortalSettings, resetCurrentUserSettings } from '../helpers/getInfoFromCurrentUser';
import { getStorage } from '../authentication/localStorage.js';
import CustomerPortalApiManagerFactory from '../authentication/CustomerPortalApiManagerFactory';
import serverConfig from '../server.config.json';
import axios from 'axios';

let apiManager, currentUser, authExpiredCb, factory, brandingInfo;
export const buildApiManager = async () => {
  if (apiManager === undefined) {
    try {
      let config = transformServerConfigToApiManagerFactoryConfig(serverConfig);
      factory = new CustomerPortalApiManagerFactory(config);
      factory.initialize();
      apiManager = factory.create(config);
      await initialize();
    } catch {
      apiManager = undefined;
      console.log('Could not build API manager based on the current refresh token');
    }
  }
  return apiManager;
}

let transformServerConfigToApiManagerFactoryConfig = (serverConfig) => {
  var config = (serverConfig === undefined) ? {} : serverConfig;
  var credentials = (config.Credentials === undefined) ? {} : config.Credentials;
  var retry = (serverConfig.Retry === undefined) ? {} : serverConfig.Retry;
  var apiConfiguration = (serverConfig.ApiConfiguration === undefined) ? {} : serverConfig.ApiConfiguration;
  return {
    username: '',
    password: '',
    clientId: credentials.ClientId,
    retry: {
      Period: retry.Period,
      PeriodMultiplier: retry.PeriodMultiplier,
      PeriodCap: retry.PeriodCap,
      MaxAttempts: retry.MaxAttempts,
    },
    api_configuration: {
      auth_url: apiConfiguration.AuthUrl,
      api_url: apiConfiguration.ApiUrl,
      authorization_server: apiConfiguration.AuthorizationServer,
      resource_server: apiConfiguration.ResourceServer,
      version: apiConfiguration.Version,
    },
    api_helper: {
      usePostSearch: false
    },
    type: CustomerPortalApiManagerFactory.ApiManagerTypeEnum.CustomerPortal
  };
}

let executeCallback = (cb, params) => {
  if (typeof cb === 'function') {
    cb(params)
  }
}

let initialize = async () => {
  await apiManager.initialize();
  let storage = getStorage();
  if (parseInt(storage?.invoicerAccountId) !== 0) {
    await setBrandingInfo();
  }
}

export const setBrandingInfo = async () => {
  await getCurrentUser().then(async () => {
    let settings = await getCurrentOwnerCustomerPortalSettings();
    brandingInfo = {
      largeLogo: settings.largeLogo,
      smallLogo: settings.smallLogo,
      primaryColor: settings.primaryColor,
      secondaryColor: settings.secondaryColor,
    };
    setBranding(brandingInfo);
  });
}

export const reauthenticate = async (username, password, onSuccess, onFail) => {
  executeLoginWorkflow(username, password, false, onSuccess, onFail);
}

export const login = async (username, password, onSuccess, onFail) => {
  executeLoginWorkflow(username, password, true, onSuccess, onFail);
}

const executeLoginWorkflow = async (username, password, isLogin, onSuccess, onFail) => {
  let config = transformServerConfigToApiManagerFactoryConfig(serverConfig);
  config.username = username;
  config.password = password;
  config.isLogin = isLogin;
  if (apiManager !== undefined && apiManager.factory !== undefined) {
    config.actingAccountId = apiManager.factory.actingAccountId;
  }
  factory = new CustomerPortalApiManagerFactory(config);
  factory.initialize();
  apiManager = factory.create(config);
  apiManager
    .getToken()
    .then(() => initialize())
    .then(() => {
      executeCallback(onSuccess)
    })
    .catch(err => {
      console.error(err);
      executeCallback(onFail, err);
    });
};

export const isAuthenticated = async () => {
  var isAuthenticated = false;
  if (apiManager !== undefined) {
    await apiManager.getToken().then(() => {
      isAuthenticated = true;
    }).catch(err => {
      console.error(err);
    });
  }
  return isAuthenticated;
}

export const logout = async (cb) => {
  function onComplete() {
    apiManager = undefined;
    currentUser = undefined;
    brandingInfo = undefined;
    resetCurrentUserSettings();
    setBranding();
    executeCallback(cb);
  }

  factory.logout()
    .then(() => {
      console.debug('Logout Successful')
      onComplete();
    })
    .catch(() => {
      console.warn('Error with Logout');
      onComplete();
    });
};

export const getCurrentUser = async () => {
  if (currentUser === undefined && apiManager !== undefined) {
    currentUser = await apiManager.getCurrentUser()
  }
  return currentUser;
}

export const getApiManager = async () => {
  if (apiManager === undefined) {
    await buildApiManager();
  }
  return apiManager;
}

export const requestResetLink = (userName, onSuccessCb, onFailCb) => {
  let config = transformServerConfigToApiManagerFactoryConfig(serverConfig);
  let apiConfig = config.api_configuration;
  let postBody = {
    userName: userName
  };
  axios.post(
    `${apiConfig.api_url}/${apiConfig.resource_server}/api/v${apiConfig.version}/internal/CustomerPortal/Access/Password/ResetLink`,
    postBody,
    {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      }
    })
    .then((response) => {
      onSuccessCb(response)
    })
    .catch((error) => {
      onFailCb(error.response);
    });
};

export const requestPasswordReset = (data, onSuccessCb, onFailCb) => {
  let config = transformServerConfigToApiManagerFactoryConfig(serverConfig);
  let apiConfig = config.api_configuration;
  let postBody = {
    userName: data.userName,
    clearTextPassword: data.password,
    resetLinkKey: data.resetLinkKey
  };
  axios.post(
    `${apiConfig.api_url}/${apiConfig.resource_server}/api/v${apiConfig.version}/internal/CustomerPortal/Access/Password/Reset`,
    JSON.stringify(postBody),
    {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      }
    })
    .then((response) => {
      onSuccessCb(response)
    })
    .catch((error) => {
      onFailCb(error.response);
    });
};

export const getUserName = async () => {
  const user = await getCurrentUser();
  return user === undefined ? undefined : user.name;
}

export const getAuthExpiredCb = () => {
  return authExpiredCb;
}

export const setAuthExpiredCb = (newCallback) => {
  authExpiredCb = newCallback;
}

export const refreshToken = async (actingAccountId) => {
  try {
    await apiManager.changeActingAccountId(actingAccountId);
  } catch (err) {
    console.error(err);
    let authExpiredCallback = getAuthExpiredCb();
    if (typeof (authExpiredCallback) === 'function') {
      await authExpiredCallback(false);
    }
  }
}