import React from 'react';
import { Route } from 'react-router-dom';
import AppThemeProvider from './AppThemeProvider'

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <AppThemeProvider>
          <Component {...props} />
        </AppThemeProvider>
      )}
    />
  );
};

