import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetPaymentConfiguration extends CustomerPortalApiCall {
  apiCall() {
    return this.apiManager.paymentConfiguration.all();
  }
}

const getPaymentConfiguration = () => new GetPaymentConfiguration().execute();

getPaymentConfiguration.propTypes = {
  environmentKey: PropTypes.string.isRequired,
};

export default getPaymentConfiguration;