import PropTypes from 'prop-types';
import React from 'react';

import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetAccountPackageSummary extends CustomerPortalApiCall {
  apiCall(currentAccountId, pageIndex, pageSize) {
    return this.apiManager.getAccountPackageSummary(currentAccountId, pageIndex, pageSize)
  }
}

const getAccountPackageSummary = (currentAccountId, pageIndex, pageSize) => {
  return new GetAccountPackageSummary().execute(currentAccountId, pageIndex, pageSize);
}

getAccountPackageSummary.propTypes = {
  identity: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  accountPackageStatusTypeId: PropTypes.number,
  accountPackageStatusTypeName: PropTypes.string,
  nextBill: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  details: PropTypes.shape({
    temporalData: PropTypes.shape({
      items: PropTypes.array.isRequired,
    }),
  }),
};

export default getAccountPackageSummary;