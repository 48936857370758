import React from 'react';

import { withStyles } from "@material-ui/core"
import TextField from '@material-ui/core/TextField';

import TextFieldWithOnEnterAction from './TextFieldWithOnEnterAction'

const styles = (theme) => {

}

function ChangePasswordFields (props) {
  const { password, confirmPassword, confirmPasswordError, onEnterPress} = props;
  return (
    <>
      <TextField
        variant="outlined"
        type="password"
        margin="normal"
        required
        fullWidth
        name="password"
        label="New Password"
        id="newPassword"
        value={password.value}
        onChange={password.onChange}
      />
      <TextFieldWithOnEnterAction
        variant="outlined"
        type="password"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm New Password"
        id="confirmPassword"
        value={confirmPassword.value}
        error={confirmPasswordError !== undefined && confirmPasswordError.length > 0}
        helperText={confirmPasswordError}
        onChange={confirmPassword.onChange}
        onEnterPress={onEnterPress}
      />
    </>
  )
}

export default withStyles(styles)(ChangePasswordFields);