import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import localeData from 'dayjs/plugin/localeData.js';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import './locales.js'

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

export default dayjs;