import React, { useState } from 'react'
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { grayColor, successColor, whiteColor, yellowColor } from '../assets/jss/portalStyle';
import ConfirmDialog from '../components/ConfirmDialog';
import postCancelAccountPackages from '../api/postCancelAccountPackages';
import ServiceHeader from './ServiceHeader';
import ServiceListItem from './ServiceListItem'
import { Typography } from '@material-ui/core';
import ProcessingDialog from 'components/ProcessingDialog';
import InputLabel from '@material-ui/core/InputLabel';

const billingBaseTypeEnum = {
  BILLING: { uid: 1, caption: 'Billing' },
  NON_BILLABLE: { uid: 2, caption: 'Non-Billable' },
  CANCELED: { uid: 3, caption: 'Canceled' },
}

const accountStatusBg = (uid) => {
  switch (uid) {
    case billingBaseTypeEnum.BILLING.uid: return { backgroundColor: successColor[0] };
    case billingBaseTypeEnum.NON_BILLABLE.uid: return { backgroundColor: yellowColor };
    case billingBaseTypeEnum.CANCELED.uid: return { backgroundColor: grayColor[3] };
  }
};

function RowAttributes(props) {
  const { nextBill, status, onCancelClick, preventChanges, allowCancelPackage, classes } = props;
  return (
    <Grid container direction='row' justifyContent='flex-start'>
      <Grid item xs className={classes.gridCell}>
        <div className={classes.field}><span className={classes.fieldLabel}>Next Bill Date: </span>{nextBill}</div>
      </Grid>
      <Grid item xs className={classes.gridCell}>
        <div className={classes.field}><span className={classes.fieldLabel}>Status: </span>{status}</div>
      </Grid>
      {allowCancelPackage && !preventChanges &&
        <Grid item xs>
          <Button className={classes.cancelButton} color='primary' variant='contained' onClick={() => onCancelClick(true)}>
            <Typography variant='caption'>Cancel</Typography>
          </Button>
        </Grid>
      }
    </Grid>
  )
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: 'larger',
    },
    '&:not(:first-child)': {
      borderTop: '#e0e0e0 2px solid',
    },
  },
  headerTopBorder: {
    backgroundColor: grayColor[10],
  },
  headerBorder: {
    backgroundColor: grayColor[10],
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  bolderFont: {
    fontWeight: '700',
  },
  gridCell: {
    flexGrow: 0
  },
  field: {
    width: '200px'
  },
  fieldLabel: {
    fontStyle: 'italic',
    fontWeight: '700',
  },
  statusLabel: {
    color: whiteColor,
    margin: 'auto',
    padding: '5px',
    fontSize: 'large',
    borderRadius: '5px',
  },
  smallerCell: {
    width: '150px',
  },
  cancelButton: {
    height: '20px',
    width: '60px',
  },
  confirmationBody: {
    color: 'rgb(0,0,0,0.65)',
    fontWeight: 700
  },
});

export default function Row(props) {
  const { onCancelPackage, currentAccount, row, allowCancelPackage } = props;
  const { id, name, quantity, nextBill, services, status, statusId, baseBillingStatusTypeId, preventChanges } = row;
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelApiCall, setCancelApiCall] = useState(null);
  const [serviceStatusStartIdx, setServiceStatusStartIdx] = useState(0);
  const [serviceStatusEndIdx, setServiceStatusEndIdx] = useState(1);
  const classes = useRowStyles();
  const statusCount = serviceStatusEndIdx - serviceStatusStartIdx + 1;
  const statusScrollCellCount = 2;
  const nameColSpan = 2;

  const handleLeftStatusScrollClick = () => {
    if (serviceStatusStartIdx > 0) {
      setServiceStatusStartIdx(serviceStatusStartIdx - 1);
      setServiceStatusEndIdx(serviceStatusEndIdx - 1);
    }
  };
  const handleRightStatusScrollClick = () => {
    setServiceStatusStartIdx(serviceStatusStartIdx + 1);
    setServiceStatusEndIdx(serviceStatusEndIdx + 1);
  }

  const handleCancelPackageClick = (e) => {
    setOpenCancelModal(true);
  }
  const handleConfirmClick = (e) => {
    const apiCall = postCancelAccountPackages(id).then(() => {
      onCancelPackage(id, statusId, nextBill);
    });

    setCancelApiCall(apiCall);
    setOpenCancelModal(false);
  }
  const handleCancelClick = () => {
    setOpenCancelModal(false);
  }

  const handleProcessingDialogClosed = () => {
    setCancelApiCall(null);
  }

  return (
    <React.Fragment>
      <TableRow className={clsx(classes.root, classes.headerTopBorder)}>
        <TableCell colSpan={nameColSpan + statusScrollCellCount + statusCount} className={classes.bolderFont}>
          {name}
        </TableCell>
        <TableCell className={classes.bolderFont} align='right'>Qty</TableCell>
        <TableCell className={clsx(classes.bolderFont, classes.smallerCell)} align='center'>
          <InputLabel className={classes.statusLabel} style={accountStatusBg(baseBillingStatusTypeId)} variant='caption'>{status}</InputLabel>
        </TableCell>
      </TableRow>
      <TableRow className={classes.headerBorder}>
        <TableCell colSpan={nameColSpan + statusScrollCellCount + statusCount}>
          <RowAttributes
            preventChanges={preventChanges}
            allowCancelPackage={allowCancelPackage}
            nextBill={nextBill}
            status={status}
            classes={classes}
            onCancelClick={handleCancelPackageClick} />
        </TableCell>
        <TableCell align='right'>{quantity}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      {
        services.length > 0 &&
        <ServiceHeader
          row={row}
          startIdx={serviceStatusStartIdx}
          endIdx={serviceStatusEndIdx}
          leftClickHandler={handleLeftStatusScrollClick}
          rightClickHandler={handleRightStatusScrollClick}
        />
      }
      {
        services.map((service) => (
          <ServiceListItem key={service.id} service={service} startIdx={serviceStatusStartIdx} endIdx={serviceStatusEndIdx} />
        ))
      }
      {
        <ConfirmDialog
          open={openCancelModal}
          title='Package Cancelation'
          handleConfirmClick={handleConfirmClick}
          handleCancelClick={handleCancelClick}
          content={
            <>
              <DialogContentText className={classes.confirmationBody} variant='body'>
                Are you sure you want to cancel {quantity} packages?
              </DialogContentText>
              <DialogContentText variant='subtitle'>
                This action cannot be reversed
              </DialogContentText>
            </>
          }
        />
      }
      {
        cancelApiCall !== null &&
        <ProcessingDialog
          apiCall={cancelApiCall}
          successMessage='Your packages were successfully canceled.'
          failedMessage='We were unable to cancel your packages.'
          onClose={handleProcessingDialogClosed}
        />
      }
    </React.Fragment>
  );
}