import React from 'react';
import Grid from "@material-ui/core/Grid";
import Header from "./Header";

export default (props) => {
  const { title } = props;
  return (
    <>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs>
          <Header id={'PageTitle'} label={title}/>
        </Grid>
      </Grid>
      <div>LOADING</div>
    </>
  )
}