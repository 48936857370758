import React, { useState, useCallback } from 'react';
import {
  makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button,
  Checkbox, FormControl, FormControlLabel, Grid, TextField, IconButton
} from '@material-ui/core';
import putPaymentMethod from '../../../api/putPaymentMethod';
import EditIcon from '@material-ui/icons/Edit';
import { DataEnums } from '@lb/utils/enums';
import ValidationErrors from '../../../components/ValidationErrors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBody: {
    marginBlock: '8px'
  },
  formControl: {
    marginBlock: '6px'
  },
  spreedlyInput: {
    borderBottom: '1px',
    height: '48px'
  }
}));

const EditPaymentDialog = (props) => {
  const { autopay, isActive, paymentMethodId, disabled, name, accountNumber, paymentMethodBaseId, hasDefault, paymentMethodNameList, onSuccessfulPayment } = props;
  const classes = useStyles();
  const [newName, setNewName] = useState(name);
  const [open, setOpen] = React.useState(false);
  const [defaultModalCheck, setDefaultModalCheck] = useState(autopay);
  const [activeModalCheck, setActiveModalCheck] = useState(isActive);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleOpen = () => {
    setNewName(name);
    setDefaultModalCheck(autopay);
    setActiveModalCheck(isActive);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const saveClose = useCallback(() => {
    setValidationErrors([]);
    if (paymentMethodNameList.includes(newName) && newName !== name) {
      setValidationErrors([{ error: 'Invalid Name', message: 'Name is already in use.' }]);
      return
    }
    if (newName === undefined || newName === null || newName === '') {
      setValidationErrors([{ error: 'Invalid Name', message: 'Invalid Name.' }]);
      return
    }
    const putBody = {
      name: newName,
      identity: paymentMethodId,
      isActive: activeModalCheck,
      autopay: activeModalCheck ? defaultModalCheck : false,
    };
    putPaymentMethod(putBody).then(() => onSuccessfulPayment());
    setValidationErrors([]);
    handleClose();
  }, [defaultModalCheck, activeModalCheck, paymentMethodId, newName]);

  return (props !== undefined) && (
    <div>
      <IconButton aria-label='pencil'
        disabled={disabled}
        onClick={handleOpen}>
        <EditIcon color={disabled ? 'disabled' : 'primary'} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>
          Edit Payment Method
        </DialogTitle>
        <DialogContent className={classes.container}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id='newName'
              value={newName}
              onChange={(event) => setNewName(event.target.value)}
              label='Name'
              fullWidth
            />
          </FormControl>
          <ValidationErrors validationErrors={validationErrors} />
          <FormControl className={classes.formBody} fullWidth>
            {
              (
                <>
                  <TextField
                    id="outlined-read-only-input"
                    label={paymentMethodBaseId === DataEnums.PaymentBaseTypeEnum.CREDIT_CARD ? 'Card Number' : 'Account Number'}
                    defaultValue={accountNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </>
              )
            }
            <FormControl fullWidth className={classes.formControl}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='Active'
                        checked={activeModalCheck}
                        color='primary'
                        onChange={(event) => setActiveModalCheck(event.target.checked)}
                      />
                    }
                    label='Active'
                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='Default'
                        disabled={!((activeModalCheck && !hasDefault) || defaultModalCheck)}
                        checked={defaultModalCheck}
                        color='primary'
                        onChange={(event) => setDefaultModalCheck(event.target.checked)}
                      />
                    }
                    label='Default'
                  />
                </Grid>
              </Grid>
            </FormControl>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveClose} variant='contained' color='primary'>
            Save
          </Button>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditPaymentDialog;
