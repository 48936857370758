import React from 'react'
import TextField from '@material-ui/core/TextField';

export default (props) => {
  const { onEnterPress, ...rest } = props;
  return (
    <TextField 
      onKeyPress={ (typeof(onEnterPress) !== 'function') ? undefined : event => {
        if (event.key === 'Enter') {
          onEnterPress(event);
        }
      }}
      { ...rest }
    />
  )
}