import React from 'react';
import {usePagination, useTable} from 'react-table';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {CircularProgress, withStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {blackColor, whiteColor} from '../assets/jss/portalStyle';
import {bgShadeClass} from '../assets/jss/classes/bgShade'
import TablePaginationActions from './TablePaginationActions';

const styles = () => ({
  root: {
    overflow: 'auto',
  },
  cellAlignCenter: {
    textAlign: 'center',
  },
  cellAlignRight: {
    textAlign: 'right',
  },
  cellAlignLeft: {
    textAlign: 'left',
  },
  header: {
    backgroundColor: blackColor,
    color: whiteColor,
    textAlign: 'center',
  },
  headerFirstChild: {
    backgroundColor: blackColor,
    color: whiteColor,
    textAlign: 'left',
    borderTopLeftRadius: '12px',
  },
  headerLastChild: {
    backgroundColor: blackColor,
    color: whiteColor,
    textAlign: 'center',
    borderTopRightRadius: '12px',
  },
  pagination: {
    minHeight: '32px',
    overflow: 'inherit',
  },
  ...bgShadeClass,
})

export const classNames = {
  CURRENCY: 'currency-info',
  DATE: 'date-info',
  ICON: 'icon-info',
  STRING: 'string-info',
}

function Table(props) {
  const matches = useMediaQuery('(min-width:480px) and (min-height: 480px');

  const {
    classes,
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    orderBy,
    order,
    handleSortChange,
    totalRows,
  } = props;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination hook that we'll handle our own data fetching
                              // This means we'll also have to provide our own pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const createSortHandler = (property) => (event) => {
    handleSortChange(event, property);
  };

  const StyledTableSortLabel = withStyles({
    icon: {
      '& path': {
        fill: '#eee'
      },
    },
    root: {
      '&.MuiTableSortLabel-active': {
        color: '#fff',
      },
    },
  })(TableSortLabel);

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize]);

  const alignmentStyleByClass = (className) => {
    switch (className) {
      case classNames.CURRENCY:
        return classes.cellAlignRight;
      case classNames.ICON:
      case classNames.DATE:
        return classes.cellAlignCenter;
      case classNames.STRING:
      default:
        return classes.cellAlignLeft;
    }
  };

  const alignmentStyleByAlign = (alignment) => {
    switch (alignment.toLowerCase()) {
      case 'right':
        return classes.cellAlignRight;
      case 'center':
      case 'centre':
        return classes.cellAlignCenter;
      case 'left':
      default:
        return classes.cellAlignLeft;
    }
  };
  // Render the UI for your table
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.root}>
        <MuiTable {...getTableProps()} aria-label='list of items'>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => {
                  const className = column.className;
                  const alignStyle = (column.align !== undefined) ? alignmentStyleByAlign(column.align) : alignmentStyleByClass(className);
                  const orderStyle = (idx === 0) ? classes.headerFirstChild :
                    (idx === headerGroup.headers.length - 1) ? classes.headerLastChild : classes.header;
                  return (
                    <TableCell
                      id={column.id}
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={clsx(alignStyle, orderStyle)}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      {column.isSortable ?
                        <StyledTableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.render('Header')}
                        </StyledTableSortLabel> :
                        column.render('Header')
                      }
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {loading && (
              <TableRow>
                <TableCell colSpan={8} align='center' className={classes.bgShade}>
                  <CircularProgress/>
                </TableCell>
              </TableRow>
            )}
            {(!loading) && (totalRows > 0) && page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const column = cell.column;
                    const className = column.className;
                    const alignStyle = (column.align !== undefined) ? alignmentStyleByAlign(column.align) : alignmentStyleByClass(className);
                    return (
                      <TableCell {...cell.getCellProps()} className={alignStyle} style={{ padding: '4px 12px' }}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {
        (totalRows > 0) && (
          <TablePagination
            id={'PaginationControl'}
            className={classes.pagination}
            rowsPerPageOptions={matches ? [10, 15, 25] : [10]}
            component='div'
            count={totalRows}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              id: 'PaginationRowsPerPageSelect',
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={gotoPage}
            onRowsPerPageChange={e => {
              setPageSize(Number(e.target.value))
            }}
            ActionsComponent={TablePaginationActions}
          />
        )
      }
    </React.Fragment>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.oneOf(['asc', 'desc']),
  handleSortChange: PropTypes.func,
  totalRows: PropTypes.number.isRequired,
};

export default withStyles(styles)(Table);