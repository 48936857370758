import React, {useCallback, useEffect} from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {withStyles} from '@material-ui/core/styles';


import getAccount from '../api/getAccount';
import getAccountSummary from '../api/getAccountSummary';
import getInvoices from '../api/getInvoices';
import getInvoiceRender from '../api/getInvoiceRender';

import dateFormat from '../helpers/dateFormat';
import {getCurrencyFormat} from '../helpers/numberFormat';
import {portalConfigTransform} from '../helpers/transforms.js';
import {getCurrentOwnerCustomerPortalSettings} from '../helpers/getInfoFromCurrentUser'
import Header from '../components/Header';
import Loading from '../components/Loading';

import {grayColor} from '../assets/jss/portalStyle';

import EmptyListMessage from '../components/EmptyListMessage';
import InvoiceCard from './InvoiceCard';
import MakePaymentDialog from './MakePaymentDialog';
import Table, {classNames} from '../components/Table';

const styles = (theme) => ({
  gridCardRoot: {
    marginBottom: theme.spacing(1),
  }
});

const screenId = 'portalScreen_Invoices';
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: grayColor[11],
    color: grayColor[8],
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function InvoiceCards(props) {
  const { classes, summaryData, cardGridSize } = props;
  return (
    <>
      <Grid item xs={cardGridSize} className={classes.gridCardRoot}>
        <InvoiceCard labelId={'InvoiceCardBalanceLabel'} label={'Balance'} amountId={'InvoiceCardBalanceAmount'}
                     amount={summaryData.balance}/>
      </Grid>
      <Grid item xs={cardGridSize} className={classes.gridCardRoot}>
        <InvoiceCard labelId={'InvoiceCardDueLabel'} label={'Due'} amountId={'InvoiceCardDueAmount'}
                     amount={summaryData.balanceDue}/>
      </Grid>
    </>
  )
}

function handleInvoiceDownloadClick(invoiceId) {
  // Solution for When CORS is setup
  // import('axios')
  //   .then((importedLib) => {
  //     const axios = importedLib.default;
  //     getInvoiceRender(invoiceId)
  //       .then((invoiceResults) => {
  //         let pdfLink = invoiceResults.link;
  //         if (pdfLink === '') {
  //           console.warn('No pdf available to download');
  //         } else {
  //           axios.get(pdfLink)
  //             .then((pdfResponse) => {
  //               let invoiceName = invoiceResults.invoiceName;
  //               let fileName = converters.invoiceToSafePdfName(invoiceName);
  //               download(new Blob(pdfResponse.data), fileName, 'application/pdf');
  //             })
  //             .catch((e) => {
  //               console.error(e.message);
  //             })
  //         }
  //       });
  //   })
  getInvoiceRender(invoiceId)
    .then((invoiceResults) => {
      let pdfLink = invoiceResults.link;
      if (pdfLink === '') {
        console.warn('No pdf available to download');
      } else {
        window.open(pdfLink);
      }
    });

}

const roundAccurately = (number, decimalPlaces) => Number(Math.round(Number(number + 'e' + decimalPlaces)) + 'e' + decimalPlaces * -1);
const accountSummaryTransform = (summary, isInvoicerAccount, currencyFormatter) => {
  if (summary === undefined) {
    summary = { balance: 0, balanceDue: 0 }
  }
  return {
    balance: currencyFormatter.format(isInvoicerAccount ? summary.balance : 0),
    balanceDue: currencyFormatter.format(isInvoicerAccount ? summary.balanceDue : 0),
    noFormatBalance: isInvoicerAccount ? roundAccurately(summary.balance, 2) : 0,
    noFormatBalanceDue: isInvoicerAccount ? roundAccurately(summary.balanceDue, 2) : 0,
  }
}


function Invoices(props) {
  const { classes, ...rest } = props;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPageIdx(1);
  };

  const { currentAccount } = props;
  const [dateFormatter, setDateFormat] = React.useState();
  const [currencyFormatter, setCurrencyFormat] = React.useState(() => ({ format: (number) => number }));
  const columns = React.useMemo(
    () => [
      {
        Header: 'Invoice',
        accessor: 'col1',
        id: 'ColumnHeaderInvoice',
        isSortable: true,
        width: '15%',
        columnName: 'name'
      },
      {
        Header: 'Date',
        accessor: 'col2',
        id: 'ColumnHeaderDate',
        isSortable: true,
        Cell: ({ value }) => dateFormatter.dbToUIDateTimeConvert(value, true),
        className: classNames.DATE,
        width: '10%',
        columnName: 'date'
      },
      {
        Header: 'Due',
        accessor: 'col3',
        id: 'ColumnHeaderDue',
        isSortable: true,
        Cell: ({ value }) => dateFormatter.dbToUIDateTimeConvert(value, true),
        className: classNames.DATE,
        width: '10%',
        columnName: 'due'
      },
      {
        Header: 'Charges',
        accessor: 'col4',
        id: 'ColumnHeaderCharges',
        isSortable: true,
        Cell: ({ value }) => currencyFormatter.format(value),
        className: classNames.CURRENCY,
        width: '15%',
        columnName: 'totalCharge'
      },
      {
        Header: 'Tax',
        accessor: 'col5',
        id: 'ColumnHeaderTax',
        isSortable: true,
        Cell: ({ value }) => currencyFormatter.format(value),
        className: classNames.CURRENCY,
        width: '10%',
        columnName: 'totalTax'
      },
      {
        Header: 'Total',
        accessor: 'col6',
        id: 'ColumnHeaderTotal',
        isSortable: true,
        Cell: ({ value }) => currencyFormatter.format(value),
        className: classNames.CURRENCY,
        width: '15%',
        columnName: 'total'
      },
      {
        Header: 'Balance',
        accessor: 'col7',
        id: 'ColumnHeaderBalance',
        isSortable: true,
        Cell: ({ value }) => currencyFormatter.format(value),
        className: classNames.CURRENCY,
        width: '15%',
        columnName: 'balance'
      },
      {
        Header: 'View',
        accessor: 'col8',
        id: 'ColumnHeaderDownload',
        isSortable: false,
        Cell: (target) => {
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  {'View a copy of this '}<em><b>{'invoice'}</b></em>{' by clicking on this icon button'}
                </React.Fragment>
              }
            >
              <IconButton aria-label='view'
                          onClick={(event) => {
                            event.preventDefault();
                            let invoiceId = target.cell.value;
                            handleInvoiceDownloadClick(invoiceId);
                          }}>
                <VisibilityIcon color='primary'/> {/* NOTE: set color to 'disabled' when implemented or remove */}
              </IconButton>
            </HtmlTooltip>
          )
        },
        className: classNames.ICON,
        width: '10%',
      }
    ],
    [dateFormatter, currencyFormatter]
  );
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [customerPortalConfig, setCustomerPortalConfig] = React.useState({});
  const [summaryData, setSummaryData] = React.useState({ balance: '', balanceDue: '' });
  const [isInvoicer, setIsInvoicer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [pageIdx, setPageIdx] = React.useState(1);
  const [rowsPerPage, setPageSize] = React.useState(10);
  // order by Date descending to start
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('ColumnHeaderDate');

  const onSuccessfulPayment = () => {
    getAccountSummary(currentAccount.uid).then((result) => {
      setSummaryData(accountSummaryTransform(result, isInvoicer, currencyFormatter));
    })

    const accountId = currentAccount.uid
    if (accountId === undefined) return;

    const getSortColumn = (columnId) => {
      const column = columns.find(item => item.id === columnId);
      return column ? column.columnName : 'date';
    };
    const pageIndex = pageIdx;
    const pageSize = rowsPerPage;
    setInvoiceLoading(true);

    getInvoices(
      accountId,
      {
        pageNumber: pageIndex,
        pageSize: pageSize,
        order,
        orderBy: getSortColumn(orderBy)
      }
    ).then((results) => {
      let invoices = results.items;
      let totalCount = results.totalCount;
      setData(invoiceTransform(invoices));
      setPageCount(Math.ceil(totalCount / pageSize));
      setTotalRows(totalCount);
      setPageSize(pageSize);
      setPageIdx(pageIndex);
      setInvoiceLoading(false);
    });
  }

  const invoiceTransform = data => data.map((item) => {
    return {
      id: item.identity,
      name: item.name,
      balance: item.balance,
      col1: item.name,
      col2: item.date,
      col3: item.due,
      col4: item.totalCharge,
      col5: item.totalTax,
      col6: item.total,
      col7: item.balance,
      col8: item.identity,
    };
  });

  const fetchInvoices = useCallback(({ pageSize, pageIndex }) => {
    const getSortColumn = (columnId) => {
      const column = columns.find(item => item.id === columnId);
      return column ? column.columnName : 'date';
    };

    // This will get called when the table needs new data like page size change or page# change
    const accountId = currentAccount.uid
    if (accountId === undefined) return;

    pageIndex += 1;
    // Set the loading state
    setInvoiceLoading(true);

    getInvoices(
      accountId,
      {
        pageNumber: pageIndex,
        pageSize,
        order,
        orderBy: getSortColumn(orderBy)
      }
    ).then((results) => {
      let invoices = results.items;
      let totalCount = results.totalCount;
      setData(invoiceTransform(invoices));
      setPageCount(Math.ceil(totalCount / pageSize));
      setTotalRows(totalCount);
      setPageSize(pageSize);
      setPageIdx(pageIndex);
      setInvoiceLoading(false);
    });
  }, [order, orderBy, currentAccount, columns]);

  useEffect(() => {     //Fetch all data needed on Initial Load
    const accountId = currentAccount.uid
    const summaryPromise = getAccountSummary(accountId);
    const accountPromise = getAccount(accountId);
    const invoicePromise = getInvoices(
      accountId,
      {
        pageIndex: pageIdx,
        pageSize: rowsPerPage
      }
    );
    const portalConfig = getCurrentOwnerCustomerPortalSettings();

    Promise.all([summaryPromise, accountPromise, invoicePromise, portalConfig])
      .then((results) => {
        const accountSummary = results[0];
        const currentAccount = results[1];
        const invoices = results[2];
        const portalConfig = results[3];
        setCustomerPortalConfig(portalConfigTransform(portalConfig));

        let totalCount = results[2].totalCount;
        setData(invoiceTransform(invoices.items));
        setPageCount(Math.ceil(totalCount / rowsPerPage));
        setTotalRows(totalCount);

        const isInvoicerAccount = (currentAccount.invoicerAccountId === currentAccount.identity ||
          currentAccount.usageInvoicerAccountId === currentAccount.identity);
        setIsInvoicer(isInvoicerAccount);
        let currencyPromise = getCurrencyFormat(currentAccount.currencyCode);
        let dateTransformPromise = dateFormat();
        Promise.all([currencyPromise, dateTransformPromise])
          .then((formatters) => {
            const [currencyFormatter, dateFormatter] = formatters;
            setCurrencyFormat(currencyFormatter);
            setDateFormat(dateFormatter);
            setSummaryData(accountSummaryTransform(accountSummary, isInvoicerAccount, currencyFormatter));
            setLoading(false);
          });
      })
  }, [currentAccount, pageIdx, rowsPerPage]);

  const gridSpacing = 2;
  const matches = useMediaQuery('(min-width:780px)');
  return (
    loading ? <Loading title={'Invoices'}/> :
      (!isInvoicer || totalRows === 0)
        ?
        <EmptyListMessage
          screenId={screenId}
          headerLabel='Invoices'
          entityName='invoices'
          {...rest}
        />
        :
        <>
          <Grid container spacing={gridSpacing} direction="row" justifyContent={matches ? "space-between" : "flex-start"} alignItems='center' id={screenId}>
            <Grid item xs id='hasInvoices'>
              <Header id={'PageTitle'} label={'Invoices'}/>
            </Grid>
            {
              customerPortalConfig.allowPayments &&
              <Grid item xs>
                <MakePaymentDialog
                  title={'Make Payment'}
                  buttonLabel={'Make Payment'}
                  accountId={currentAccount.uid}
                  accountCurrencyCode={currentAccount.currencyCode}
                  accountBalance={summaryData.noFormatBalance}
                  currencyFormatter={currencyFormatter}
                  onSuccessfulPayment={onSuccessfulPayment}
                />
              </Grid>
            }
            {
              matches &&
              <InvoiceCards classes={classes} summaryData={summaryData} cardGridSize={3}/>
            }
          </Grid>
          {
            !matches &&
            <Grid container spacing={gridSpacing} direction="row" justifyContent="flex-start" alignItems='center'>
              <InvoiceCards classes={classes} summaryData={summaryData} cardGridSize={6}/>
            </Grid>
          }
          <Table
            columns={columns}
            data={data}
            fetchData={fetchInvoices}
            loading={invoiceLoading}
            pageCount={pageCount}
            orderBy={orderBy}
            order={order}
            handleSortChange={handleRequestSort}
            totalRows={totalRows}
          />
        </>
  )
}

export {handleInvoiceDownloadClick};
export default withStyles(styles)(Invoices);
