import { makeStyles } from '@material-ui/core/styles';
export default () => {
  return makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      margin: theme.spacing(1,1,2,1)
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3)
    },
    greeting: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    message: {
      width: '100%',
      marginBottom: theme.spacing(1)
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    buttonRow: {
    },
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minWidth: '48%'
    },
    floatLeft: {
      float: 'left'
    },
    floatRight: {
      float: 'right'
    },
    content: {
      padding: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    login: {
      marginTop: theme.spacing(8),
      padding:  theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));
}