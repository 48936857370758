import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetAccountStatusType extends CustomerPortalApiCall {
  apiCall(searchRequest) {
    return this.apiManager.accountStatusType.all(searchRequest);
  }
}

const getAccountStatusType = (searchRequest) => new GetAccountStatusType().execute(searchRequest);

getAccountStatusType.propTypes = {
  identity: PropTypes.number.isRequired,
  baseBillingStatusTypeId: PropTypes.number.isRequired,
  baseBillingStatusTypeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  preventChanges: PropTypes.bool.isRequired,
};

export default getAccountStatusType;