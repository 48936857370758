import React from 'react'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';

import { refreshToken } from '../user/authService'

const styles = (theme) => ({
  changeAccountButton: {
    fontSize: '14px',
    textDecoration: 'underline', 
    cursor: 'pointer',
  },
  grid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
});

function ChangeAccount(props) {
  const { classes, invoicerAccounts, setCurrentAccount, text, buttonClass } = props;
  const [accountFilter, setAccountFilter] = React.useState('');
  const [anchorChangeAccount, setAnchorChangeAccount] = React.useState(null);
  
  if (!Array.isArray(invoicerAccounts) || invoicerAccounts.length <= 1) {
    return false;
  }

  const openChangeAccount = Boolean(anchorChangeAccount);
  const filteredAccounts = invoicerAccounts.filter((account) => {
    return (
      accountFilter === undefined ||
      accountFilter.trim() === '' ||
      account.name.toLowerCase().startsWith(accountFilter.toLowerCase())
    )
  })

  const handleChangeAccountClose = () => {
    setAnchorChangeAccount(null);
  };

  const handleAccountFilterChange = (event) => {
    setAccountFilter(event.currentTarget.value);
  }

  const handleChangeAccountMenu = (event) => {
    setAnchorChangeAccount(event.currentTarget);
  };
  
  const handleAccountChange = async (account) => {
    await refreshToken(account.uid);
    setCurrentAccount(account);
    setAccountFilter('');
    handleChangeAccountClose();
  }

  let buttonClassName = classes.changeAccountButton;
  if (buttonClass !== undefined) {
    buttonClassName = clsx(buttonClassName, buttonClass);
  }
  return (
    <React.Fragment>
      <Button
        id='ChangeAccountMenuButton'
        className={buttonClassName}
        onClick={handleChangeAccountMenu}
        variant='caption'
      >
        {text}
      </Button>
      <Menu
        id='AppBarChangeAccountMenu'
        anchorEl={anchorChangeAccount}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openChangeAccount}
        onInput={(e) => e.preventDefault()}
        onClose={handleChangeAccountClose}
        variant='selectedMenu'
      >
        <Grid 
          container 
          direction='column'
          className={classes.grid}>
          <Grid item>
            <TextField 
              id='accountChangeFilter' 
              label='Search'
              helperText={`${filteredAccounts.length} accounts found`}
              onChange={handleAccountFilterChange}
              variant='outlined'
              type='search'
              value={accountFilter}
              margin='normal'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            
          </Grid>
          <Grid item>
            {
              filteredAccounts.map((account) => (
                <MenuItem 
                  id={'MenuItem' + account.name}
                  key={'MenuItem' + account.name}
                  onClick={() => handleAccountChange(account)}
                >
                  <PersonIcon />

                  {account.name}
                </MenuItem>
              ))
            }
          </Grid>
        </Grid>
      </Menu>
    </React.Fragment>
  )
}

export default withStyles(styles)(ChangeAccount);