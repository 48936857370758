import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Hidden from '@material-ui/core/Hidden';

import { drawerClass, drawerWidth, logoHeight } from '../assets/jss/classes/drawer';

import Login from '../components/Login'
import RedirectToLogin from '../components/RedirectToLogin'
import Invoices from '../invoices/Invoices';
import Overview from '../overview/Overview';
import Products from '../products/Products';
import Usage from '../usage/Usage';
import Profile from '../profile/Profile';
import { reauthenticate, logout, getUserName, setAuthExpiredCb, isAuthenticated } from '../user/authService';
import { getCurrentAccountId, getLocaleIdentifier } from '../helpers/getInfoFromCurrentUser';
import getAccountInvoicers from '../api/getAccountInvoicers'
import getCustomerPortalUser from '../api/getCustomerPortalUser'
import Navigator from './Navigator';
import Header from './PageHeader';

let timeoutPromise, timeoutPromiseResolve, timeoutPromiseReject;

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    height: '100%'
  },
  ...drawerClass,
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.main,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  main: {
    position: 'absolute',
    top: logoHeight,
    bottom: 0,
    left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    height: `calc(100% - ${logoHeight}px)`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(3, 3),
    background: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      height: `calc(100% - 80px)`,
      padding: theme.spacing(2, 2),
      top: '80px',
      width: '100%',
    }
  },
});

function BuildPage(props) {
  const { classes } = props;
  const [loading, setLoading] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [customerPortalUser, setCustomerPortalUser] = React.useState(null);
  const [currentAccount, setCurrentAccount] = React.useState(null);
  const [currentLocale, setCurrentLocale] = React.useState(null);
  const [invoicerAccounts, setInvoicerAccounts] = useState([]);
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [userName, setUserName] = React.useState('');
  useEffect(() => {
    async function loadPage() {
      let accountInvoicerTransform = (usageBillDay) => (account) => {
        return {
          uid: account.identity,
          name: account.name,
          accountStatusTypeId: account.accountStatusTypeId,
          accountStatusTypeName: account.accountStatusTypeName,
          invoicerAccountId: account.invoicerAccountId,
          usageInvoicerAccountId: account.usageInvoicerAccountId,
          usageBillDay: usageBillDay,
          billDay: account.billDay,
          currencyCode: account.currencyCode,
          invoiceDeliveryName: account.invoiceDeliveryName,
          isInvoicerAccount: (account.identity === account.invoicerAccountId),
          accountsReceivableTermsName: account.accountsReceivableTermsName,
          accountsReceivableTermsId: account.accountsReceivableTermsId,
        }
      }

      let invoicerAccountId = await getCurrentAccountId();
      const userName = await getUserName();
      const locale = await getLocaleIdentifier();
      const getAccountInvoicersPromise = getAccountInvoicers(invoicerAccountId);
      const getCustomerPortalUserPromise = getCustomerPortalUser(invoicerAccountId);

      Promise.all([getAccountInvoicersPromise, getCustomerPortalUserPromise])
        .then((results) => {
          let [accounts, customerPortalUser] = results;
          let invoicerAccount = accounts.find((account) => account.identity === invoicerAccountId);
          let transformedAccounts = accounts.map(accountInvoicerTransform(invoicerAccount.usageBillDay));
          setUserName(userName);
          setCurrentLocale(locale);
          setCustomerPortalUser(customerPortalUser);
          setInvoicerAccounts(transformedAccounts);
          setCurrentAccount(transformedAccounts.find((account) => account.uid === invoicerAccountId));
          setLoading(false);
        })
    }
    loadPage();
  }, []);

  useEffect(() => {
    setAuthExpiredCb(handleModalOpen);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleModalOpen = async (checkForAuth = true) => {
    if (checkForAuth && await isAuthenticated()) {
      return Promise.resolve();
    }

    setOpenModal(true);
    if (timeoutPromise === undefined) {
      timeoutPromise = new Promise((resolve, reject) => {
        timeoutPromiseResolve = resolve;
        timeoutPromiseReject = reject;
      });
    }
    return timeoutPromise;
  };

  const handleModalClose = () => {
    setOpenModal(false);
    if (typeof timeoutPromiseResolve === 'function') {
      timeoutPromiseResolve();
    }
    timeoutPromise = undefined;
    timeoutPromiseResolve = undefined;
    timeoutPromiseReject = undefined;
  };

  const handleLogout = () => {
    logout();
    setOpenModal(false);
    if (typeof timeoutPromiseReject === 'function') {
      timeoutPromiseReject();
    }
    timeoutPromise = undefined;
    timeoutPromiseResolve = undefined;
    timeoutPromiseReject = undefined;
    setRedirectToLogin(true);
  };

  const onLoginSuccess = () => {
    handleModalClose();
  }

  let pageProps = {
    currentLocale,
    currentAccount,
    setCurrentAccount,
    customerPortalUser,
    invoicerAccounts
  };

  let { url } = useRouteMatch();
  url = url === '/' ? '/overview' : url;

  const container = window !== undefined ? () => window.document.body : undefined;

  if (redirectToLogin) {
    return <RedirectToLogin {...props} />
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav>
        <Hidden mdUp implementation='js'>
          <Navigator
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            url={url}
          />
        </Hidden>
        <Hidden smDown implementation='css'>
          <Navigator
            url={url}
            variant="permanent"
            open
          />
        </Hidden>
      </nav>
      {!loading &&
        <div className={classes.app}>
          <Header
            onDrawerToggle={handleDrawerToggle}
            {...pageProps}
          />
          <div className={classes.rightContainer}>
            <div className={classes.main}>
              {url === '/invoices' && <Invoices {...pageProps} />}
              {url === '/overview' && <Overview {...pageProps} />}
              {url === '/products' && <Products {...pageProps} />}
              {url === '/usage' && <Usage {...pageProps} />}
              {url === '/profile' && <Profile {...pageProps} />}
              <Dialog
                open={openModal}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleModalClose();
                  }
                }}
                disableEscapeKeyDown
              >
                <div>
                  <DialogTitle
                    id="alert-dialog-title"
                  >
                    Login expired
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You've been logged out due to inactivity. Please re-authenticate to continue
                    </DialogContentText>
                    <Login
                      username={userName}
                      onLoginSuccess={onLoginSuccess}
                      style={{ align: 'right' }}
                      loginWorkflow={reauthenticate}
                      {...props}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleLogout}>Log out</Button>
                  </DialogActions>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

BuildPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BuildPage);