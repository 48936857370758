import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';

import TextFieldWithOnEnterAction from '../components/TextFieldWithOnEnterAction'
import LogisenseIcon from '../components/LogisenseIcon';
import useUserStyles from './useUserStyles';
import { requestPasswordReset } from './authService';

export default (props) => {
  const useStyles = useUserStyles();
  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [submitSucceed, setSubmitSucceed] = useState(false);

  const userNameChangeHandler = (event) => {
    setUserName(event.target.value);
    setValidationErrors([]);
  };

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
    setValidationErrors([]);
  };

  const passwordConfirmChangeHandler = (event) => {
    setConfirmPasswordError(event.target.value !== password ? 'Passwords do not match' : '');
    setConfirmPassword(event.target.value);
    setValidationErrors([]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //Get resetLinkKey from URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const resetLinkKey = urlParams.get('resetLink');
    const onSuccessCb = (response) => {
      setValidationErrors([]);
      setSubmitSucceed(true);
    };
    const onFailCb = (response) => {
      if (Array.isArray(response.data.messages)) {
        setValidationErrors(response.data.messages);
      }
    };
    requestPasswordReset({userName, password, resetLinkKey}, onSuccessCb, onFailCb);
  };

  if (submitSucceed) {
    return <Redirect to={{ pathname: '/login' }}/>;
  }

  const isSubmitDisabled = (
    userName.length < 1 || 
    validationErrors.length > 0 || 
    confirmPasswordError.length > 0);
  return (
    <Container component='main' maxWidth='xs' id='portalScreen_NewPassword'>
      <CssBaseline/>
      <Card elevation={3} className={classes.login}>
        <LogisenseIcon className={classes.logo}/>
        <div className={classes.title}>Enter New Password</div>
        <form className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='userName'
            label='Username'
            name='userName'
            value={userName}
            onChange={userNameChangeHandler}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='New Password'
            type='password'
            id='newPassword'
            value={password}
            onChange={passwordChangeHandler}
          />
          <TextFieldWithOnEnterAction
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='confirmPassword'
            label='Confirm New Password'
            type='password'
            id='confirmPassword'
            value={confirmPassword}
            error={confirmPasswordError.length > 0}
            helperText={confirmPasswordError}
            onChange={passwordConfirmChangeHandler}
            onEnterPress={!isSubmitDisabled && submitHandler}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={isSubmitDisabled}
            onClick={submitHandler}
          >
            Change Password
          </Button>
          <ul>
            {validationErrors.map((error, idx) => (
              <li key={idx + 1} style={{ color: 'red' }}>
                {error.message}
              </li>
            ))}
          </ul>
        </form>
      </Card>
      <Box mt={8}>
      </Box>
    </Container>
  );
}