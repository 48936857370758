import React from 'react';
import LogisenseLogo from 'assets/images/logiSense/logisense_login_logo.png'

export default (props) => {
  return (
    <img
      src={LogisenseLogo}
      className={props.className}
      height='150'
      alt='Logisense Logo'
    />
  )
}