import PropTypes from 'prop-types';

import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetInvoices extends CustomerPortalApiCall {
  apiCall(accountId, options) {
    // for all cases exclude if not sent
    let filters = [
      { name: 'accountId', value: accountId },
      {"name": "sent", "operator": "isNotNull"}
    ];
    let pagedOptions = {};
    let searchRequest = {};
    if (options !== undefined) {
      if (Array.isArray(options.filters)) {
        filters = filters.concat(options.filters)
      } else if (typeof options.filters === 'object') {
        filters.push(options.filters);
      }
      if (options.readRequest !== undefined) {
        pagedOptions.readRequest = options.ReadRequest;
      }
      if (options.order !== undefined && options.orderBy !== undefined) {
        searchRequest.orderBy = [{ name: options.orderBy, direction: options.order }]
      }
    }
    searchRequest.filters = filters;

    return this.apiManager.invoice.paged(
      options.pageNumber,
      options.pageSize,
      searchRequest,
      pagedOptions
    )
  }
}

// comment the following line and uncomment the following code to get a full list of invoices - useful for pagination
const getInvoices = (accountId, options) => new GetInvoices().execute(accountId, options);

// const invoices = {
//   "totalCount": 19,
//   "items": [
//     {
//       "identity": 18336,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18336",
//       "date": "2020-02-06T22:14:43Z",
//       "due": "2020-02-27T22:14:43Z",
//       "invoiceStatusTypeId": 5,
//       "invoiceStatusTypeName": "Posted",
//       "processed": "2020-02-06T22:14:57Z",
//       "billRunHistoryId": 1,
//       "billRunHistoryName": "test",
//       "previousBalance": 0,
//       "balance": 20.73,
//       "created": "2017-06-01T00:00:00Z",
//       "closed": "2017-06-01T00:00:00Z",
//       "total": 22.49375205481,
//       "totalCharge": 20.81624657534,
//       "totalTax": 2.9724369863,
//       "totalDiscount": 0,
//       "totalPaymentApplied": 0,
//       "totalCreditApplied": -0.09,
//       "totalPaymentAttached": 10.05,
//       "totalCreditAttached": -1.12602739726,
//       "baseTotal": 22.49375205481,
//       "baseTotalCharge": 20.81624657534,
//       "baseTotalDiscount": 0,
//       "baseTotalPaymentApplied": 0,
//       "baseTotalCreditApplied": -0.09,
//       "baseTotalPaymentAttached": 10.05,
//       "baseTotalCreditAttached": -1.12602739726,
//       "baseBalance": 20.73,
//       "baseTotalTax": 2.9724369863,
//       "basePreviousBalance": 0,
//       "isVoid": false,
//       "isVoiding": false,
//       "doNotDeliver": false
//     },
//     {
//       "identity": 18337,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18337",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 74.86,
//       "total": 54.664701951,
//       "totalCharge": 47.834253325,
//       "totalTax": 6.830448626,
//     },
//     {
//       "identity": 18338,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18338",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 75.86,
//       "total": 55.764701951,
//       "totalCharge": 48.834253325,
//       "totalTax": 6.930448626,
//     },
//     {
//       "identity": 18339,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18339",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 76.86,
//       "total": 56.864701951,
//       "totalCharge": 49.834253325,
//       "totalTax": 7.030448626,
//     },
//     {
//       "identity": 18340,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18340",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 77.86,
//       "total": 57.964701951,
//       "totalCharge": 50.834253325,
//       "totalTax": 7.130448626,
//     },
//     {
//       "identity": 18341,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18341",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 78.86,
//       "total": 58.864701951,
//       "totalCharge": 51.834253325,
//       "totalTax": 7.230448626,
//     },
//     {
//       "identity": 18342,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18342",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 79.86,
//       "total": 59.764701951,
//       "totalCharge": 52.834253325,
//       "totalTax": 7.330448626,
//     },
//     {
//       "identity": 18343,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18343",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 80.86,
//       "total": 60.664701951,
//       "totalCharge": 53.834253325,
//       "totalTax": 7.430448626,
//     },
//     {
//       "identity": 18344,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18344",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 81.86,
//       "total": 61.564701951,
//       "totalCharge": 54.834253325,
//       "totalTax": 7.530448626,
//     },
//     {
//       "identity": 18345,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18345",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 82.86,
//       "total": 62.464701951,
//       "totalCharge": 55.834253325,
//       "totalTax": 7.630448626,
//     },
//     {
//       "identity": 18346,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18346",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 83.86,
//       "total": 63.364701951,
//       "totalCharge": 56.834253325,
//       "totalTax": 7.730448626,
//     },    {
//       "identity": 18347,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18347",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 84.86,
//       "total": 64.264701951,
//       "totalCharge": 57.834253325,
//       "totalTax": 7.830448626,
//     },    {
//       "identity": 18348,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18348",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 85.86,
//       "total": 65.164701951,
//       "totalCharge": 58.834253325,
//       "totalTax": 7.930448626,
//     },    {
//       "identity": 18349,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18349",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 86.86,
//       "total": 66.064701951,
//       "totalCharge": 59.834253325,
//       "totalTax": 8.030448626,
//     },
//     {
//       "identity": 18350,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18350",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 87.86,
//       "total": 67.164701951,
//       "totalCharge": 60.834253325,
//       "totalTax": 8.130448626,
//     },
//     {
//       "identity": 18351,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18351",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 88.86,
//       "total": 68.264701951,
//       "totalCharge": 61.834253325,
//       "totalTax": 8.230448626,
//     },
//     {
//       "identity": 18352,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18352",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 89.86,
//       "total": 69.364701951,
//       "totalCharge": 62.834253325,
//       "totalTax": 8.330448626,
//     },
//     {
//       "identity": 18353,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18353",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 90.86,
//       "total": 70.464701951,
//       "totalCharge": 63.834253325,
//       "totalTax": 8.430448626,
//     },
//     {
//       "identity": 18354,
//       "accountId": 10000000,
//       "accountName": "Anthem Records",
//       "name": "18354",
//       "date": "2020-03-06T22:14:43Z",
//       "due": "2020-03-27T22:14:43Z",
//       "balance": 91.86,
//       "total": 71.564701951,
//       "totalCharge": 64.834253325,
//       "totalTax": 8.530448626,
//     },
//   ]
// };

// function dynamicSort(property, isAsc) {
//   var sortOrder = isAsc ? 1 : -1;
//   return function (a,b) {
//     /* next line works with strings and numbers */
//     var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//     return result * sortOrder;
//   }
// }

// const getInvoices = (accountId, options) => {
//   let filters = [{ name: 'accountId', value: accountId }];
//   let pagedOptions = {};
//   let orderBy;
//   if (options !== undefined) {
//     if (Array.isArray(options.filters)) {
//       filters = filters.concat(options.filters)
//     } else if (typeof options.filters === 'object') {
//       filters.push(options.filters);
//     }
//     if (options.readRequest !== undefined) {
//       pagedOptions.readRequest = options.ReadRequest;
//     }
//     if (options.order !== undefined && options.orderBy !== undefined) {
//       orderBy = { name: options.orderBy, direction: options.order };
//     }
//   }
//
//   const start = (options.pageNumber - 1) * options.pageSize;
//   const end = options.pageNumber * options.pageSize;
//   return Promise.resolve(
//     Object.assign(
//       {},
//       invoices,
//       {
//         items: orderBy
//           ? invoices.items.slice(start, end).sort(dynamicSort(orderBy.name, orderBy.direction === 'asc'))
//           : invoices.items.slice(start, end)
//       }
//     )
//   );
// }

getInvoices.propTypes = {
  identity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  due: PropTypes.number.isRequired,
  totalCharge: PropTypes.number.isRequired,
  totalTax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
};

export default getInvoices;
