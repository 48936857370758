import { getLocaleIdentifier } from './getInfoFromCurrentUser.js';

const defaultCurrency = 'USD';
const defaultLocale = 'en-US';

let numberFormat = undefined;

export const getNumberFormat = async () => {
  if (numberFormat === undefined) {
    let locale = await getLocaleIdentifier();
    if (locale === undefined) {
      console.debug(`Attempting to retrieve a number formatter, but could not retrieve a locale for the current user, defaulting to ${defaultLocale}`)
      locale = defaultLocale;
    }
    numberFormat = new Intl.NumberFormat(locale);
  }
  return numberFormat;
};

export const getCurrencyFormat = async (currency) => {
  let selectedCurrency = currency;
  if (selectedCurrency === undefined) {
    console.debug(`Attempting to retrieve a currency formatter without specifying a currency, defaulting to ${defaultCurrency}`);
    selectedCurrency = defaultCurrency;
  }
  let locale = await getLocaleIdentifier();
  if (locale === undefined) {
    console.debug(`Attempting to retrieve a currency formatter, but could not retrieve a locale for the current user, defaulting to ${defaultLocale}`)
    locale = defaultLocale;
  }
  let formatter = new Intl.NumberFormat(locale, { 
    style: 'decimal', 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return { 
    format: (number) => {
      //Ensure currency always appears at the front, regardless of locale information (fr-CA would have currency at the end of the string)
      return `${selectedCurrency} ${formatter.format(number)}`
    }
  }
};

export const guid = () => {
  const guidS4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (guidS4() + guidS4() + '-' + guidS4() + '-' + guidS4() + '-' + guidS4() + '-' + guidS4() + guidS4() + guidS4());
}