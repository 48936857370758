import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetCardType extends CustomerPortalApiCall {
  apiCall(id) {
    return this.apiManager.cardType.single(id);
  }
}

const getCardType = (id) => new GetCardType().execute(id);

getCardType.propTypes = {
  identity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  vendorCardName: PropTypes.string.isRequired,
};

export default getCardType;