import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetCustomerPortalSettings extends CustomerPortalApiCall {
  apiCall(accountId) {
    return this.apiManager.customerPortalSetting.single(accountId);
  }
}

const getCustomerPortalSettings = (accountId) => new GetCustomerPortalSettings().execute(accountId);
getCustomerPortalSettings.propTypes = {
  identity: PropTypes.number.isRequired,
  allowPaymentMethodCreation: PropTypes.bool.isRequired,
  allowPaymentMethodUpdate: PropTypes.bool.isRequired,
  allowPaymentProductCancellation: PropTypes.bool.isRequired,
  allowPayments: PropTypes.bool.isRequired,
  largeLogo: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  smallLogo: PropTypes.string,
}
export default getCustomerPortalSettings;