import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import useUserStyles from './useUserStyles';
import LogisenseIcon from 'components/LogisenseIcon';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import { requestResetLink } from './authService';

export default (props) => {
  const useStyles = useUserStyles();
  const classes = useStyles();
  const [resendCount, setResendCount] = useState(0);
  const [isReturnToLoginClicked, setIsReturnToLoginClicked] = useState(false);
  const userName = props.userName ?? '';

  const returnToLoginHandler = () => {
    setIsReturnToLoginClicked(true);
  };
  const resendEmailHandler = (e) => {
    e.preventDefault();
    const onSuccessCb = (response) => {
      setResendCount(resendCount + 1);
    };
    const onFailCb = (response) => {
      console.error(response);
    };
    requestResetLink(userName, onSuccessCb, onFailCb);
  };

  if (isReturnToLoginClicked) return <Redirect to={{pathname: '/login'}} />;
  return (
    <Container component='main' maxWidth='xs' id='portalScreen_CheckEmail'>
      <CssBaseline />
      <Card elevation={3} className={classes.login}>
        <LogisenseIcon className={classes.logo} />
        <div className={classes.title}>Check your Email</div>
        <div className={classes.content}>
          <div className={clsx(classes.greeting, classes.floatLeft)}>Hello {userName},</div>
          <div className={clsx(classes.message, classes.floatLeft)}>We've sent you an email with a link to finish resetting your password.</div>
          <div className={classes.buttonRow}>
            <Button
              variant='contained'
              color='primary'
              className={clsx(classes.button, classes.floatLeft)}
              onClick={resendEmailHandler}
            >
              Resend the Email
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={clsx(classes.button, classes.floatRight)}
              onClick={returnToLoginHandler}
            >
              Return to Login
            </Button>
          </div>
        </div>
        {resendCount > 0 && 
          <div>
            Email has been resent {resendCount} times
          </div> 
        }
      </Card>
    </Container>
  )
}