import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import getInvoices from '../api/getInvoices';
import getAccountSummary from '../api/getAccountSummary';

import Header from '../components/Header';
import Loading from '../components/Loading.js';
import useMediaQuery from '../components/useMediaQuery.js';

import {getCurrencyFormat} from '../helpers/numberFormat.js';
import {portalConfigTransform, invoiceTransform} from '../helpers/transforms.js';
import {getCurrentOwnerCustomerPortalSettings} from '../helpers/getInfoFromCurrentUser'

import MakePaymentDialog from '../invoices/MakePaymentDialog';
import {handleInvoiceDownloadClick} from '../invoices/Invoices';
import OverviewCard from './OverviewCard';

const screenId = 'portalScreen_Overview';

export default (props) => {
  const { currentAccount, invoicerAccounts } = props;
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [invoicerAccount, setInvoicerAccount] = useState({});
  const [usageInvoicerAccount, setUsageInvoicerAccount] = useState({});
  const [summary, setSummary] = useState({ balance: '', balanceDue: '' });
  const [currencyFormatter, setCurrencyFormat] = React.useState();
  const [customerPortalSettings, setCustomerPortalSettings] = useState({});

  useEffect(() => {
    let currencyPromise = getCurrencyFormat(currentAccount.currencyCode);
    Promise.all([currencyPromise])
      .then((response) => {
        let [currencyFormatter] = response;
        setCurrencyFormat(currencyFormatter);
        if (currentAccount.isInvoicerAccount) {
          let portalSettings, invoiceData, summaryData;
          const portalConfig = getCurrentOwnerCustomerPortalSettings();
          const invoicePromise = getInvoices(
            currentAccount.uid,
            {
              pageNumber: 1,
              pageSize: 1,
              order: 'desc',
              orderBy: 'date'
            }
          );
          const summaryPromise = getAccountSummary(currentAccount.uid);
          Promise.all([portalConfig, invoicePromise, summaryPromise])
            .then((results) => {
              [portalSettings, invoiceData, summaryData] = results;
              let invoices = invoiceData.items.map(invoiceTransform);
              let invoice = (invoices.length > 0) ? invoices[0] : {};
              setCustomerPortalSettings(portalConfigTransform(portalSettings));
              setInvoice(invoice);
              if (summaryData !== undefined) {
                setSummary(summaryData);
              }
              setLoading(false);
            });
        } else {
          const invoicer = invoicerAccounts.find((account) => account.uid === currentAccount.invoicerAccountId);
          const usageInvoicer = invoicerAccounts.find((account) => account.uid === currentAccount.usageInvoicerAccountId);
          setInvoicerAccount(invoicer);
          setUsageInvoicerAccount(usageInvoicer);
          setLoading(false)
        }
      });
  }, [currentAccount, invoicerAccounts]);

  const onSuccessfulPayment = () => {
    getAccountSummary(currentAccount.uid).then((result) => {
      setSummary(result);
    })
  }

  let isPageWide = useMediaQuery('(min-width: 1240px)');
  let isPageBarelyWide = useMediaQuery('(min-width: 760px)');
  let cardSpacing = isPageWide ? 3 : isPageBarelyWide ? 6 : 12;

  return (
    loading ? <Loading title={'Overview'}/> :
      <>
        <Grid container spacing={3} alignItems='center' id={screenId}>
          <Grid item xs id='hasOverview'>
            <Header id={'PageTitle'} label={'Overview'}/>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={cardSpacing}>
            {
              (currentAccount.isInvoicerAccount) ?
                (
                  <OverviewCard
                    title='Balance'
                    content={`${currencyFormatter.format(summary.balance)}`}
                    actionButtonComponent={
                      (customerPortalSettings.allowPayments) ? (
                        <MakePaymentDialog 
                          title={'Make Payment'} 
                          buttonLabel={'Make Payment'} 
                          accountId={currentAccount.uid} 
                          accountCurrencyCode={currentAccount.currencyCode}
                          accountBalance={summary.balance}
                          currencyFormatter={currencyFormatter} 
                          hideInvoices={true}
                          onSuccessfulPayment={onSuccessfulPayment}
                        />) :
                        undefined
                    }
                  />
                ) : (
                  <OverviewCard
                    title='Subscription Invoice Recipient'
                    content={`${invoicerAccount.name}`}
                  />
                )
            }
          </Grid>
          <Grid item xs={cardSpacing}>
            {
              (currentAccount.isInvoicerAccount) ?
                (
                  <OverviewCard
                    title='Due'
                    content={`${currencyFormatter.format(summary.balanceDue)}`}
                  />
                ) : (
                  <OverviewCard
                    title='Usage Invoice Recipient'
                    content={`${usageInvoicerAccount.name}`}
                  />
                )
            }
          </Grid>
          <Grid item xs={cardSpacing}>
            {
              (currentAccount.isInvoicerAccount) ?
                (
                  <OverviewCard
                    title='Bill Day'
                    content={`${currentAccount.billDay}`}
                  />
                ) : (
                  <OverviewCard
                    title='Status'
                    content={`${currentAccount.accountStatusTypeName}`}
                  />
                )
            }
          </Grid>
          <Grid item xs={cardSpacing}>
            {
              (currentAccount.isInvoicerAccount) ?
                (
                  <OverviewCard
                    title='Latest Invoice'
                    content={invoice.name || '-'}
                    actionButtonComponent={
                      (invoice.uid !== undefined) ? (
                        <Button
                          className='overview-card-button' variant='contained' size='small' color='primary'
                          onClick={() => handleInvoiceDownloadClick(invoice.uid)}
                        >
                          View
                        </Button>
                      ) : undefined
                    }
                  />
                ) : (
                  <OverviewCard
                    title='Currency'
                    content={`${currentAccount.currencyCode}`}
                  />
                )
            }
          </Grid>
        </Grid>
      </>
  )
};