import React from 'react';

import InvoicesIcon from '@material-ui/icons/InsertDriveFile';
import OverviewIcon from '@material-ui/icons/Home';
import ProductsIcon from '@material-ui/icons/Widgets';
import UsageIcon from '@material-ui/icons/Storage';

const routeList = [
  { id: 'Overview', icon: <OverviewIcon/>, href: 'overview' },
  { id: 'Products', icon: <ProductsIcon/>, href: 'products' },
  { id: 'Invoices', icon: <InvoicesIcon/>, href: 'invoices' },
  { id: 'Usage', icon: <UsageIcon/>, href: 'usage' },
];

export default routeList;
