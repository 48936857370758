import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from "@material-ui/core/Card";

import LogisenseIcon from '../components/LogisenseIcon';
import Login from '../components/Login';
import { login } from '../user/authService';
import useUserStyles from './useUserStyles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        LogiSense
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default (props) => {
  const useStyles = useUserStyles();
  const classes = useStyles();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const onLoginSuccess = () => {
    setRedirectToReferrer(true)
  }

  let { from } = props.location.state || { from: { pathname: '/' } };
  if (redirectToReferrer) return <Redirect to={from} />;

  return (
    <Container component='main' maxWidth='xs' id='portalScreen_Login'>
      <CssBaseline />
      <Card elevation={3} className={classes.login}>
        <LogisenseIcon className={classes.logo}/>
        <form className={classes.form} noValidate>
          <Login 
            onLoginSuccess={onLoginSuccess}
            classes={classes}
            loginWorkflow={login}
            {...props}
          />
          <Grid container>
            <Grid item xs>
              <Link href='./forgotPassword' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}