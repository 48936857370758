import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const style = (theme) => ({
  root: {
    padding: theme.spacing(1),
    textAlign: 'center',
    '&:last-child': {
      padding: theme.spacing(1),
    },
  },
})

function InvoiceCard(props) {
  const { classes, amount, label, amountId, labelId } = props;
  const matches = useMediaQuery('(min-height:500px)');

  return (
    <Card>
      <CardContent className={classes.root}>
        {
          matches ?
            <>
              <Typography id={labelId} variant='subtitle2' gutterBottom>
                {label}
              </Typography>
              <Typography id={amountId} variant='h6' component='h2'>
                {amount}
              </Typography>
            </>
            :
            <Typography variant='subtitle2'>
              <span id={labelId}>{label}</span>: <span id={amountId}>{amount}</span>
            </Typography>
        }
      </CardContent>
    </Card>
  );
}

InvoiceCard.propTypes = {
  amount: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(style)(InvoiceCard);