import React, { useCallback, useEffect } from 'react'
import dayjs from '../assets/dayjsPlugins.js';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import getUsage from '../api/getUsage';
import Header from '../components/Header';
import Loading from '../components/Loading';
import Table, { classNames } from '../components/Table';

import DownloadUsageDialog from './helpers/downloadUsageDialog';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    fontSize: 'small',
    marginTop: theme.spacing(2),
  },
}));

const columns = [
  {
    accessor: 'col1',
    align: 'left',
    className: classNames.STRING,
    columnName: 'product',
    id: 'ColumnHeaderProduct',
    isSortable: false,
    width: '55%',
    Header: 'Product',
    Cell: row => {
      return (
        <div>
          <span className='service-name'>{row.value.serviceName}</span><br />
          <span className='package-name'>{row.value.packageName}</span>
        </div>
      )
    },
  },
  {
    accessor: 'col2',
    align: 'center',
    className: classNames.STRING,
    columnName: 'usageIdentifier',
    id: 'ColumnHeaderUsageIdentifier',
    isSortable: false,
    width: '30%',
    Header: 'Usage Identifier',
  },
  {
    accessor: 'col3',
    align: 'center',
    className: classNames.STRING,
    columnName: 'totalCharge',
    id: 'ColumnHeaderTotalUsed',
    isSortable: false,
    width: '15%',
    Header: 'Total Used',
  },
];

const setUsageBillingPeriods = (usageBillDay, locale) => {
  const now = dayjs();
  const month = now.month() + 1,
    year = now.year();
  let startDateFirstPeriod = dayjs.utc(`${year}-${month}-${usageBillDay}`, 'YYYY-M-D');
  // if before the bill day, start a month earlier
  if (now.isBefore(startDateFirstPeriod, 'day')) {
    startDateFirstPeriod = startDateFirstPeriod.subtract(1, 'months');
  }

  const setEndDate = (startDate) => {
    return dayjs(startDate).add(1, 'months').subtract(1, 'days');
  }
  let endDateFirstPeriod = setEndDate(startDateFirstPeriod);

  const fnPeriodMap = (monthOffset) => {
    if (monthOffset > 0) {
      startDateFirstPeriod = startDateFirstPeriod.subtract(1, 'months');
      endDateFirstPeriod = setEndDate(startDateFirstPeriod);
    }
    
    return {
      id: monthOffset + 1,
      start: startDateFirstPeriod.locale(locale).format('MMM DD YYYY'),
      end: endDateFirstPeriod.locale(locale).format('MMM DD YYYY'),
      month: startDateFirstPeriod.month() + 1,
      year: startDateFirstPeriod.year(),
    }
  }
  // 18 months
  return [...Array(18).keys()].map(fnPeriodMap);
}

const screenId = 'portalScreen_Usage';

function Usage(props) {
  const { currentAccount, currentLocale } = props;
  const classes = useStyles();
  const [usageBillingPeriod, setUsageBillingPeriod] = React.useState('1');  // current billing period
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [usageLoading, setUsageLoading] = React.useState(false);
  const [usageBillingPeriodList, setUsageBillingPeriodList] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageIdx, setPageIdx] = React.useState(1);
  const [rowsPerPage, setPageSize] = React.useState(10);

  const handleBillingPeriodChange = (event) => {
    const currentPageIdx = pageIdx;
    setUsageBillingPeriod(event.target.value);
    setPageIdx(1);
    if (currentPageIdx !== pageIdx) {
      fetchUsage({ pageIndex: pageIdx, pageSize: rowsPerPage })
    }
  };

  const usageTransform = (item) => {
    return {
      id: item.identity,
      col1: {
        serviceName: item.serviceName,
        packageName: item.packageName
      },
      col2: item.udrUsageIdentifier,
      col3: `${item.displayUsageTotal}`,
    };
  };

  useEffect(() => {
    const usageBillDay = currentAccount.usageBillDay;
    const usagePeriodList = setUsageBillingPeriods(usageBillDay, currentLocale);
    setUsageBillingPeriodList(usagePeriodList);
    setLoading(false);
  }, [currentAccount])


  const openDownloadDialog = function () {
    setIsDialogOpen(true);
  }

  const onUsageDialogClose = function () {
    setIsDialogOpen(false);
  }

  const downloadUsage = useCallback(({ pageSize, pageIndex }) => {
    const accountId = currentAccount.uid
    const usagePeriod = usageBillingPeriodList.find((period) => period.id === parseInt(usageBillingPeriod));
    if (accountId === undefined) return;
    return getUsage(
      accountId,
      usagePeriod.month,
      usagePeriod.year,
      {
        pageNumber: pageIndex,
        pageSize,
      }
    )
  }, [currentAccount, usageBillingPeriod, usageBillingPeriodList]);

  const fetchUsage = useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data like page size change or page# change
    const accountId = currentAccount.uid
    const usagePeriod = usageBillingPeriodList.find((period) => period.id === parseInt(usageBillingPeriod));
    if (accountId === undefined) return;
    pageIndex += 1;
    // Set the loading state
    setUsageLoading(true);

    getUsage(
      accountId,
      usagePeriod.month,
      usagePeriod.year,
      {
        pageNumber: pageIndex,
        pageSize,
      }
    ).then((results) => {
      let usage = results.items;
      let totalCount = results.totalCount;
      setData(usage);
      setPageCount(Math.ceil(totalCount / pageSize));
      setTotalRows(totalCount);
      setPageSize(pageSize);
      setPageIdx(pageIndex);
      setUsageLoading(false);
    });
  }, [currentAccount, usageBillingPeriod, usageBillingPeriodList]);

  return (
    loading ? <Loading title={'Usage Detail'} /> :
      <>
        <Grid container spacing={3} alignItems='center' id={screenId}>
          <Grid item xs={(totalRows === 0) ? 4 : 6} sm={3} md={4} lg={2} id='hasUsage'>
            <Header id={'PageTitle'} label={'Usage Detail'} />
          </Grid>
          {(totalRows > 0) && (
            <Grid item xs={6} sm={4} md={4} lg={7} id='downloadButton'>
              <>
                <Button
                  id='ButtonDownloadUsageAsCSV'
                  variant='contained'
                  color='primary'
                  startIcon={<CloudDownloadIcon />}
                  onClick={openDownloadDialog}
                  disableElevation>
                  Download
                </Button>
                <DownloadUsageDialog
                  onClose={onUsageDialogClose}
                  open={isDialogOpen}
                  periodData={usageBillingPeriodList.find((period) => period.id === parseInt(usageBillingPeriod))}
                  totalCount={totalRows}
                  fetchData={downloadUsage}
                />
              </>
            </Grid>
          )}
          <Grid item xs={8} sm={5} md={4} lg={3}>
            <FormControl required className={classes.formControl}>
              <InputLabel id='usage-billing-period-label'>Select Period</InputLabel>
              <Select
                labelId='usage-billing-period-label'
                id='usage-billing-period-select'
                value={usageBillingPeriod}
                onChange={handleBillingPeriodChange}
                className={classes.selectEmpty}
              >
                {usageBillingPeriodList.map((periodItem) => {
                  return (
                    <MenuItem key={periodItem.id} value={periodItem.id}>{periodItem.start} - {periodItem.end}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Table
          columns={columns}
          data={data.map(usageTransform)}
          fetchData={fetchUsage}
          loading={usageLoading}
          pageCount={pageCount}
          totalRows={totalRows}
        />
        {(!usageLoading) && (totalRows <= 0) && (
          <div>
            <br />
            <br />
            <Typography variant='subtitle1'>
              No usage found for the selected period
            </Typography>
          </div>
        )}
      </>
  )
}

export default Usage;