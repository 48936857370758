import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetCustomerPortalUser extends CustomerPortalApiCall {
  apiCall(accountId) {
    return this.apiManager.portalUser.first();
  }
}

const getCustomerPortalUser = (accountId) => new GetCustomerPortalUser().execute(accountId);
getCustomerPortalUser.propTypes = {
  identity: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  accountName: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  localeId: PropTypes.number.isRequired,
  localeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ownerId: PropTypes.number.isRequired,
  ownerName: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
}

export default getCustomerPortalUser;