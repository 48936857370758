import PropTypes from 'prop-types';
import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetLocale extends CustomerPortalApiCall {
  apiCall(localeId) {
    return this.apiManager.locale.single(localeId);
  }
}

const getLocale = (localeId) => new GetLocale().execute(localeId);

getLocale.propTypes = {
  identity: PropTypes.number.isRequired,
  localeIdentifier: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default getLocale;