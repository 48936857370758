import React from 'react';
import { withStyles } from '@material-ui/core';
import ReadOnlyField from '../../components/ReadOnlyField';

const styles = (theme) => ({
  readOnlyField: {
    paddingBottom: theme.spacing(2),
  },
  changePassword: {
    marginTop: theme.spacing(1),
  }
});

function BillingInformation (props) {
  const { classes, currentAccount } = props;

  const items = [
    { caption: 'Currency', text: currentAccount.currencyCode },
    { caption: 'Payment Term', text: currentAccount.accountsReceivableTermsName },
    { caption: 'Invoice Delivery', text: currentAccount.invoiceDeliveryName },
    { caption: 'Bill Day', text: currentAccount.billDay },
    { caption: 'Usage Bill Day', text: currentAccount.usageBillDay },
  ]

  return (
    <>
      {
        items.map((item) => {
          return <div className={classes.readOnlyField} key={item.caption}>
            <ReadOnlyField 
              caption={item.caption}
              text={item.text}
            />
          </div>
        })
      }
    </>
  )
}

export default withStyles(styles)(BillingInformation)