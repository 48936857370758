import React from 'react'

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { DataEnums } from '@lb/utils/enums';
import ReadOnlyField from '../../../components/ReadOnlyField';
import EditPaymentDialog from './EditPaymentDialog';

const styles = () => ({
  badgeCell: {
    display: 'flex',
  },
});

function PaymentMethodRow(props) {
  const { paymentMethod, customerPortalSettings, handleIsActiveChange, handleIsDefaultChange,
    accountId, hasDefault, paymentMethodNameList, onSuccessfulPayment } = props;

  const handleEditPaymentMethod = (e, fnUpdate) => {
    fnUpdate(e, paymentMethod.identity);
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <ReadOnlyField
            caption='Payment Method'
            text={paymentMethod.paymentMethodBaseId === DataEnums.PaymentBaseTypeEnum.CREDIT_CARD ? 'Credit Card' : 'Bank Account'}
          />
        </TableCell>
        <TableCell>
          <ReadOnlyField
            caption={paymentMethod.paymentMethodBaseId === DataEnums.PaymentBaseTypeEnum.CREDIT_CARD ? 'Card Number' : 'Account Number'}
            text={paymentMethod.paymentMethodIdentifier}
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            label='Active'
            labelPlacement='bottom'
            control={
              <Switch
                disabled={!customerPortalSettings.allowPaymentMethodUpdate}
                color='primary'
                checked={paymentMethod.isActive}
                onClick={(e) => handleEditPaymentMethod(e, handleIsActiveChange)}
              />
            }
          />
          <FormControlLabel
            label='Default'
            labelPlacement='bottom'
            control={
              <Radio
                color='primary'
                paymentMethodId={paymentMethod.id}
                disabled={!customerPortalSettings.allowPaymentMethodUpdate || !paymentMethod.isActive}
                checked={paymentMethod.autopay}
                onClick={(e) => handleEditPaymentMethod(e, handleIsDefaultChange)}
              />
            }
          />
          <FormControlLabel
            label='Edit'
            labelPlacement='bottom'
            control={
              <EditPaymentDialog
                accountId={accountId}
                disabled={!customerPortalSettings.allowPaymentMethodUpdate}
                name={paymentMethod.name}
                paymentTypeId={paymentMethod.paymentTypeId}
                paymentMethodId={paymentMethod.identity}
                paymentMethodBaseId={paymentMethod.paymentMethodBaseId}
                accountNumber={paymentMethod.paymentMethodIdentifier}
                autopay={paymentMethod.autopay}
                isActive={paymentMethod.isActive}
                hasDefault={hasDefault}
                paymentMethodNameList={paymentMethodNameList}
                onSuccessfulPayment={onSuccessfulPayment}
              />
            }
          />
          {/* {
            isUpdating && <CircularProgress color='primary' size={20} />
          } */}
        </TableCell>

      </TableRow>
    </>
  )
}

export default withStyles(styles)(PaymentMethodRow)
