const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};

const defaultBrandingInfo = {
  primaryColor: '#009be5',
  secondaryColor: '#18202c',
  largeLogo: undefined,
  smallLogo: undefined,
}

let primaryColor = defaultBrandingInfo.primaryColor;
let secondaryColor = defaultBrandingInfo.secondaryColor;
let largeLogo = defaultBrandingInfo.largeLogo;
let smallLogo = defaultBrandingInfo.smallLogo;

const setBranding = (branding) => {
  const schema = branding ?? defaultBrandingInfo;
  primaryColor = schema.primaryColor ?? defaultBrandingInfo.primaryColor;
  secondaryColor = schema.secondaryColor ?? defaultBrandingInfo.secondaryColor;
  largeLogo = schema.largeLogo ?? defaultBrandingInfo.largeLogo;
  smallLogo = schema.smallLogo ?? defaultBrandingInfo.smallLogo;
}

const warningColor = [
  "#ff9800",
  "#ffa726",
  "#fb8c00",
  "#ffa21a"
];
const dangerColor = [
  "#f44336",
  "#ef5350",
  "#e53935",
  "#f55a4e"
];
const successColor = [
  "#4caf50",
  "#66bb6a",
  "#43a047",
  "#5cb860"
];
const infoColor = [
  "#00acc1",
  "#26c6da",
  "#00acc1",
  "#00d3ee"
];
const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#b4b4b4",
  "#555555",
  "#333",
  "#a9afbb",
  "#eee",
  "#e7e7e7"
];
const blackColor = "#000";
const whiteColor = "#FFF";
const yellowColor = "#F2E100";
const lightColor = 'rgba(255, 255, 255, 0.7)';

export {
  setBranding,
  defaultFont,
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  grayColor,
  blackColor,
  whiteColor,
  yellowColor,
  lightColor,
  largeLogo,
  smallLogo,
};