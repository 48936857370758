import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    minWidth: '220px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '150px',
    }
  },
  contentRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    }
  }
}));

function OverviewCard(props) {
  const { actionButtonComponent: ActionButtonComponent, content, title } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent className={classes.contentRoot}>
          <Typography className='overview-card-title' variant='subtitle2' gutterBottom>
            {title}
          </Typography>
          <Typography className='overview-card-content' variant='h6' component='h2'>
            {content}
          </Typography>
        </CardContent>
      </CardActionArea>
      {
        (ActionButtonComponent !== undefined) &&
        <CardActions className={classes.cardAction}>
          {ActionButtonComponent}
        </CardActions>
      }
    </Card>
  )
}

OverviewCard.propTypes = {
  actionButtonComponent: PropTypes.object,
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default OverviewCard;