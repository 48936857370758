import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControl,
  FormControlLabel, FormHelperText, Grid, OutlinedInput, InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';

import getPaymentConfiguration from '../../../api/getPaymentConfiguration';
import postPaymentMethodBankAccount from 'api/postPaymentMethodBankAccount';
import postPaymentMethodCreditCard from 'api/postPaymentMethodCC';
import { createCSSText, defaultSpreedlySetup, initializeSpreedly } from '../../../invoices/MakePaymentDialog';
import ValidationErrors from '../../../components/ValidationErrors';

const textValidator = new RegExp(/^[a-zA-Z0-9]*$/);
const bankAccountNumberValidator = new RegExp(/^[0-9 ]+$/);
const bankRoutingNumberValidator = new RegExp(/^[0-9]{9}$/);
const PaymentTypeEnum = { NONE: '', CREDIT_CARD: 'CC', ACH: 'ACH' };
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBody: {
    marginBlock: '8px'
  },
  formControl: {
    marginBlock: '6px'
  },
  spreedlyInput: {
    borderBottom: '1px',
    height: '48px'
  }
}));

const isValueNonEmpty = (value) => {
  return value !== undefined && value !== null && value.length > 0 && value !== '';
}
const validateNewCreditCard = (firstName, lastName, expiryMonth, expiryYear) => {
  return (
    isValueNonEmpty(firstName) &&
    isValueNonEmpty(lastName) &&
    isValueNonEmpty(expiryMonth) &&
    isValueNonEmpty(expiryYear)
  )
}

const validateNewACH = (firstName, lastName, routingNumber, accountNumber) => {
  return (
    isValueNonEmpty(firstName) &&
    isValueNonEmpty(lastName) &&
    isValueNonEmpty(routingNumber) &&
    isValueNonEmpty(accountNumber)
  )
}

function AddPaymentDialog(props) {
  const { currentAccountId, hasDefault, paymentMethodNameList, onSuccessfulPayment } = props;
  const classes = useStyles();
  const [name, setName] = useState('');
  const [environmentKey, setEnvironmentKey] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [defaultPaymentCheck, setDefaultPaymentCheck] = useState(false);

  const [spreedlyVisible, setSpreedlyVisible] = useState(true);
  const [spreedlyReady, setSpreedlyReady] = useState(false);
  // const [spreedlyValid, setSpreedlyValid] = useState(true);
  const [save, setSave] = useState(false);

  const spreedlyCreditInput = useRef(null);
  const [spreedlyCreditError, setSpreedlyCreditError] = useState('');
  const [spreedlyCVVValid, setSpreedlyCVVValid] = useState(false);
  const [spreedlyCVVError, setSpreedlyCVVError] = useState('');
  const [spreedlyDateError, setSpreedlyDateError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [routingNumberError, setRoutingNumberError] = useState('');
  const [expiry, setExpiry] = useState({});

  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountId] = useState(currentAccountId);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const closeDialog = useCallback(() => {
    const Spreedly = window.Spreedly;
    setPaymentMethod('');
    setSpreedlyVisible(false);
    setSave(false);
    setSpreedlyCreditError('');
    setSpreedlyCVVError('');
    setSpreedlyDateError('');
    setAccountNumberError('');
    setRoutingNumberError('');
    setSpreedlyCVVValid(false);
    setSave(false);
    setValidationErrors([]);
    setName('');
    if (Spreedly.isLoaded()) {
      Spreedly.removeHandlers();
      Spreedly.unload();
    }
    setDialogOpen(false);
  });

  const saveACHPaymentCallback = useCallback((paymentMethod) => {
    if (paymentMethod?.accountNumber !== undefined) {
      console.log(paymentMethod?.accountNumber);
    } else {
      console.error('Unexpected Error Occurred - Closing Dialog')
    }

    const data = {
      name: name,
      accountId: accountId,
      autopay: defaultPaymentCheck,
      isActive: defaultPaymentCheck ? defaultPaymentCheck : false,
    }

    postPaymentMethodBankAccount(data, paymentMethod)
      .then(() => onSuccessfulPayment())
      .catch((e) => console.error(e));
    closeDialog();
  }, [accountId, closeDialog, defaultPaymentCheck, name]);

  const saveCCPaymentCallback = useCallback((token, paymentMethod) => {
    if (token !== undefined) {
      console.log(token)
      console.log(paymentMethod)
    } else {
      console.error('Unexpected Error Occurred - Closing Dialog')
    }

    const data = {
      name: name,
      accountId: accountId,
      autopay: defaultPaymentCheck,
      isActive: defaultPaymentCheck ? defaultPaymentCheck : false,
    }
    postPaymentMethodCreditCard(data, paymentMethod)
      .then(() => onSuccessfulPayment())
      .catch((e) => console.error(e));

    closeDialog();
  }, [accountId, closeDialog, defaultPaymentCheck, name]);

  const saveClose = useCallback(() => {
    setAccountNumberError('');
    setRoutingNumberError('');
    setValidationErrors([]);
    if (paymentMethod === PaymentTypeEnum.NONE) return;
    if (firstName === '' || lastName === '') return;
    if (paymentMethod === PaymentTypeEnum.CREDIT_CARD && expiry.month === undefined) return;
    if (!textValidator.test(name) && name === '') {
      setValidationErrors([{ error: 'Invalid Name', message: 'Name must not be empty and no special characters.' }]);
      return;
    };
    if (paymentMethodNameList.includes(name)) {
      setValidationErrors([{ error: 'Invalid Name', message: 'Name is already in use.' }]);
      return
    }
    if (paymentMethod === PaymentTypeEnum.ACH && (!bankRoutingNumberValidator.test(routingNumber) || !bankAccountNumberValidator.test(accountNumber))) {
      if (!bankRoutingNumberValidator.test(routingNumber)) {
        setRoutingNumberError('Invalid Routing Number.');
      }
      if (!bankAccountNumberValidator.test(accountNumber)) {
        setAccountNumberError('Invalid Account Number.');
      }
      return;
    }

    if (paymentMethod === PaymentTypeEnum.CREDIT_CARD) {
      const Spreedly = window.Spreedly;
      let requiredFields = {
        first_name: firstName,
        last_name: lastName,
        month: expiry.month,
        year: expiry.year
      };
      Spreedly.tokenizeCreditCard(requiredFields);
    } else {
      saveACHPaymentCallback({ firstName, lastName, accountNumber, routingNumber });
    }
    setValidationErrors([]);
  }, [paymentMethod, saveACHPaymentCallback, name,
    firstName, lastName,
    expiry,
    accountNumber, routingNumber
  ]);

  const spreedlyOnReady = useCallback(() => {
    const Spreedly = window.Spreedly;
    let compStyle = window.getComputedStyle(spreedlyCreditInput.current);
    let style = createCSSText({
      font: compStyle.font,
      width: '95%',
    });
    Spreedly.setFieldType('number', 'text');
    Spreedly.setNumberFormat('prettyFormat');
    Spreedly.setStyle('number', style);
    Spreedly.setStyle('cvv', style);
    setSpreedlyReady(true);
  }, [spreedlyCreditInput]);

  useEffect(() => {
    getPaymentConfiguration()
      .then((data) => {
        setEnvironmentKey(data.environmentKey);
      })
  }, [])

  useEffect(() => {
    function removeSpreedly() {
      const Spreedly = window.Spreedly;
      if (Spreedly.isLoaded()) Spreedly.unload();
      setSpreedlyVisible(false);
    }

    const spreedlyOnErrorCB = (errors) => {
      console.log(errors);
      errors.forEach(function (error) {
        if (error.attribute === 'cvv') {
          setSpreedlyCVVError(error.message);
        }
        if (error.attribute === 'number') {
          setSpreedlyCreditError(error.message);
        }
        if (error.attribute === 'year') {
          setSpreedlyDateError(error.message);
        }
        console.error(error.message);
      });
    };

    const fieldEventCB = function (name, type, /*activeEl, inputProperties*/) {
      if (type === 'input') {
        if (name === 'number') setSpreedlyCreditError('');
        if (name === 'cvv') {
          setSpreedlyCVVValid(true);
          setSpreedlyCVVError('');
        }
        if (name === 'year') setSpreedlyDateError('');
        // setSpreedlyValid(true);
      }
    }
    const validationCB = function (inputProperties) {
      if (!inputProperties.validNumber) setSpreedlyCreditError('Please enter a valid credit card number');
      if (!inputProperties.validCvv) setSpreedlyCreditError('Please enter a valid CVV');
      // setSpreedlyValid(inputProperties.validNumber && inputProperties.validCvv);
    }

    if (dialogOpen) {
      if (paymentMethod === PaymentTypeEnum.CREDIT_CARD) {
        const Spreedly = window.Spreedly;
        if (!Spreedly.isLoaded()) initializeSpreedly(environmentKey);
        setSpreedlyVisible(true);
        Spreedly.removeHandlers();
        defaultSpreedlySetup(spreedlyOnReady, spreedlyOnErrorCB, fieldEventCB, validationCB);
        Spreedly.on('paymentMethod', saveCCPaymentCallback)
      } else {
        removeSpreedly();
      }
      checkValidInput();
    }
  }, [dialogOpen, environmentKey, paymentMethod, spreedlyOnReady, saveCCPaymentCallback])

  const checkValidInput = () => {
    let enablePayNow = false;
    if (paymentMethod === PaymentTypeEnum.CREDIT_CARD && spreedlyReady) {
      enablePayNow = validateNewCreditCard(firstName, lastName, expiry.month, expiry.year) && spreedlyCVVValid;
      enablePayNow = enablePayNow && spreedlyCVVValid;
    } else if (paymentMethod === PaymentTypeEnum.ACH) {
      enablePayNow = validateNewACH(firstName, lastName, routingNumber, accountNumber);
    }
    setSave(enablePayNow);
  }

  return (
    <div style={{ 'textAlign': 'right' }}>
      <Button
        variant='contained'
        onClick={() => setDialogOpen(true)}
        color='primary'
        style={{ 'marginLeft': 'auto' }}
      >
        Add New
      </Button>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={dialogOpen}
        onClose={closeDialog}
      >
        <DialogTitle>
          Add Payment Method
        </DialogTitle>
        <DialogContent className={classes.container}>
          <FormControl variant='outlined' fullWidth className={classes.formControl}>
            <TextField
              id='name'
              value={name}
              onChange={(event) => setName(event.target.value)}
              label='Name'
              fullWidth
            />
          </FormControl>
          <ValidationErrors validationErrors={validationErrors} />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id='paymentMethodLabel'>Payment Method</InputLabel>
            <Select
              id='paymentMethod'
              value={paymentMethod}
              onChange={(event) => setPaymentMethod(event.target.value)}
              labelId={'paymentMethodLabel'}
              label='Payment Method'
              fullWidth
            >
              <MenuItem value={PaymentTypeEnum.NONE}> <em>None</em> </MenuItem>
              <MenuItem value={PaymentTypeEnum.CREDIT_CARD}>Credit Card</MenuItem>
              <MenuItem value={PaymentTypeEnum.ACH}>Bank Account</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formBody} fullWidth>

            {/*Name Components*/}
            {
              (paymentMethod !== PaymentTypeEnum.NONE) && (
                <FormControl fullWidth className={classes.formControl}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        id='first-name'
                        label='First Name'
                        variant='outlined'
                        onChange={(event) => setFirstName(event.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='last-name'
                        label='Last Name'
                        variant='outlined'
                        onChange={(event) => setLastName(event.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )
            }
            {/*Credit Card Components*/}
            {
              (paymentMethod === PaymentTypeEnum.CREDIT_CARD) && (
                <>
                  <FormControl error={(spreedlyCreditError !== '')} variant='outlined' fullWidth className={classes.formControl}>
                    <InputLabel shrink htmlFor='spreedly-credit'>Credit Card</InputLabel>
                    <OutlinedInput
                      id='spreedly-credit'
                      disabled={!spreedlyReady}
                      ref={spreedlyCreditInput}
                      variant='outlined'
                      inputComponent='div'
                      aria-describedby='spreedly-credit-error-text'
                      label='Credit Card'
                      notched
                    />
                    <FormHelperText id='spreedly-credit-error-text'>{spreedlyCreditError}</FormHelperText>
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} hidden={!spreedlyVisible}>
                        <FormControl error={(spreedlyDateError !== '')} variant='outlined' fullWidth>
                          <TextField
                            id='expiration-date'
                            label='Expiration Date' // TODO - Split Expiry Into Two Fields like Make Payment
                            type='month'
                            variant='outlined'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event) => {
                              const [year, month] = event.target.value.split('-');
                              setExpiry({ year, month });
                            }}
                            fullWidth
                          />
                          <FormHelperText id='spreedly-year-error-text'>{spreedlyDateError}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} hidden={!spreedlyVisible}>
                        <FormControl error={(spreedlyCVVError !== '')} variant='outlined' fullWidth>
                          <InputLabel shrink htmlFor='spreedly-cvv'>CVV</InputLabel>
                          <OutlinedInput
                            id='spreedly-cvv'
                            disabled={!spreedlyReady}
                            inputComponent='div'
                            aria-describedby='spreedly-cvv-error-text'
                            label='CVV'
                            notched
                          />
                          <FormHelperText id='spreedly-cvv-error-text'>{spreedlyCVVError}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              )
            }
            {/*ACH Components*/}
            {
              (paymentMethod === PaymentTypeEnum.ACH) && (
                <>
                  <FormControl error={(routingNumberError !== '')} variant='outlined' fullWidth>
                    <TextField
                      id='routing-number'
                      label='Routing Number'
                      variant='outlined'
                      aria-describedby='routing-number-error-text'
                      onChange={(event) => setRoutingNumber(event.target.value)}
                      fullWidth />
                    <FormHelperText id='routing-number-error-text'>{routingNumberError}</FormHelperText>
                  </FormControl>
                  <FormControl error={(accountNumberError !== '')} variant='outlined' fullWidth>
                    <TextField
                      id='account-number'
                      label='Account Number'
                      variant='outlined'
                      aria-describedby='account-number-error-text'
                      onChange={(event) => setAccountNumber(event.target.value)}
                      fullWidth />
                    <FormHelperText id='account-number-error-text'>{accountNumberError}</FormHelperText>
                  </FormControl>
                </>
              )
            }
            <FormControl fullWidth className={classes.formControl}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='Default'
                        disabled={hasDefault}
                        checked={defaultPaymentCheck}
                        color='primary'
                        onChange={(event) => setDefaultPaymentCheck(event.target.checked)}
                      />
                    }
                    label='Default'
                  />
                </Grid>
              </Grid>
            </FormControl>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button disabled={!save} onClick={saveClose} variant='contained' color='primary'>
            Save
          </Button>
          <Button onClick={closeDialog} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddPaymentDialog.propTypes = {
  currentAccountId: PropTypes.number.isRequired,
};

export default AddPaymentDialog;