import PropTypes from 'prop-types';

import CustomerPortalApiCall from './CustomerPortalApiCall';

class GetAccountSummary extends CustomerPortalApiCall {
  apiCall(accountId, options) {
    return this.apiManager.accountSummary.first({
      filters: [{ name: 'accountId', value: accountId }]
    });
  }
}

const getAccountSummary = (accountId, options) => new GetAccountSummary().execute(accountId, options);

getAccountSummary.propTypes = {
  identity: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  accountName: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  balanceDue: PropTypes.number.isRequired,
  charge: PropTypes.number.isRequired,
  credit: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  payment: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
};

export default getAccountSummary;