import React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { grayColor } from '../assets/jss/portalStyle';
import { makeStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useHeaderStyles = makeStyles({
  root: {
    fontWeight: '700',
    marginLeft: '6px',
  },
  headerBorder: {
    backgroundColor: grayColor[10],
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

function ServiceHeader(props) {
  const { row, startIdx, endIdx, leftClickHandler, rightClickHandler } = props;
  const { serviceStatusList } = row;
  const classes = useHeaderStyles();
  const disableLeftStatusScroll = startIdx === 0;
  const disableRightStatusScroll = endIdx >= serviceStatusList.length - 1;
  const visibleServiceStatusList = serviceStatusList.slice(startIdx, endIdx + 1);

  const matches = useMediaQuery('(min-width:768px)');

  return (
    <TableRow>
      <TableCell />
      <TableCell className={classes.root} align='left' width='400px'>Service</TableCell>
      {
        matches &&
        <>
          <TableCell className={classes.scroll} padding='checkbox'>
            <IconButton disabled={disableLeftStatusScroll} onClick={leftClickHandler}>
              <ArrowBackIosIcon />
            </IconButton>
          </TableCell>
          {visibleServiceStatusList.map((status) => {
            return (
              <TableCell key={status.id} className={classes.root} align='center'>{status.name}</TableCell>
            )
          })}
          <TableCell className={classes.scroll} padding='checkbox'>
            <IconButton disabled={disableRightStatusScroll} onClick={rightClickHandler}>
              <ArrowForwardIosIcon />
            </IconButton>
          </TableCell>
        </>
      }

      <TableCell className={classes.root} align='right'>Qty</TableCell>
      <TableCell className={classes.root}></TableCell>
    </TableRow>
  )
}

ServiceHeader.propTypes = {
  row: PropTypes.shape({
    serviceStatusList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }))
  }),
};

export default ServiceHeader;