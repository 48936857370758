import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// Material Components
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField'
import Hidden from '@material-ui/core/Hidden'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'

//Helpers
import { DataEnums } from '@lb/utils/enums';
import { getCurrentOwnerCustomerPortalSettings } from '../helpers/getInfoFromCurrentUser'

// API Components
import { dateHelpers, accounting } from '@lb/utils/helpers';
import getPaymentMethods from '../api/getPaymentMethods';
import { getPaymentType } from '../api/getPaymentType';
import getCardType from '../api/getCardType';
import getPaymentConfiguration from '../api/getPaymentConfiguration';
import postPayment from '../api/postPayment';
import getInvoices from '../api/getInvoices';
import postPaymentOneTimeBankAccount from '../api/postPaymentOneTimeBankAccount';
import postPaymentOneTimeCreditCard from '../api/postPaymentOneTimeCreditCard';
import ProcessingDialog from 'components/ProcessingDialog';

// Component Functions and Variables
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1)
  },
  spreedlyInput: {
    borderBottom: '1px',
    height: '48px',
  },
  newPaymentMethod: {
    borderTop: '1px solid',
  },
}));

const currencyAmountValidator = new RegExp(/^(?:\d{1,3},)*(?:\d{3},)*\d{3}(?:\.\d{1,2})?$|^\d+(?:\.\d{1,2})?$/);
const PaymentMethodTypeEnum = { NONE: 0, CREDIT_CARD: 1, ACH: 2 };
const PaymentBaseTypeEnum = DataEnums.PaymentBaseTypeEnum;
const PaymentMethodEnum = { NONE: 0, NEW: -1 }
const currentYear = new Date().getFullYear();

// Export Spreedly Functions
export const initializeSpreedly = (gatewayToken) => {
  const Spreedly = window.Spreedly;
  Spreedly.init(
    gatewayToken,
    {
      'numberEl': 'spreedly-credit',
      'cvvEl': 'spreedly-cvv'
    }
  )
};

export const defaultSpreedlySetup = (onReadyCB, onErrorCB, fieldEventCB, validationCB) => {
  if (typeof window.Spreedly !== 'object') {
    return console.warn('Resource - Spreedly Unavailable');
  }
  const Spreedly = window.Spreedly;
  Spreedly.on('ready', onReadyCB);
  Spreedly.on('errors', onErrorCB);
  Spreedly.on('fieldEvent', fieldEventCB);
  Spreedly.on('validation', validationCB);
};

export const createCSSText = (styleObj) => {
  if (!styleObj || typeof styleObj !== 'object' || Array.isArray(styleObj)) {
    throw new TypeError(`expected an argument of type object, but got ${typeof styleObj}`);
  }
  const elements = Object.keys(styleObj).map(property => `${property}: ${styleObj[property]};`);
  return elements.join(' ');
};

// Transform Functions
const paymentMethodTransform = (item) => {
  let digitsReg = item.paymentMethodIdentifier.match(/\d{4}/);
  let lastDigits = (digitsReg === null) ? '0000' : digitsReg[0];
  return {
    uid: item.identity,
    name: item.name,
    paymentTypeId: item.paymentTypeId,
    paymentTypeName: item.paymentTypeName,
    accountId: item.accountId,
    accountName: item.accountName,
    description: item.description,
    paymentMethodToken: item.paymentMethodToken,
    paymentMethodIdentifier: lastDigits,
    isActive: item.isActive,
    autopay: item.autopay,
  };
};
const paymentTypeTransform = (item) => {
  return {
    uid: item.identity,
    name: item.name,
    paymentBaseTypeId: item.paymentBaseTypeId,
    paymentBaseTypeName: item.paymentBaseTypeName,
    cardTypeId: item.cardTypeId,
    cardTypeName: item.cardTypeName,
    isActive: item.isActive,
  };
};
const cardTypeTransform = (item) => {
  return {
    uid: item.identity,
    name: item.name,
    vendorCardName: item.vendorCardName,
  };
};
const invoiceTransform = data => data.map((item) => {
  return {
    id: item.identity,
    name: item.name,
    balance: item.balance,
  };
});

// Internal Functions
const isValueNonEmpty = (value) => {
  return value !== undefined && value !== null && value.length > 0;
}
const validateNewCreditCard = (firstName, lastName, expiryMonth, expiryYear) => {
  return (
    isValueNonEmpty(firstName) &&
    isValueNonEmpty(lastName) &&
    isValueNonEmpty(expiryMonth) &&
    isValueNonEmpty(expiryYear)
  )
}

const validateNewACH = (firstName, lastName, routingNumber, accountNumber) => {
  return (
    isValueNonEmpty(firstName) &&
    isValueNonEmpty(lastName) &&
    isValueNonEmpty(routingNumber) &&
    isValueNonEmpty(accountNumber)
  )
}

const clickHiddenButton = (ref) => {
  ref.current.disabled = false;
  ref.current.click();
  ref.current.disabled = true;
}

const setToDisabled = (ref) => {
  if (ref !== null && ref.current !== null && ref.current.disabled !== true) {
    ref.current.disabled = true;
  }
}

const buildPaymentMethodMessage = (payment) => {
  return (payment === undefined && payment.identity !== undefined) ? '' : `Your payment ID is ${payment.identity}`;
}

// Main Dialog

function MakePaymentDialog({
                             accountId,
                             accountBalance,
                             accountCurrencyCode,
                             currencyFormatter,
                             hideInvoices,
                             onSuccessfulPayment,
                             ...props
                           }) {
  const classes = useStyles();
  const getAmountFromAccountBalance = useCallback(() => {
    return accountBalance > 0 ? accountBalance : '';
  }, [accountBalance]);

  // Dialog Variables
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [gatewayToken, setGatewayToken] = React.useState('');
  const [customerPortalSettings, setCustomerPortalSettings] = React.useState({});
  const [paymentApiCall, setPaymentApiCall] = React.useState(null);
  const [processingMessage, setProcessingMessage] = React.useState('');

  // Dialog Fields
  const [paymentType, setPaymentType] = React.useState({});
  const [cardType, setCardType] = React.useState({});
  const [paymentMethodId, setPaymentMethodId] = React.useState(0);
  const [paymentMethod, setPaymentMethod] = React.useState({});

  // EN-12262 Show account balance if no invoice selected
  const [amount, setAmount] = React.useState(getAmountFromAccountBalance());
  const [invoices, setInvoices] = React.useState([]);

  const [invoiceId, setInvoiceId] = React.useState(0);
  const [newPaymentMethodType, setNewPaymentMethodType] = React.useState(0);
  const [payNow, setPayNow] = React.useState(true);

  // Conditional Dialog Fields
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [expiryMonth, setExpiryMonth] = React.useState('');
  const [expiryYear, setExpiryYear] = React.useState('');
  const [expiryError, setExpiryError] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [routingNumber, setRoutingNumber] = React.useState('');

  const [savePaymentCheck, setSavePaymentCheck] = React.useState(false);
  const [paymentMethodName, setPaymentMethodName] = React.useState('');
  const [defaultCheck, setDefaultCheck] = React.useState(false);

  // Spreedly Fields
  const [spreedlyVisible, setSpreedlyVisible] = React.useState(false);
  const [spreedlyNumberValid, setSpreedlyNumberValid] = React.useState(false);
  const [spreedlyAmountValid, setSpreedlyAmountValid] = React.useState(false);
  const [spreedlyCvvValid, setSpreedlyCvvValid] = React.useState(false);
  const [spreedlyCreditError, setSpreedlyCreditError] = React.useState('');
  const [spreedlyAmountError, setSpreedlyAmountError] = React.useState('');
  const [spreedlyCVVError, setSpreedlyCVVError] = React.useState('');
  const [paymentMethodToken, setPaymentMethodToken] = React.useState('');
  const [genericError, setGenericError] = React.useState('');
  const [openGenericMessage, setOpenGenericMessage] = React.useState(false);

  // Spreedly State
  const [spreedlyReady, setSpreedlyReady] = React.useState(false);

  const spreedlyCreditInput = useRef(null);
  const recacheButton = useRef(null);
  const paymentMethodButton = useRef(null);

  setToDisabled(recacheButton);
  setToDisabled(paymentMethodButton);

  const resetDialogState = () => {
    const Spreedly = window.Spreedly;
    setPaymentType({});
    setCardType({});
    setPaymentMethodId(0);
    setPaymentMethod({});
    setInvoiceId(0);
    setNewPaymentMethodType({});
    setAmount(getAmountFromAccountBalance());
    setFirstName('');
    setLastName('');
    setExpiryMonth('');
    setExpiryYear('');
    setAccountNumber('');
    setRoutingNumber('');
    setPaymentMethodToken('');
    setSpreedlyVisible(false);
    setSpreedlyCreditError('');
    setSpreedlyAmountError('');
    setSpreedlyCVVError('');
    setSpreedlyNumberValid(false);
    setSpreedlyAmountValid(false);
    setSpreedlyCvvValid(false);
    setPayNow(true);
    setGenericError('');
    setOpenGenericMessage(false);
    if (Spreedly.isLoaded()) {
      Spreedly.removeHandlers();
      Spreedly.unload();
    }
  };

  // Initialize
  const initializePaymentMethods = useCallback(() => {
    getPaymentMethods(accountId, {
      filters: [
        { name: 'isActive', value: true, operator: 'eq', condition: 'and' },
      ]
    }).then((data) => {
      let paymentMethods = data.map(paymentMethodTransform)
      setPaymentMethods(paymentMethods);
      let defaultPaymentMethod = paymentMethods.find((pm) => pm.autopay === true);
      if (defaultPaymentMethod !== undefined) {
        setPaymentMethodId(defaultPaymentMethod.uid);
        setPaymentMethod(defaultPaymentMethod);
      }
    })
  }, [accountId]);

  const handleProcessingDialogClosed = () => {
    setProcessingMessage('');
    setPaymentApiCall(null);
  }

  const getInvoicesWithBalances = useCallback(() => {
    return getInvoices(
      accountId,
      {
        pageNumber: 1,
        pageSize: 1000,
        filters: [
          { name: 'balance', operator: 'gt', value: 0 },
          { name: 'isVoiding', operator: 'eq', value: 0 },
          { name: 'isVoid', operator: 'eq', value: 0 },
        ],
        order: 'desc',
        orderBy: 'date'
      }
    );
  }, [accountId])

  useEffect(() => {
    getPaymentConfiguration()
      .then((data) => {
        setGatewayToken(data.environmentKey);
      })
  }, [])

  useEffect(() => {
    setAmount(getAmountFromAccountBalance());
  }, [accountBalance, getAmountFromAccountBalance])

  // Dialog Functions
  const openDialog = () => {
    // EN-12244 Need to provide a list of invoices with positive balances sorted in reverse chronological order
    getInvoicesWithBalances().then((results) => {
      setInvoices(invoiceTransform(results.items));
      setIsDialogOpen(true);
    }).catch(() => {
      console.error('Unable to retrieve invoices with positive balances');
      setInvoices([]);
      setIsDialogOpen(true)
    });

    getCurrentOwnerCustomerPortalSettings().then((settings) => {
      setCustomerPortalSettings(settings);
    });

    initializePaymentMethods();
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const paymentMethodChange = (event) => {
    setPaymentType({});
    setCardType({});
    setNewPaymentMethodType(0);
    setPaymentMethodId(event.target.value);
    let paymentMethod = paymentMethods.find((method) => method.uid === event.target.value);
    if (paymentMethod !== undefined) setPaymentMethod(paymentMethod)
  };

  const amountChange = (event) => {
    setAmount(event.target.value);
  };

  const invoiceChange = useCallback((event) => {
    setInvoiceId(event.target.value);
    const invoice = invoices.find(invoice => invoice.id === event.target.value);
    // EN-12262 if no invoice selected revert to account balance if positive
    setAmount((curAmount) => {
      if (invoice && parseFloat(invoice.balance) > 0) return accounting.formatNumber(invoice.balance, 2);
      if (parseFloat(accountBalance) > 0) return accountBalance;
      return curAmount;
    })
  }, [invoices, accountBalance]);

  const expiryMonthChange = (event) => {
    let month = event.target.value;
    setExpiryMonth(month);
    setExpiryError('');
  };

  const expiryYearChange = (event) => {
    let year = event.target.value;
    setExpiryYear(year);
    setExpiryError('');
  };

  // Effects to gather additional data
  useEffect(() => {
    if (paymentMethodId > 0) {
      let paymentMethod = paymentMethods.find((method) => method.uid === paymentMethodId);
      if (paymentMethod !== undefined && paymentMethod.paymentTypeId !== undefined) {
        getPaymentType(paymentMethod.paymentTypeId)
          .then((paymentType) => {
            setPaymentType(paymentTypeTransform(paymentType));
          });
      }
    }
  }, [paymentMethodId, paymentMethods])

  useEffect(() => {
    if (typeof paymentType === 'object' && paymentType.cardTypeId !== undefined) {
      getCardType(paymentType.cardTypeId)
        .then((data) => {
          setCardType(cardTypeTransform(data));
        })
    }
  }, [paymentType])

  // Payment Functions
  const makeOneTimeBankPayment = useCallback(() => {
    let paymentOptions = {
      accountId: accountId,
      amount: amount,
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      firstName: firstName,
      lastName: lastName,
      posting: dateHelpers.dbNow(),
    }
    if (savePaymentCheck) {
      paymentOptions.savePaymentMethod = savePaymentCheck;
      paymentOptions.paymentMethodName = paymentMethodName;
    }
    const apiCall = postPaymentOneTimeBankAccount(paymentOptions).then(
      (results) => {
        console.log('Payment Successful');
        if (typeof onSuccessfulPayment === 'function') {
          onSuccessfulPayment();
        }
        setProcessingMessage(buildPaymentMethodMessage(results));
      });

    setPaymentApiCall(apiCall);
    closeDialog();
  }, [accountId, amount, accountNumber, routingNumber, firstName, lastName, paymentMethodName, savePaymentCheck, onSuccessfulPayment]);

  const makeOneTimeCreditPayment = useCallback((paymentMethodToken) => {
    let paymentOptions = {
      accountId: accountId,
      amount: amount,
      paymentMethodToken: paymentMethodToken,
      date: dateHelpers.dbNow(),
      posting: dateHelpers.dbNow(),
    };

    if (savePaymentCheck) {
      paymentOptions.savePaymentMethod = savePaymentCheck;
      paymentOptions.paymentMethodName = paymentMethodName;
    }
    const apiCall = postPaymentOneTimeCreditCard(paymentOptions).then((results) => {
      console.log('Payment Successful');
      if (typeof onSuccessfulPayment === 'function') {
        onSuccessfulPayment();
      }
      setProcessingMessage(buildPaymentMethodMessage(results));
    });

    setPaymentApiCall(apiCall);
    closeDialog();
  }, [accountId, amount, onSuccessfulPayment, paymentMethodName, savePaymentCheck]);

  useEffect(() => {
    if (paymentMethodToken === '') return;
    makeOneTimeCreditPayment(paymentMethodToken)
  }, [paymentMethodToken, makeOneTimeCreditPayment])

  const makeExistingPayment = useCallback(() => {
    let paymentObj = {
      accountId: accountId,
      paymentMethodId: paymentMethodId,
      invoiceId: (invoiceId > 0) ? invoiceId : null,
      posting: dateHelpers.dbNow(),
      automaticDisburse: true,
      detail: null,
      amount: amount,
    }
    const apiCall = postPayment(paymentObj).then((results) => {
      console.log('Payment Successful');
      if (typeof onSuccessfulPayment === 'function') {
        onSuccessfulPayment();
      }
      setProcessingMessage(buildPaymentMethodMessage(results));
    });
    setPaymentApiCall(apiCall);
    closeDialog();
  }, [accountId, paymentMethodId, invoiceId, amount, onSuccessfulPayment]);

  const triggerTokenize = useCallback(() => {
    const Spreedly = window.Spreedly;
    let requiredFields = {
      first_name: firstName,
      last_name: lastName,
      month: expiryMonth,
      year: expiryYear,
      amount: amount,
    };
    Spreedly.tokenizeCreditCard(requiredFields);
  }, [firstName, lastName, expiryMonth, expiryYear, amount]);

  const submitPayment = useCallback(() => {
    setOpenGenericMessage(false);
    if (paymentMethodId === PaymentMethodEnum.NEW) {
      if (newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD) {
        triggerTokenize();
      } else if (newPaymentMethodType === PaymentMethodTypeEnum.ACH) {
        makeOneTimeBankPayment();
      }
    } else if (paymentType.paymentBaseTypeId === PaymentBaseTypeEnum.CREDIT_CARD) {
      const Spreedly = window.Spreedly;
      Spreedly.recache();
    } else {
      makeExistingPayment();
    }
  }, [paymentMethodId, paymentType, newPaymentMethodType, makeExistingPayment, triggerTokenize, makeOneTimeBankPayment]);

  // Spreedly Functions
  const spreedlyRecacheOnReady = useCallback(() => {
    const Spreedly = window.Spreedly;
    Spreedly.setRecache(paymentMethod.paymentMethodToken, {
      last_four_digits: paymentMethod.paymentMethodIdentifier,
      card_type: cardType.vendorCardName
    });
    Spreedly.setFieldType('number', 'text');
    Spreedly.setNumberFormat('prettyFormat');
    let compStyle = window.getComputedStyle(spreedlyCreditInput.current);
    let style = createCSSText({
      font: compStyle.font,
      width: '95%'
    });
    Spreedly.setStyle('number', style);
    Spreedly.setStyle('cvv', style);
    Spreedly.on('recacheReady', function () {
      Spreedly.on('recache', () => {
        clickHiddenButton(recacheButton);
      })
      setSpreedlyReady(true);
    });
  }, [paymentMethod, cardType, spreedlyCreditInput, recacheButton]);

  const spreedlyNewPaymentOnReady = useCallback(() => {
    const Spreedly = window.Spreedly;
    let compStyle = window.getComputedStyle(spreedlyCreditInput.current);
    let style = createCSSText({
      font: compStyle.font,
      width: '95%',
    });
    Spreedly.setFieldType('number', 'text');
    Spreedly.setNumberFormat('prettyFormat');
    Spreedly.setStyle('number', style);
    Spreedly.setStyle('cvv', style);
    Spreedly.on('paymentMethod', (token) => {
      setPaymentMethodToken(token);
    });
    setSpreedlyReady(true);
  }, [spreedlyCreditInput]);

  // Dialog Validation Effects

  useEffect(() => {
    let enablePayNow = false;
    setSpreedlyAmountError('');
    setSpreedlyAmountValid(true);
    if (amount !== '' && !currencyAmountValidator.test(amount)) {
      setSpreedlyAmountError('Amount is not a valid currency value.');
      setSpreedlyAmountValid(false);
      enablePayNow = spreedlyAmountValid;
      return;
    }
    ;

    if (parseFloat(amount) > 0 || parseFloat(amount?.replace(/,/g, '')) > 0) {
      if (paymentMethodId === PaymentMethodEnum.NEW) {
        if (newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD && spreedlyReady) {
          enablePayNow = validateNewCreditCard(firstName, lastName, expiryMonth, expiryYear);
          enablePayNow = enablePayNow && spreedlyNumberValid && spreedlyCvvValid;
        } else if (newPaymentMethodType === PaymentMethodTypeEnum.ACH) {
          enablePayNow = validateNewACH(firstName, lastName, routingNumber, accountNumber);
        }
        if (savePaymentCheck === true) {
          enablePayNow = enablePayNow && (paymentMethodName.length > 0)
        }
      } else if (paymentMethodId > 0 && paymentType.uid > 0) {
        if (paymentType.paymentBaseTypeId === PaymentBaseTypeEnum.CREDIT_CARD) {
          enablePayNow = spreedlyReady && spreedlyCvvValid;
        } else if (paymentType.paymentBaseTypeId === PaymentBaseTypeEnum.ACH) {
          enablePayNow = true;
        }
      }
    }
    setPayNow(enablePayNow);
  }, [
    paymentMethodId, paymentType, newPaymentMethodType,
    spreedlyReady, amount,
    firstName, lastName,
    expiryMonth, expiryYear,
    routingNumber, accountNumber,
    spreedlyNumberValid, spreedlyAmountValid, spreedlyCvvValid,
    paymentMethodName, savePaymentCheck,
  ]);

  // Main Dialog State Logic
  useEffect(() => {
    const Spreedly = window.Spreedly;

    function setupSpreedly() {
      setSpreedlyVisible(true);
      setSpreedlyReady(false);
      if (!Spreedly.isLoaded()) initializeSpreedly(gatewayToken);
      Spreedly.removeHandlers();
    }

    function removeSpreedly() {
      const Spreedly = window.Spreedly;
      if (Spreedly.isLoaded()) Spreedly.unload();
      setSpreedlyReady(false);
      setSpreedlyVisible(false);
    }

    const spreedlyOnErrorCB = (errors) => {
      errors.forEach(function (error) {
        if (error.attribute === 'cvv') {
          setSpreedlyCVVError(error.message);
        } else if (error.attribute === 'number') {
          setSpreedlyCreditError(error.message);
        } else if (error.key === 'errors.expired' || error.attribute === 'year' || error.attribute === 'month') {
          setExpiryError(error.message);
        } else {
          setGenericError(`The payment failed due to an unexpected exception: ${error.message}`);
          setOpenGenericMessage(true);
        }
        console.error(error.message);
      });
    };

    const fieldEventCB = function (name, type, activeEl, inputProperties) {
      if (type === 'input') {
        if (name === 'number') {
          setSpreedlyCreditError('');
          if (inputProperties.validNumber !== undefined) setSpreedlyNumberValid(inputProperties.validNumber === true)
        }
        if (name === 'cvv') {
          setSpreedlyCVVError('');
          if (inputProperties.validCvv !== undefined) setSpreedlyCvvValid(inputProperties.validCvv === true)
        }
      }
    }

    const validationCB = function (inputProperties) {
      if (!inputProperties.validNumber) setSpreedlyCreditError('Please enter a valid credit card number');
      if (!inputProperties.validCvv) setSpreedlyCreditError('Please enter a valid CVV');
    }

    if (isDialogOpen) {
      // Existing Payment Method
      if (paymentMethodId > 0 && paymentType.uid > 0) {
        if (cardType.uid > 0 && paymentType.paymentBaseTypeId === PaymentBaseTypeEnum.CREDIT_CARD) {
          setupSpreedly();
          defaultSpreedlySetup(spreedlyRecacheOnReady, spreedlyOnErrorCB, fieldEventCB, validationCB);
          Spreedly.reload();
        } else {
          removeSpreedly();
        }
      } else if (paymentMethodId === PaymentMethodEnum.NEW && newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD) {
        setupSpreedly();
        defaultSpreedlySetup(spreedlyNewPaymentOnReady, spreedlyOnErrorCB, fieldEventCB, validationCB);
        Spreedly.reload();
      } else {
        removeSpreedly();
      }
    }
  }, [isDialogOpen, paymentMethodId, paymentType, cardType, newPaymentMethodType, gatewayToken, spreedlyRecacheOnReady, spreedlyNewPaymentOnReady])

  const closeToast = () => {
    setOpenGenericMessage(false);
  }

  const exitedToast = () => {
    setGenericError('');
  }

  return (
    <React.Fragment>
      <Button id='ButtonMakePayment' variant='contained' color='primary' onClick={openDialog} disableElevation>
        {(props.buttonLabel !== undefined) ? props.buttonLabel : 'Make Payment'}
      </Button>
      <Dialog open={isDialogOpen} onExit={resetDialogState} aria-labelledby='payment-dialog-title' fullWidth>
        <DialogTitle id='payment-dialog-title'>{(props.title !== undefined) ? props.title : 'Payment'}</DialogTitle>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={openGenericMessage}
          message={genericError}
          onExited={exitedToast}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              className={classes.close}
              onClick={closeToast}
            >
              <CloseIcon/>
            </IconButton>
          }
        />
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id='select-payment-method-label'>Payment Method</InputLabel>
              <Select
                id='select-payment-method'
                labelId='select-payment-method-label'
                input={<Input/>}
                value={paymentMethodId}
                label='Payment Method'
                onChange={paymentMethodChange}
              >
                <MenuItem key='0' value={PaymentMethodEnum.NONE}><em>None</em></MenuItem>
                {
                  paymentMethods.map((paymentMethod) => (
                    <MenuItem key={'paymentMethod-' + paymentMethod.uid}
                              value={paymentMethod.uid}>{paymentMethod.name}</MenuItem>
                  ))
                }
                <MenuItem key={'paymentMethod-New'} value={PaymentMethodEnum.NEW} className={classes.newPaymentMethod}>New
                  Payment Method</MenuItem>
              </Select>
            </FormControl>
            {
              (!hideInvoices) &&
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id='select-invoice-label'>Invoice</InputLabel>
                <Select id='select-invoice' labelId='select-invoice-label' input={<Input/>}
                        value={invoiceId} onChange={invoiceChange}>
                  <MenuItem key='0' value='0'><em>None</em></MenuItem>
                  {
                    invoices.map((invoice) => (
                      <MenuItem key={'invoice-' + invoice.id} value={invoice.id}>
                        {invoice.name}: {currencyFormatter.format(invoice.balance)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {
              (paymentMethodId === PaymentMethodEnum.NEW) &&
              <>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id='paymentMethodLabel'>Payment Method Type</InputLabel>
                  <Select
                    id='paymentMethod'
                    value={newPaymentMethodType}
                    onChange={(event) => setNewPaymentMethodType(event.target.value)}
                    labelId={'paymentMethodLabel'}
                    label='Payment Method Type'
                    fullWidth
                  >
                    <MenuItem value={PaymentMethodTypeEnum.NONE}> <em>None</em> </MenuItem>
                    <MenuItem value={PaymentMethodTypeEnum.CREDIT_CARD}>Credit Card</MenuItem>
                    <MenuItem value={PaymentMethodTypeEnum.ACH}>Bank Account</MenuItem>
                  </Select>
                </FormControl>
                {
                  (newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD || newPaymentMethodType === PaymentMethodTypeEnum.ACH) && (
                    <FormControl fullWidth className={classes.formControl}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            id='first-name'
                            label='First Name'
                            variant='outlined'
                            color='primary'
                            onChange={(event) => setFirstName(event.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id='last-name'
                            label='Last Name'
                            variant='outlined'
                            color='primary'
                            onChange={(event) => setLastName(event.target.value)}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  )
                }
              </>
            }
            {
              (paymentMethodId !== PaymentMethodEnum.NONE) &&
              <>
                {
                  (newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD) &&
                  <br/>
                }
                <FormControl className={classes.formControl} fullWidth>
                  <Grid container spacing={1}>
                    <Grid item xs={(paymentMethodId === PaymentMethodEnum.NEW) ? 12 : 8} hidden={!spreedlyVisible}>
                      <FormControl error={(spreedlyCreditError !== '')} variant='outlined' fullWidth>
                        <InputLabel shrink htmlFor='spreedly-credit'>Credit Card Number</InputLabel>
                        <OutlinedInput
                          id='spreedly-credit'
                          disabled={!spreedlyReady}
                          ref={spreedlyCreditInput}
                          variant='outlined'
                          color='primary'
                          inputComponent='div'
                          aria-describedby='spreedly-credit-error-text'
                          label='Credit Card Number'
                          notched
                        />
                        <FormHelperText id='spreedly-credit-error-text'>{spreedlyCreditError}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}
                          hidden={!(paymentMethodId === PaymentMethodEnum.NEW && newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD)}>
                      <TextField
                        id='expiration-date-month'
                        label='Expiry Month'
                        type='number'
                        variant='outlined'
                        color='primary'
                        placeholder='MM'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={expiryMonth}
                        InputProps={{ inputProps: { min: 1, max: 12 } }}
                        onChange={expiryMonthChange}
                        error={expiryError.length > 0}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}
                          hidden={!(paymentMethodId === PaymentMethodEnum.NEW && newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD)}>
                      <TextField
                        id='expiration-date-year'
                        label='Expiry Year'
                        type='number'
                        variant='outlined'
                        color='primary'
                        placeholder='YYYY'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={expiryYear}
                        InputProps={{ inputProps: { min: currentYear } }}
                        onChange={expiryYearChange}
                        error={expiryError.length > 0}
                        helperText={expiryError || ''}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} hidden={!spreedlyVisible}>
                      <FormControl error={(spreedlyCVVError !== '')} variant='outlined' fullWidth>
                        <InputLabel shrink htmlFor='spreedly-cvv'>CVV</InputLabel>
                        <OutlinedInput
                          id='spreedly-cvv'
                          disabled={!spreedlyReady}
                          inputComponent='div'
                          color='primary'
                          aria-describedby='spreedly-cvv-error-text'
                          label='CVV'
                          notched
                        />
                        <FormHelperText id='spreedly-cvv-error-text'>{spreedlyCVVError}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormControl>
              </>
            }
            {
              (newPaymentMethodType === PaymentMethodTypeEnum.ACH) && (
                <>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id='routing-number'
                      label='Routing Number'
                      variant='outlined'
                      color='primary'
                      onChange={(event) => setRoutingNumber(event.target.value)}
                      fullWidth/>
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id='account-number'
                      label='Account Number'
                      variant='outlined'
                      color='primary'
                      onChange={(event) => setAccountNumber(event.target.value)}
                      fullWidth/>
                  </FormControl>
                </>
              )
            }
            {
              (paymentMethodId > 0 || (paymentMethodId === PaymentMethodEnum.NEW && newPaymentMethodType !== PaymentMethodTypeEnum.NONE)) &&
              <FormControl error={(spreedlyAmountError !== '')} variant='outlined' fullWidth
                           className={classes.formControl}>
                <InputLabel htmlFor='amount-input'>Amount</InputLabel>
                <OutlinedInput
                  id='amount-input' onChange={amountChange}
                  startAdornment={<InputAdornment position='start'>{accountCurrencyCode}</InputAdornment>}
                  InputProps={{ inputProps: { min: 0 } }}
                  variant='outlined'
                  color='primary'
                  label='Amount'
                  value={amount}
                />
                <FormHelperText id='spreedly-cvv-error-text'>{spreedlyAmountError}</FormHelperText>
              </FormControl>
            }
            {
              <>
                {/*Actions on save*/}
                {
                  (newPaymentMethodType === PaymentMethodTypeEnum.CREDIT_CARD || newPaymentMethodType === PaymentMethodTypeEnum.ACH) && (
                    customerPortalSettings.allowPaymentMethodCreation &&
                    <>
                      <FormControl fullWidth className={classes.formControl}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name='SavePaymentMethod'
                                  checked={savePaymentCheck}
                                  color='primary'
                                  onChange={(event) => {
                                    setSavePaymentCheck(event.target.checked);
                                    setDefaultCheck(false);
                                  }}
                                />
                              }
                              label='Save Payment Method'
                              fullWidth
                            />
                          </Grid>
                          <Grid item hidden xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name='Default'
                                  checked={defaultCheck}
                                  color='primary'
                                  onChange={(event) => setDefaultCheck(event.target.checked)}
                                  disabled={!savePaymentCheck}
                                />
                              }
                              label='Default'
                              fullWidth
                            />
                          </Grid>
                          <Grid hidden={!savePaymentCheck} item xs={12}>
                            <TextField
                              id='payment-method-name'
                              label='Payment Method Name'
                              variant='outlined'
                              color='primary'
                              onChange={(event) => setPaymentMethodName(event.target.value)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </>
                  )
                }
              </>
            }
          </form>
        </DialogContent>
        <DialogActions>
          <Hidden>
            <Button ref={recacheButton} onClick={makeExistingPayment}/>
            <Button ref={paymentMethodButton} onClick={makeOneTimeCreditPayment}/>
          </Hidden>
          <Button onClick={closeDialog} color='primary'>
            Cancel
          </Button>
          <Button disabled={!payNow} variant='contained' onClick={submitPayment} color='primary'>
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
      {
        paymentApiCall !== null &&
        <ProcessingDialog
          apiCall={paymentApiCall}
          successMessage='Your Payment was Successful.'
          failedMessage='We were unable to make your payment.'
          onClose={handleProcessingDialogClosed}
          message={processingMessage}
        />
      }
    </React.Fragment>
  );
}

MakePaymentDialog.propTypes = {
  accountId: PropTypes.number.isRequired,
  accountBalance: PropTypes.number.isRequired,
  accountCurrencyCode: PropTypes.string.isRequired,
  currencyFormatter: PropTypes.object.isRequired,
  hideInvoices: PropTypes.bool
};

export default MakePaymentDialog
