import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '../../components/CircleProgressWithLabel';
import generateCsvString from '../../helpers/generateCsvString';

const maxPageCount = 5;
const maxPerPage = 2000;
// 2000 is about 1MB per page of response
// So that is a 5MB Dl for the file

const downloadData = function (fetchData, progressCb, totalDataCount, completeCb) {
  const currentNumOfPages = Math.ceil(totalDataCount / maxPerPage);
  const maxNumOfPages = Math.min(currentNumOfPages, maxPageCount);
  const progressMetric = 100 / maxNumOfPages;

  let currentPage = 1;
  let dataset = [];

  const getNextPage = (results) => {
    dataset = dataset.concat(results.items);
    progressCb(progressMetric);
    currentPage++;
    if (currentPage <= maxNumOfPages) {
      fetchData({ pageIndex: currentPage, pageSize: maxPerPage })
        .then(getNextPage);
    } else {
      global.download(new Blob([generateCsvString(dataset)]), 'periodData.csv', 'text/plain');
      completeCb();
    }
  }
  fetchData({ pageIndex: currentPage, pageSize: maxPerPage }).then(getNextPage);
}

function DownloadUsageDialog(props) {
  const [usageDownloading, setUsageDownloading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const { onClose, open, periodData, fetchData, totalCount } = props;
  // 1 million Record Max

  const handleClose = () => {
    onClose();
    setProgress(0);
    setUsageDownloading(false);
  };

  const startDownload = function () {
    const progressCallback = (metric) => {
      setProgress((prevProgress) => prevProgress + metric);
    }
    const completeCallback = () => {
      handleClose();
    }
    setUsageDownloading(true);
    downloadData(fetchData, progressCallback, totalCount, completeCallback)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id='download-dialog-title' variant='underline'>{`${periodData.start} - ${periodData.end}`}</DialogTitle>
      <CircularProgress variant='determinate' value={progress} />
      <Button
        color='primary'
        onClick={startDownload}
        disabled={usageDownloading}
        disableElevation>
        Download Now
      </Button>
      <Button onClick={handleClose} color='primary'>
        Cancel
      </Button>
    </Dialog>
  );
}

DownloadUsageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  periodData: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default DownloadUsageDialog;