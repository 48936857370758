import React, { useEffect, useState } from 'react'
import { usePagination, useTable } from 'react-table';

import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TablePaginationActions from '../components/TablePaginationActions';
import { bgShadeClass } from '../assets/jss/classes/bgShade'
import { portalConfigTransform } from '../helpers/transforms.js';
import { getCurrentOwnerCustomerPortalSettings } from '../helpers/getInfoFromCurrentUser'
import dateFormat from '../helpers/dateFormat';
import Header from '../components/Header';
import EmptyListMessage from '../components/EmptyListMessage';
import Loading from '../components/Loading';
import getAccountPackageSummary from '../api/getAccountPackageSummary';
import getServiceSummary from '../api/getServiceSummary';
import getServiceStatusType from '../api/getServiceStatusType';
import getAccountPackageStatusType from '../api/getAccountPackageStatusType';
import getAccountStatusType from '../api/getAccountStatusType';

import ServiceRow from './ServiceRow';
import transform from './transform';

const CANCELLED_BASE_STATUS_ID = 3;
const getServiceStatusList = () => {
  return new Promise((resolve) => {
    getServiceStatusType(
      { filters: [{ name: 'baseBillingStatusTypeId', operator: 'notEq', value: CANCELLED_BASE_STATUS_ID }] }
    ).then(result => {
      return resolve(result.map((item) => {
        return {
          id: item.identity,
          name: item.name,
          baseBillingStatusTypeId: item.baseBillingStatusTypeId
        };
      }))
    }).catch((err) => {
      console.error(err);
      resolve();
    });
  });
};

const getAccountPackageStatusList = () => {
  return new Promise((resolve) => {
    getAccountPackageStatusType(
    ).then(result => {
      return resolve(result.map((item) => {
        return {
          id: item.identity,
          name: item.name,
          baseBillingStatusTypeId: item.baseBillingStatusTypeId,
          preventChanges: item.preventChanges,
        };
      }))
    }).catch((err) => {
      console.error(err);
      resolve();
    });
  });
};

const getAccountStatusList = () => {
  return new Promise((resolve) => {
    getAccountStatusType(
    ).then(result => {
      return resolve(result.map((item) => {
        return {
          id: item.identity,
          name: item.name,
          baseBillingStatusTypeId: item.baseBillingStatusTypeId,
          preventChanges: item.preventChanges,
        };
      }))
    }).catch((err) => {
      console.error(err);
      resolve();
    });
  });
};

const useStyles = makeStyles({
  ...bgShadeClass,
  root: {
    overflow: 'auto',
  },
  pagination: {
    overflow: 'visible',
  }
});

const screenId = 'portalScreen_Products';

function Products(props) {
  const { currentAccount } = props;
  const defaultPageSize = 10;
  const defaultPageIndex = 1;
  const [data, setData] = useState([]);
  const [customerPortalConfig, setCustomerPortalConfig] = React.useState({});
  const [serviceSummary, setServiceSummary] = useState([]);
  const [serviceStatusList, setServiceStatusList] = useState([]);
  const [cancelledStatus, setCancelledStatus] = useState({});
  const [dateFormatter, setDateFormatter] = useState();
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [pageIdx, setPageIdx] = useState(defaultPageIndex);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const [accountPackageStatusList, setAccountPackageStatusList] = useState([]);
  const [accountStatusList, setAccountStatusList] = useState([]);

  const matches = useMediaQuery('(min-width:480px) and (min-height: 480px');

  const columns = React.useMemo(
    () => [
      {
        accessor: 'id', // accessor is the "key" in the data
      },
      {
        accessor: 'name',
      },
      {
        accessor: 'quantity',
      },
      {
        accessor: 'nextBill',
      },
      {
        accessor: 'status',
      },
      {
        accessor: 'statusId',
      },
      {
        accessor: 'baseBillingStatusTypeId',
      },
      {
        accessor: 'preventChanges',
      },
      {
        accessor: 'serviceStatusList',
      },
      {
        accessor: 'services',
      },
    ],
    []
  )

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    const currentAccountId = currentAccount.uid;
    pageIndex += 1;
    if ((pageSize === rowsPerPage && pageIndex === pageIdx) || currentAccountId === undefined ||
      dateFormatter === undefined) return;
    // Set the loading state
    setLoading(true);
    getAccountPackageSummary(currentAccountId, pageIndex, pageSize)
      .then((results) => {
        const packageSummary = results.items;
        let totalCount = results.totalCount;
        let currentAccountStatus = accountStatusList.find(accStatus => accStatus.id === currentAccount.accountStatusTypeId);
        setData(transform(packageSummary, serviceSummary, dateFormatter, serviceStatusList, accountPackageStatusList, currentAccountStatus));
        setPageCount(Math.ceil(totalCount / pageSize));
        setTotalRows(totalCount);
        setRowsPerPage(pageSize);
        setPageIdx(pageIndex);
        setLoading(false);
      });
  }, [rowsPerPage, pageIdx, currentAccount, serviceSummary, dateFormatter, serviceStatusList, accountPackageStatusList, accountStatusList]);

  const classes = useStyles();

  const onCancelPackage = (packageId, statusId, nextBill) => {
    setData(oldData => {
      return oldData.map(pkg => {
        if (pkg.id === packageId && pkg.statusId === statusId && pkg.nextBill === nextBill) {
          pkg.status = cancelledStatus.name;
          pkg.statusId = cancelledStatus.id;
          pkg.preventChanges = true;
        };
        return pkg;
      });
    })
  }

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination hook that we'll handle our own data fetching
      // This means we'll also have to provide our own pageCount.
      pageCount,
    },
    usePagination
  );

  //Fetch all data needed on Initial Load
  useEffect(() => {
    let isMounted = true;
    const accountId = currentAccount.uid
    const dateTransformPromise = dateFormat();
    const serviceStatusPromise = getServiceStatusList();
    const accountPackageStatusPromise = getAccountPackageStatusList();
    const accountStatusPromise = getAccountStatusList();
    const portalConfigPromise = getCurrentOwnerCustomerPortalSettings();
    const packageSummaryPromise = getAccountPackageSummary(accountId, defaultPageIndex, defaultPageSize);
    const serviceSummaryPromise = getServiceSummary(accountId);
    Promise.all([packageSummaryPromise, serviceSummaryPromise, serviceStatusPromise, accountPackageStatusPromise, accountStatusPromise, dateTransformPromise, portalConfigPromise])
      .then((results) => {
        const [packageSummary, serviceSummary, serviceStatusList, accountPackageStatusList, accountStatusList, dateFormatter, portalConfig] = results;
        let accountPackageStatusCancelled = accountPackageStatusList.find(status => status.baseBillingStatusTypeId === CANCELLED_BASE_STATUS_ID)
        let currentAccountStatus = accountStatusList.find(accStatus => accStatus.id === currentAccount.accountStatusTypeId);
        let totalCount = packageSummary.totalCount;
        if (isMounted) {
          setData(transform(packageSummary.items, serviceSummary, dateFormatter, serviceStatusList, accountPackageStatusList, currentAccountStatus));
          setPageCount(Math.ceil(totalCount / defaultPageSize));
          setTotalRows(totalCount);
          setDateFormatter(dateFormatter);
          setServiceSummary(serviceSummary);
          setServiceStatusList(serviceStatusList);
          setCancelledStatus(accountPackageStatusCancelled);
          setCustomerPortalConfig(portalConfigTransform(portalConfig));
          setAccountPackageStatusList(accountPackageStatusList);
          setAccountStatusList(accountStatusList);
          setLoading(false);
        }
      })

    return () => isMounted = false;
  }, [currentAccount]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize]);

  return (
    loading ? <Loading title={'Products'} /> :
      data.length === 0
        ?
        <EmptyListMessage
          screenId={screenId}
          entityName='products'
          headerLabel='Products'
          {...props}
        />
        :
        <>
          <Grid container spacing={3} alignItems='center' id={screenId}>
            <Grid item xs id='hasProducts'>
              <Header id={'PageTitle'} label={'Products'} />
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={classes.root}>
            <MuiTable {...getTableProps()} size='small' aria-label='list of products'>
              <TableBody {...getTableBodyProps()}>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={8} align='center' className={classes.bgShade}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && data.length > 0 && page.map((pkg) => {
                  prepareRow(pkg);
                  return (
                    <ServiceRow
                      onCancelPackage={onCancelPackage}
                      currentAccount={currentAccount}
                      allowCancelPackage={customerPortalConfig.allowPaymentProductCancellation}
                      key={pkg.id}
                      row={pkg.values}
                    />
                  )
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
          {!loading && (
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={matches ? [5, 10, 15, 25] : [10]}
              component='div'
              count={totalRows}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={gotoPage}
              onRowsPerPageChange={e => {
                setPageSize(Number(e.target.value))
              }}
              ActionsComponent={TablePaginationActions}
            />
          )}
        </>
  )
}

export default Products;
