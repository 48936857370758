import CustomerPortalApiCall from './CustomerPortalApiCall';

class PostCancelAccountPackages extends CustomerPortalApiCall {
  apiCall(packageId) {
    let postBody = {
      billCancelOptionTypeId: 1, //Prorate Final Period Charge
      effectiveCancel: new Date(),
      waiveEarlyTerminationFee: false,
    }
    return this.apiManager.cancelAccountPackages.create(postBody, { masterId: packageId });
  }
}

const postCancelAccountPackages = (packageId) => new PostCancelAccountPackages().execute(packageId);

export default postCancelAccountPackages;