const REFRESH_TOKEN_KEY = 'LogisenseCustomerPortalRefreshToken'
const INVOICE_ACCOUNT_ID_KEY = 'LogisenseCustomerPortalInvoicerAccountId'
const LOGIN_RESPONSE_KEY = 'LogisenseCustomerPortalLoginResponse'

export const clearStorage = () => {
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(INVOICE_ACCOUNT_ID_KEY);
  window.localStorage.removeItem(LOGIN_RESPONSE_KEY);
};

export const getStorage = () => {
  let refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
  if (refreshToken === null) { refreshToken = undefined; }

  let invoicerAccountId = window.localStorage.getItem(INVOICE_ACCOUNT_ID_KEY);
  if (invoicerAccountId === null) { invoicerAccountId = undefined; }

  let loginResponse = JSON.parse(window.localStorage.getItem(LOGIN_RESPONSE_KEY));
  if (invoicerAccountId === null) { loginResponse = undefined; }

  return { refreshToken, invoicerAccountId, loginResponse };
}

export const setStorage = (refreshToken, accessToken, loginResponse) => {
  if (refreshToken !== undefined) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  if (accessToken !== undefined) {
    let jwtToken = decodeJWT({ access_token: accessToken });
    window.localStorage.setItem(INVOICE_ACCOUNT_ID_KEY, jwtToken.accountId);
  }

  if (loginResponse !== undefined) {
    window.localStorage.setItem(LOGIN_RESPONSE_KEY, JSON.stringify(loginResponse));
  }
};

const decodeJWT = (current_claim) => {
  let response = {};
  if (current_claim && current_claim.access_token) {
    let segments = current_claim.access_token.split('.');
    if (segments.length > 1) {
      let claims = segments[1];
      response = JSON.parse(decodeURIComponent(encodeURIComponent(window.atob(claims))));
    }
  }
  return response;
};
