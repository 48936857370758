import CustomerPortalApiCall from './CustomerPortalApiCall';

class PostPayment extends CustomerPortalApiCall {
  apiCall(data) {
    return this.apiManager.payment.create(data);
  }
}

const postPayment = (data) => new PostPayment().execute(data);

export default postPayment;