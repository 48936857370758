import React from "react";
import { withStyles } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";

const styles = (theme) => ({
  caption: {
    fontSize: 'smaller',
  },
  text: {
    fontWeight: 'bold',
  },
  spacer: {
    height: theme.spacing(1),
  }
});

function ReadOnlyField (props) {
  const { classes, caption, text } = props;
  return (
    <>
      <InputLabel className={classes.caption}>{caption}</InputLabel>
      <span className={classes.spacer} />
      <InputLabel className={classes.text}>{text}</InputLabel>
    </>
  )
}

export default withStyles(styles)(ReadOnlyField);