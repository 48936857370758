import React, { useEffect } from 'react';
import clsx from 'clsx'

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  content: {
    textAlign: 'center',
  },
  icon: {
    fontSize: 60,
  },
  successIcon: {
    color: 'green',
  },
  failedIcon: {
    color: 'red',
  },
  message: {
    color: 'rgb(0,0,0,0.65)',
    fontWeight: 700
  },
}));

const buildErrorMessage = (err) => {
  const defaultMessage = '';
  if (err === undefined) return defaultMessage;
  if (err.response !== undefined && err.response.data !== undefined && Array.isArray(err.response.data.errors)) {
    var errorMessages = err.response.data.errors.map((e) => e.message);
    return errorMessages.join(' ');
  }
  if (err.message !== undefined) { 
    return err.message;
  }

  return defaultMessage;
}

const getTrackingId = (err) => {
  return (
    err !== undefined && 
    err.response !== undefined && 
    err.response.data !== undefined && 
    err.response.data.trackingId !== undefined
  ) ? err.response.data.trackingId : '';
}

export default function ProcessingDialog(props) {
  let {
    apiCall,
    successMessage,
    failedMessage,
    onClose,
    message,
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [trackingId, setTrackingId] = React.useState('');
  if (message === undefined || message === null) {
    message = '';
  }

  useEffect(() => {
    apiCall.then((results) => {
      setIsSuccess(true);
      return Promise.resolve(results)
    }).catch((err) => {
      setIsSuccess(false);
      console.warn(err);
      setErrorMessage(buildErrorMessage(err));
      setTrackingId(getTrackingId(err));
      return Promise.reject(err);
    }).finally(() => {
      setIsLoading(false);
    })
  }, [apiCall]);

  const classes = useStyles();
  return (
    <Dialog open>
      <DialogTitle className={classes.dialogTitle} >
        {
          isLoading ? undefined : <>
            {
              isSuccess 
              ? <CheckCircleIcon className={clsx(classes.successIcon, classes.icon)} /> 
              : <CancelIcon className={clsx(classes.failedIcon, classes.icon)}/>
            }
            <Typography className={classes.message}>{isSuccess ? successMessage : failedMessage}</Typography>
          </>
        }
      </DialogTitle>
      <DialogContent className={classes.content}>
        {
          isLoading ? <CircularProgress /> :<>
            { errorMessage.length > 0 && <Typography>{errorMessage}</Typography> }
            { trackingId.length > 0 && <Typography>Tracking Identifier: {trackingId}</Typography> }
            { message.length > 0 && <Typography>{message}</Typography>}
          </>
        }
      </DialogContent>
      <DialogActions>
        {
          !isLoading && <Button onClick={onClose} color='primary' variant='contained'>
            <Typography variant='caption'>Close</Typography>
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}