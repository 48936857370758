import React from 'react';
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  listItem: {
    color: 'red'
  }
})

function ValidationErrors (props) {
  const { classes, validationErrors } = props;
  if (!Array.isArray(validationErrors) || validationErrors.length <= 0) {
    return false;
  }
  return (
    <ul>
      {validationErrors.map((error, idx) => (
        <li key={idx + 1} className={classes.listItem}>
          {error.message}
        </li>
      ))}
    </ul>
  )
}

export default withStyles(styles)(ValidationErrors)