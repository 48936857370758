import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  content: {
    textAlign: 'center',
  },
  icon: {
    fontSize: 60,
  },
  
}));

const defaults = {
  confirmLabel: 'Yes',
  cancelLabel: 'No',
  Icon: InfoOutlinedIcon,
}

export default function ConfirmDialog(props) {
  let {
    open,
    title,
    Icon,
    confirmLabel,
    handleConfirmClick,
    cancelLabel,
    handleCancelClick,
    content,
  } = props;
  const classes = useStyles();
  Icon = Icon ?? defaults.Icon;
  confirmLabel = confirmLabel ?? defaults.confirmLabel;
  cancelLabel = cancelLabel ?? defaults.cancelLabel;

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.dialogTitle} >
        <Icon color='primary' className={classes.icon} />
        <div>{title}</div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} color='primary'>{cancelLabel}</Button>
        <Button onClick={handleConfirmClick} color='primary' variant='contained'>{confirmLabel}</Button>
      </DialogActions>
    </Dialog>
  )
}


