import CustomerPortalApiCall from './CustomerPortalApiCall';

class PostPaymentOneTimeBankAccount extends CustomerPortalApiCall {
  apiCall(data) {
    let postBody = {
      accountId: data.accountId,
      amount: data.amount,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      posting: data.posting,
      automaticDisburse: true,
    }
    if (data.savePaymentMethod === true) {
      postBody.savePaymentMethod = data.savePaymentMethod;
      postBody.name = data.paymentMethodName;
    }
    return this.apiManager.paymentOneTimeBankAccount.create(postBody);
  }
}

const postPaymentOneTimeBankAccount = (data) => new PostPaymentOneTimeBankAccount().execute(data);

export default postPaymentOneTimeBankAccount;