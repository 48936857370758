export const portalConfigTransform = (data) => {
  const defaultOwnerConfig = {
    'allowPayments': false,
    'allowPaymentMethodCreation': false,
    'allowPaymentMethodUpdate': false,
    'allowPaymentProductCancellation': false
  };
  return Object.assign(defaultOwnerConfig, data);
};

export const invoiceTransform = (item) => {
  return {
    id: item.identity,
    uid: item.identity,
    name: item.name,
    balance: item.balance,
    col1: item.name,
    col2: item.date,
    col3: item.due,
    col4: item.totalCharge,
    col5: item.totalTax,
    col6: item.total,
    col7: item.balance,
    col8: item.identity,
  };
};