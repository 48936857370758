import React from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';

import Header from '../components/Header';

import UserDetail from './tabs/UserDetail'
import BillingInformation from './tabs/BillingInformation'
import PaymentMethods from './tabs/paymentMethods/PaymentMethods'

const buildTab = (tabInfo, idx) => {
  const tabId = `tab_${idx}`;
  return (
    <Tab
      label={tabInfo.label}
      id={tabId}
      key={tabInfo.label}
    />
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  rootVertical: {
    display: 'flex',
    flexGrow: 1,
    height: '90%',
  },
  panel: {
    flexGrow: 1,
    overflow: 'auto',
  },
  tabs: {
    width: 'fit-content',
  },
  tabsVertical: {
    borderRight: 0,
  },
})

const screenId = 'portalScreen_Profile';

function Profile (props) {
  const { classes, ...rest } = props;
  const matches = useMediaQuery('(min-width:780px)');

  const { currentAccount } = rest;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (selectedTab !== 0 && !currentAccount.isInvoicerAccount) {
    setSelectedTab(0);
    return;
  }
  let billingInfoLabel = matches ? 'Billing Information' : 'Billing Info';

  let tabInfos = [
    { label: 'User Detail', component: UserDetail, isVisibleByNonInvoicerAccount: true },
    { label: billingInfoLabel, component: BillingInformation, isVisibleByNonInvoicerAccount: false },
    { label: 'Payment Methods' , component: PaymentMethods, isVisibleByNonInvoicerAccount: false },
  ]
  
  tabInfos = tabInfos.filter((tabInfo) => {
    return currentAccount.isInvoicerAccount || tabInfo.isVisibleByNonInvoicerAccount;
  });

  const buildTabPanel = (tabInfo, idx) => {
    return (
      <TabPanel 
        component='div'
        value={selectedTab}
        index={idx}
        key={tabInfo.label}
        className={classes.panel}
      >
        <tabInfo.component {...rest} />
      </TabPanel>
    )
  }
  
  return (
    <>
      <Grid container spacing={3} alignItems='center' id={screenId}>
        <Grid item xs id='hasProfile'>
          <Header id={'PageTitle'} label={'Profile'}/>
        </Grid>
      </Grid>
      <div className={matches ? clsx(classes.root, classes.rootVertical) : classes.root}>
        {
          matches
            ?
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={selectedTab}
              onChange={handleTabChange}
              className={classes.tabs}
            >
              {tabInfos.map((tab, idx) => {
                return buildTab(tab, idx);
              })}
            </Tabs>
            :
            <Tabs
              variant='scrollable'
              value={selectedTab}
              onChange={handleTabChange}
              className={clsx(classes.tabs, classes.tabsVertical)}
            >
              {tabInfos.map((tab, idx) => {
                return buildTab(tab, idx);
              })}
            </Tabs>
        }
        { tabInfos.map((tab, idx) => {
          return buildTabPanel(tab, idx);
        }) }
      </div>
    </>
  )
}

export default withStyles(styles)(Profile);